import React, { useState } from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
// import ReactTooltip from "react-tooltip";

import { Heading, Level, LevelItem, Title } from "../../sdk/Layout";

import { Button, Buttons } from "../../sdk/Button";
import Icon2 from "../../components/Icons2";
import useLoggedIn from "../../../blueberry/hooks/useLoggedIn";
import renderIf from "../../utils/renderIf";
import Gallery from "../../components/Gallery2";

import ProductGrid from "../../components/ProductGrid2";

import {
	DetailProductTable,
	ProductMeta,
	AddToCart,
	ProductTitle,
	AsociatedProducts,
} from "../../components/Product2";

import useInCart from "../../../blueberry/hooks/useInCart2";
import Blueberry from "../../../blueberry";
import { useTranslation } from "../../../lang";

const EmptySpace = styled.div`
	height: 200px;
`;

const QuickView = styled.div`
	flex: none;
	/* width: 100%; */
	width: 100%;
	overflow: hidden;
	background-color: ${(props) => props.theme.grayLight};

	${Title} {
		text-transform: uppercase;
		padding: 10px 0 20px;
		margin-bottom: 0;
		@media (max-width: 980px) {
			font-size: 18px;
		}
	}

	table {
		font-size: 14px;
		background-color: transparent;
		width: 100%;
		border: solid 1px ${(props) => props.theme.borderColor};
		color: ${(props) => props.theme.primaryDark};
		@media (max-width: 980px) {
			font-size: 12px;
		}
		th {
			width: 1px;
			white-space: nowrap;
		}
		td,
		th {
			padding: 5px;
		}
		tr + tr th,
		tr + tr td {
			border-top: solid 1px ${(props) => props.theme.borderColor};
		}
	}

	figure {
		background-color: ${(props) => props.theme.white};
		&.lookalike {
			img {
				display: block;
				max-height: 440px;
				max-width: 100%;
				margin: auto;
				object-fit: contain;
			}
		}
	}
`;

/* ${ItemsContainer} {
        padding: 0;
    }
    ${Item} {
        padding: 10px;
        h3 {
            font-size: 12px;
            line-height: 14px;
        }
    } */

const Topbar = styled.div`
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: ${(props) => props.theme.white};
	border: solid 1px ${(props) => props.theme.grayLight};
	border-right: 0;
	border-bottom-color: ${(props) => props.theme.borderColor};
	padding: 10px;
	h3 {
		font-weight: bold;
		font-size: 20px;
	}
	${Button} {
		margin-bottom: 0;
	}
`;
const Wrapper = styled.div`
	padding: 20px;
	@media (max-width: 768px) {
		padding: 10px;
	}
	> div + div {
		margin-top: 20px;
	}
`;

const LookItems = styled.div`
	margin-top: 20px;
	h3 {
		margin-top: 0;
	}
	@media (max-width: 768px) {
	}
`;

const InCartRemove = styled(Button)`
	svg {
		margin-right: 10px !important;
	}
`;

const QuickViewComponent = ({
	product,
	isActive,
	hideQuickView,
	addToCart,
	removeItem,
	viewItem,
	isSticky,
	itemToItem,
	ambientProducts,
	nextProduct,
	backProduct,
	width,
	asociatedProducts,
	isNextProductActive,
	jsonSettings,
	refetch,
	isBackProductActive,
}) => {
	const [lookActive, setLookActive] = useState(false);

	const [isRemovingFromCart, setIsRemovingFromCart] = useState(false);

	const [_t] = useTranslation();
	const isLoggedIn = useLoggedIn();

	const { inCart, cookieTokens } = useInCart(product ? product._id : null);
	const isInCart = inCart != null;

	const toggleLook = () => {
		setLookActive(!lookActive);
	};

	const isFair =
		product &&
		product.tags &&
		product.tags.length > 0 &&
		product.tags.indexOf("feria") > -1;

	const ambientImage =
		ambientProducts &&
		ambientProducts.collection &&
		ambientProducts.collection.imagesUrl &&
		ambientProducts.collection.imagesUrl.length > 0
			? ambientProducts.collection.imagesUrl[0].url
			: "";

	const handleRemoveProduct = () => {
		setIsRemovingFromCart(true);
		const promises = cookieTokens.map((index) =>
			removeItem(product._id, product.slug, index),
		);
		Promise.all(promises).then(() => {
			setIsRemovingFromCart(false);
		});
	};

	let didRequestImageAbient = false;
	const handleOnHoverAmbient = () => {
		if (ambientImage !== "" && !didRequestImageAbient) {
			let imageObj = new Image();
			imageObj.src = ambientImage;
			didRequestImageAbient = true;
		}
	};

	React.useEffect(() => {
		if (product && isActive) {
			Blueberry.productView({ slug: product.slug });

			if (typeof analytics !== "undefined") {
				analytics.track("Product Viewed", {
					product_id: product.slug,
					...product,
				});
			}
		}
		setLookActive(false);
	}, [isActive]);

	if (product == null) return null;

	return (
		<QuickView isSticky={isSticky}>
			<Topbar>
				<div>
					<div className={lookActive ? "is-hidden" : ""}>
						<Buttons>
							{renderIf(
								ambientProducts && ambientProducts.collection,
							)(
								<Button
									onClick={toggleLook}
									onMouseEnter={handleOnHoverAmbient}
									onTouchStart={handleOnHoverAmbient}
									data-tip={_t("comleteLook")}
									data-for="help-text"
									data-place="bottom">
									<Icon2 icon="grip" />
								</Button>,
							)}
							{isInCart ? (
								<InCartRemove
									loading={isRemovingFromCart}
									onClick={handleRemoveProduct}>
									<Icon2 icon="remove-cart" />
									{inCart}
								</InCartRemove>
							) : null}
							{/*
                                <Button data-tip="Compartir">
                                    <Icon2 icon="share" />
                                </Button>
                                */}
						</Buttons>
					</div>
					<div className={lookActive ? "" : "is-hidden"}>
						<Level isMobile>
							<LevelItem>
								<Button onClick={toggleLook}>
									<Icon2 icon="minus" />
								</Button>
							</LevelItem>
							<LevelItem>
								<h3></h3>
							</LevelItem>
						</Level>
					</div>
				</div>
				<div>
					<div className={lookActive ? "is-hidden" : ""}>
						<Buttons>
							<Button
								white
								static={!isBackProductActive}
								onClick={backProduct}
								data-tip={_t("back")}
								data-for="help-text"
								data-place="bottom">
								<Icon2 icon="chevron-left" />
							</Button>
							<Button
								white
								static={!isNextProductActive}
								onClick={nextProduct}
								data-tip={_t("next")}
								data-for="help-text"
								data-place="bottom">
								<Icon2 icon="chevron-right" />
							</Button>
							<Button
								id="close-button"
								white
								onClick={hideQuickView}>
								<Icon2 icon="close" />
							</Button>
						</Buttons>
					</div>
					<div className={lookActive ? "" : "is-hidden"}>
						<Buttons>
							<Button white onClick={hideQuickView}>
								<Icon2 icon="close" />
							</Button>
						</Buttons>
					</div>
				</div>
			</Topbar>
			<Wrapper>
				<div>
					{renderIf(!lookActive)(
						<Gallery
							key={`maginigfy-${product._id}`}
							slug={product.sku}
							images={product.imagesUrl}
							zoomDefault="over"
							horizontal
							videosUrl={product.videosUrl}
						/>,
					)}
					{renderIf(lookActive)(
						<div className={lookActive ? "" : "is-hidden"}>
							<figure className="lookalike">
								<img src={ambientImage} />
							</figure>
						</div>,
					)}
				</div>
				<div className={lookActive ? "is-hidden" : ""}>
					<ProductMeta
						product={product}
						isFair={isFair}
						jsonSettings={jsonSettings}
						isLoggedIn={isLoggedIn}
					/>
					<ProductTitle
						size="5"
						title={product.title}
						title_eng={product.title_eng}
						title_pt={product.title_pt}
						slug={product.slug}
					/>
					{/* add to cart */}
					{renderIf(isActive)(
						<AddToCart
							product={product}
							isInCart={isInCart}
							defaultQuantity={inCart}
							isFair={isFair}
							addToCart={addToCart}
							removeItem={removeItem}
							isLoggedIn={isLoggedIn}
						/>,
					)}
					{renderIf(isActive)(
						<>
							<div>
								<DetailProductTable
									isFair={isFair}
									product={product}
									isLoggedIn={isLoggedIn}
									refetch={refetch}
									jsonSettings={jsonSettings}
								/>
							</div>
							<AsociatedProducts
								viewItem={viewItem}
								asociatedProducts={asociatedProducts}
							/>
						</>,
					)}
				</div>
				{renderIf(
					isActive &&
						itemToItem &&
						itemToItem.products &&
						itemToItem.products.length > 0,
				)(
					<div className={lookActive ? "is-hidden" : ""}>
						<Heading isCentered>
							<h3>{_t("relatedProducts")}</h3>
						</Heading>
						<ProductGrid
							products={itemToItem}
							grid="x3"
							// onQuickView={showQuickView}
							addToCart={addToCart}
							viewItem={viewItem}
							// width={width}
							// currentProduct={productIndex}
							// renderQuickView={renderQuickView}
						/>
					</div>,
				)}
				{renderIf(lookActive)(
					<LookItems className={lookActive ? "" : "is-hidden"}>
						<Heading isCentered>
							<h3>
								{ambientProducts && ambientProducts.collection
									? ambientProducts.collection.title
									: ""}
							</h3>
						</Heading>
						<ProductGrid
							products={ambientProducts}
							grid="x3"
							// onQuickView={showQuickView}
							addToCart={addToCart}
							viewItem={viewItem}
							// width={width}
							// currentProduct={productIndex}
							// renderQuickView={renderQuickView}
						/>
					</LookItems>,
				)}
				<EmptySpace></EmptySpace>
			</Wrapper>
		</QuickView>
	);
};

export default React.memo(QuickViewComponent);
