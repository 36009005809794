import React from "react";

export default function HomeFillIcon() {
    return (
        <svg
            width="23px"
            height="18px"
            viewBox="0 0 23 18"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <g
                    id="current_bar"
                    transform="translate(-597.000000, -141.000000)"
                    fill="#000000"
                    fillRule="nonzero"
                >
                    <g
                        id="Group-8-Copy"
                        transform="translate(563.000000, 120.000000)"
                    >
                        <g id="Group">
                            <g
                                id="Stacked-Group"
                                transform="translate(34.000000, 18.000000)"
                            >
                                <g id="home-fill">
                                    <path d="M11.1571894,7.62371095 L3.82066695,13.666185 L3.82066695,20.1877594 C3.82066695,20.5393871 4.10571749,20.8244376 4.45734515,20.8244376 L8.9164801,20.8128978 C9.26686393,20.8111459 9.54997929,20.5266078 9.54997491,20.1762196 L9.54997491,16.3676902 C9.54997491,16.0160625 9.83502545,15.731012 10.1866531,15.731012 L12.7333659,15.731012 C13.0849936,15.731012 13.3700441,16.0160625 13.3700441,16.3676902 L13.3700441,20.1734341 C13.3695154,20.3426362 13.4363603,20.5050893 13.5558179,20.6249203 C13.6752756,20.7447513 13.8375195,20.8121028 14.0067223,20.8121019 L18.4642656,20.8244376 C18.8158932,20.8244376 19.1009438,20.5393871 19.1009438,20.1877594 L19.1009438,13.6618078 L11.766013,7.62371095 C11.5883383,7.48049609 11.3348641,7.48049609 11.1571894,7.62371095 Z M22.7459265,11.7306833 L12.6732794,3.43516423 C11.9681872,2.85494526 10.951036,2.85494526 10.2459438,3.43516423 L0.173296708,11.7306833 C0.0756432717,11.8113968 0.0140682555,11.9276094 0.00212714255,12.0537374 C-0.00981397044,12.1798654 0.0288577507,12.3055688 0.109628888,12.4031746 L1.12433477,13.6367386 C1.2048874,13.7346867 1.32109429,13.7965686 1.44732617,13.8087364 C1.57355806,13.8209042 1.69944544,13.7823584 1.79722404,13.7016002 L11.1571894,5.99222306 C11.3348641,5.8490082 11.5883383,5.8490082 11.766013,5.99222306 L21.1263763,13.7016002 C21.2239821,13.7823714 21.3496855,13.8210431 21.4758135,13.809102 C21.6019415,13.7971609 21.7181541,13.7355858 21.7988676,13.6379324 L22.8135735,12.4043684 C22.8942626,12.3061941 22.9324905,12.1799061 22.919794,12.0534635 C22.9070975,11.927021 22.8445239,11.8108548 22.7459265,11.7306833 Z" />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
