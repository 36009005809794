import React from "react";
import styled from "styled-components";
import { Title, Heading, Box } from "../sdk/Layout";
import { Modal } from "../sdk/Elements";
import { Buttons, Button } from "../sdk/Button";
import renderIf from "../utils/renderIf";
import Blueberry from "../../blueberry";
import { useTranslation } from "../../lang";

const Card = styled(Modal)`
    text-align: center;
    z-index: 9999;
`;

const Wrap = styled(Box)`
    position: relative;
    @media (max-width: 768px) {
        & ${Title} {
            margin-bottom: 10px;
        }
    }
`;

const Skip = styled.a`
    margin-top: 20px;
    height: 30px;
    padding: 0px 20px;
    display: inline-flex;
    align-items: center;
    border: solid 1px white;
    color: white;
    border-radius: 20px;
    opacity: 0.2;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 12px;
    user-select: none;
    cursor: pointer;
    @media (min-width: 769px) {
        &:hover {
            background-color: #fff;
            border-color: white;
            color: black;
            opacity: 0.8;
        }
    }
`;

const Image = styled.figure`
    img {
        width: 100%;
        display: block;
        margin: auto;
    }
    margin-bottom: 20px;
    user-select: none;
`;

const Page = styled.div`
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 12px;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: #e7eaea;
    color: #757678;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
`;

const Footer = styled(Buttons)`
    padding-top: 30px;

    @media (max-width: 768px) {
        padding-top: 20px;
    }
`;

function App({ handleCartChange }) {
    const [currentCard, setCurrentCard] = React.useState(0);
    const [_t] = useTranslation();
    const handleNextCard = () => {
        setCurrentCard((prev) => prev + 1);
    };

    const handleLastCard = () => {
        setCurrentCard((prev) => prev - 1);
    };

    const handleDone = () => {
        setCurrentCard(999);
        window.localStorage.setItem("didTutorial", true);
        handleCartChange();
        Blueberry.didTutorial();
    };

    return (
        <>
            {renderIf(currentCard === 0)(
                <Card className="is-active">
                    <span onClick={handleDone}></span>
                    <div>
                        <Wrap>
                            <Heading isCentered>
                                <Image>
                                    <img
                                        src="https://www.noritex.com/Ccx2Kqvs4sakmaoQdcapture-1.jpg"
                                        alt=""
                                    />
                                </Image>
                                <Title size="4" as="h4">
                                    Home
                                </Title>
                                <p>{_t("allBrands")}</p>
                                <Footer isCentered>
                                    <Button primary onClick={handleNextCard}>
                                        {_t("next")}
                                    </Button>
                                </Footer>
                            </Heading>
                            <Page>1/6</Page>
                        </Wrap>
                        <Skip onClick={handleDone}>{_t("skip")}</Skip>
                    </div>
                </Card>
            )}
            {renderIf(currentCard === 1)(
                <Card className="is-active">
                    <span onClick={handleDone}></span>
                    <div>
                        <Wrap>
                            <Heading isCentered>
                                <Image>
                                    <img
                                        src="https://www.noritex.com/4kGLDLSAmqRCtKTnbcapture-2.jpg"
                                        alt=""
                                    />
                                </Image>
                                <Title size="4" as="h4">
                                    {_t("searchBar")}
                                </Title>
                                <p>{_t("searchBarHelp")}</p>
                                <Footer isCentered>
                                    <Button primary onClick={handleLastCard}>
                                        {_t("back")}
                                    </Button>
                                    <Button primary onClick={handleNextCard}>
                                        {_t("next")}
                                    </Button>
                                </Footer>
                            </Heading>
                            <Page>2/6</Page>
                        </Wrap>
                        <Skip onClick={handleDone}>{_t("skip")}</Skip>
                    </div>
                </Card>
            )}
            {renderIf(currentCard === 2)(
                <Card className="is-active">
                    <span onClick={handleDone}></span>
                    <div>
                        <Wrap>
                            <Heading isCentered>
                                <Image>
                                    <img
                                        src="https://www.noritex.com/i2kcCsdyGQpB8Bg6ecapture-3.jpg"
                                        alt=""
                                    />
                                </Image>
                                <Title size="4" as="h4">
                                    {_t("cart")}
                                </Title>
                                <p>{_t("addProductsToCart")}</p>
                                <Footer isCentered>
                                    <Button primary onClick={handleLastCard}>
                                        {_t("back")}
                                    </Button>
                                    <Button primary onClick={handleNextCard}>
                                        {_t("next")}
                                    </Button>
                                </Footer>
                            </Heading>
                            <Page>3/6</Page>
                        </Wrap>
                        <Skip onClick={handleDone}>{_t("skip")}</Skip>
                    </div>
                </Card>
            )}
            {renderIf(currentCard === 3)(
                <Card className="is-active">
                    <span onClick={handleDone}></span>
                    <div>
                        <Wrap>
                            <Heading isCentered>
                                <Image>
                                    <img
                                        src="https://www.noritex.com/WLcyBMaRZxETzLHm3capture-4.jpg"
                                        alt=""
                                    />
                                </Image>
                                <Title size="4" as="h4">
                                    Wishlist
                                </Title>
                                <p>{_t("wishlistHelp")}</p>
                                <Footer isCentered>
                                    <Button primary onClick={handleLastCard}>
                                        {_t("back")}
                                    </Button>
                                    <Button primary onClick={handleNextCard}>
                                        {_t("next")}
                                    </Button>
                                </Footer>
                            </Heading>
                            <Page>4/6</Page>
                        </Wrap>
                        <Skip onClick={handleDone}>{_t("skip")}</Skip>
                    </div>
                </Card>
            )}
            {renderIf(currentCard === 4)(
                <Card className="is-active">
                    <span onClick={handleDone}></span>
                    <div>
                        <Wrap>
                            <Heading isCentered>
                                <Image>
                                    <img
                                        src="https://www.noritex.com/cJQ433H7Ps2pHgbgfcapture-5.jpg"
                                        alt=""
                                    />
                                </Image>
                                <Title size="4" as="h4">
                                    {_t("ordersHistory")}
                                </Title>
                                <p>{_t("ordersHistoryHelp")}</p>
                                <Footer isCentered>
                                    <Button primary onClick={handleLastCard}>
                                        {_t("back")}
                                    </Button>
                                    <Button primary onClick={handleNextCard}>
                                        {_t("next")}
                                    </Button>
                                </Footer>
                            </Heading>
                            <Page>5/6</Page>
                        </Wrap>
                        <Skip onClick={handleDone}>{_t("skip")}</Skip>
                    </div>
                </Card>
            )}
            {renderIf(currentCard === 5)(
                <Card className="is-active">
                    <span onClick={handleDone}></span>
                    <div>
                        <Wrap>
                            <Heading isCentered>
                                <Image>
                                    <img
                                        src="https://www.noritex.com/HZe6RoWucjRbeh4Dgcapture-6.jpg"
                                        alt=""
                                    />
                                </Image>
                                <Title size="4" as="h4">
                                    {_t("tendencieTable")}
                                </Title>
                                <p>{_t("tendencieTableHelp")}</p>
                                <Footer isCentered>
                                    <Button onClick={handleDone} primary>
                                        {_t("done")}
                                    </Button>
                                </Footer>
                            </Heading>
                            <Page>6/6</Page>
                        </Wrap>
                        <Skip onClick={handleDone}>{_t("skip")}</Skip>
                    </div>
                </Card>
            )}
        </>
    );
}

export default App;
