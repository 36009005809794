import React from "react";
import Cover from "../components/Cover";

import { Section, Heading } from "../../sdk/Layout";

import { Icon } from "../../sdk/Button";
import Icons from "../components/Icons";
import ProductGrid from "../../components/ProductGrid2";
import { SectionHeader } from "../styles";
import Blueberry from "../../../blueberry";
import { useTranslation } from "../../../lang";

function History({ recomendations, addToCart, viewItem }) {
    const [_t] = useTranslation();
    const [isLoading, setIsLoading] = React.useState(false);
    React.useEffect(() => {
        setIsLoading(true);
        Blueberry.history({
            logic: "recombee:recently-viewed",
            limit: 100,
        }).then((resp) => {
            setIsLoading(false);
            console.log(resp);
        });
    }, []);
    return (
        <>
            <Section>
                <SectionHeader>
                    <h3>{_t("navigationHistory")}</h3>
                    <p>{_t("recentlyViewedProducts")}</p>
                </SectionHeader>

                <ProductGrid
                    grid="x5"
                    addToCart={addToCart}
                    viewItem={viewItem}
                    products={recomendations}
                    isRetrivingProducts={isLoading}
                />
            </Section>
        </>
    );
}

export default History;
