import React from "react";
import { Nav } from "../style";
import InternalLink from "../../InternalLink";
import IconLibrary from "../../Icons2";
import { Column } from "../../../sdk/Layout";

function FooterMenu({ config, isLoggedIn }) {
    const [isMenuVisible, setIsMenuVisible] = React.useState(false);
    const toggleMenu = () => {
        setIsMenuVisible((prevState) => !prevState);
    };
    return (
        <Column>
            <Nav>
                <h3
                    className={isMenuVisible ? "is-active" : ""}
                    onClick={toggleMenu}
                >
                    {config.title}{" "}
                    <IconLibrary icon="chevron-down"></IconLibrary>
                </h3>
                <ul>
                    {config.children &&
                        config.children.map((index, i) => {
                            if (index.link === "/" && isLoggedIn)
                                index.link = "/home-login/";
                            return (
                                <li key={i + "footer-sub" + index.title}>
                                    <InternalLink {...index}>
                                        {index.title}
                                    </InternalLink>
                                </li>
                            );
                        })}
                </ul>
            </Nav>
        </Column>
    );
}

export default FooterMenu;
