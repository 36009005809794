import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import { Wrapper, Main } from "./styles";

// import CartPopup from "./components/CartPopup";
import ImageExport from "./views/ImageExport/index";
import History from "./views/History";
import Pages from "./views/Pages";
import Admin from "./views/Admin";
import HomePage from "./views";

function HomeContainer({
    user,
    location,
    wishlists,
    menus,
    _t,
    recomendations,
    history,
    addToCart,
    viewItem,
    jsonSettings,
    lang,
    gotoThankYouPage,
    toggleModalProduct,
    childCollection,
    data,
    isLoadingPage,
}) {
    return (
        <Wrapper>
            {/* <CartPopup /> */}

            <Main className="main">
                <Switch>
                    <Route path="/:lang/:brandSlug?/home-login/image-export">
                        <ImageExport
                            user={user}
                            location={location}
                            wishlists={wishlists}
                            _t={_t}
                            lang={lang}
                        />
                    </Route>

                    <Route path="/:lang/:brandSlug?/home-login/history">
                        <History
                            user={user}
                            recomendations={history}
                            addToCart={addToCart}
                            viewItem={viewItem}
                        />
                    </Route>
                    <Route path="/:lang/:brandSlug?/home-login/p/:page">
                        <Pages
                            user={user}
                            recomendations={recomendations}
                            location={location}
                            gotoThankYouPage={gotoThankYouPage}
                            viewItem={viewItem}
                            toggleModalProduct={toggleModalProduct}
                        />
                    </Route>
                    <Route path="/:lang/:brandSlug?/home-login/admin">
                        <Admin user={user} />
                    </Route>
                    <Route exact path="/:lang/:brandSlug?/home-login/news">
                        <Redirect to={`/${lang}/home-login/`} />
                    </Route>
                    <Route path="/:lang/home-login/">
                        <HomePage
                            location={location}
                            _t={_t}
                            recomendations={recomendations}
                            addToCart={addToCart}
                            viewItem={viewItem}
                            gotoThankYouPage={gotoThankYouPage}
                            toggleModalProduct={toggleModalProduct}
                            childCollection={childCollection}
                            data={data}
                            isLoadingPage={isLoadingPage}
                        />
                    </Route>
                </Switch>
            </Main>
        </Wrapper>
    );
}

export default HomeContainer;
