export default {
    individualHelp: "Individual",
    individualHelp: "Exportar apenas a imagem frontal do produto",
    zoom: "Aproximação",
    zoomHelp: "Exporta apenas um zoom do material do produto",
    thelook: "Set",
    thelookHelp: "Exportar apenas a configuração do produto",
    all: "Tudo",
    allHelp: "Exportar todas as imagens do produto",
	allExceptsLook: "Tudo exceto configuração",
	allExceptsLookHelp: "Exportar todas as imagens do produto exceto a configuração",
    videos: "Videos",
    videosHelp: "Export all videos of the products",
    helpDownload: "Estamos gerando o seu arquivo, você pode aguardar alguns minutos aqui ou enviaremos pelo correio",
    alertReferences: "Não há referência válida para exportação",
    alertVideos: "As referências enviadas não possuem vídeos",
    alertZoom: "A opção de zoom permite apenas até 25 referências",
};
