import styled, { css } from "styled-components";
// import { Container, Section, Columns, Column, Title, Box } from "../sdk/Layout";
import { Icon } from "../../sdk/Button";
// import { Field, Control, SelectBox } from "../sdk/Form";
// import { Preloader } from "../sdk/Elements";

export const Logo = styled.div`
    svg {
        width: 20px;
        height: 20px;
        color: ${props => props.theme.primaryDarkMode};
    }
`;
export const Wrapper = styled.div`
    width: 100%;
    z-index: 9999;
    position: fixed;
    height: 100vh;
`;
export const BackDrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 9995;
    background-color: rgba(0, 0, 0, 0.3);
    display: ${(props) => (props.isActive ? "block " : " none")};
`;

export const Close = styled(Icon)`
    cursor: pointer;
`;

export const Nav = styled.div`
    /* position: absolute; */
    /* top: 0; */
    /* left: 0; */
    /* width: 100%; */
    position: fixed;
    left: 0;
    top: 0;
    /* height: 100vh; */
    height: 100%;
    z-index: 9998;
    -webkit-transition: 0.3s;
    transition: 0.3s;

    -webkit-transform: translate3d(-320px, 0px, 0);
    transform: translate3d(-320px, 0px, 0);

    -webkit-transform: translate(-320px, 0px);
    transform: translate(-320px, 0px);

    &.open {
        -webkit-transform: translate3d(0px, 0px, 0);
        transform: translate3d(0px, 0px, 0);

        -webkit-transform: translate(0px, 0px);
        transform: translate(0px, 0px);
    }
    @media (max-width: 980px) {
        ${(props) =>
            props.subLevelOpen
                ? `.hide-on-movil-open {
        display: none;
    }`
                : ""}
    }
    /* background-color: rgba(0, 0, 0, 0.3) ; */
    > div {
        background-color: ${props => props.theme.white};
        width: 320px;
        height: 100%;
        position: relative;
        padding-top: 50px;
        display: flex;
        flex-direction: column;
        --safe-area-inset-left: env(safe-area-inset-left, 0px);
        padding-left: var(--safe-area-inset-left) !important;
    }
    ul {
        li {
            position: relative;
            a {
                font-size: 14px;
                color: ${props => props.theme.black};;
                padding: 10px 20px 10px 0;

                display: flex;
                align-items: center;
                cursor: pointer;
                :hover {
                    text-decoration: underline;
                }
                svg {
                    width: 24px;
                    height: 24px;
                    margin-right: 15px;
                }
            }
            &.has-children {
                a:before {
                    content: "";
                    display: block;
                    width: 8px;
                    height: 8px;
                    border-right: solid 1px ${props => props.theme.gray};;
                    border-bottom: solid 1px ${props => props.theme.gray};;
                    position: absolute;
                    top: 50%;
                    margin-top: -5px;
                    right: 0;
                    transform: rotate(-45deg);
                }
            }
        }
    }
`;

export const Level = styled.div``;

export const NavHeader = styled.div`
    background-color: ${props => props.theme.grayLight};;
    /* border-bottom: 1px solid #e3e3e3; */
    padding: 0 10px 0 20px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    flex: none;
    z-index: 2;
`;

export const NavBody = styled.div`
    padding: 40px 25px;
    padding-top: 0;
    padding-right: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    .menu-level {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 25px 25px 0 0;
    }
    h2 {
        margin-bottom: 15px;
    }
    .menu-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        a {
            font-size: 14px;
            text-decoration: underline;
            color: ${props => props.theme.primaryDark};
        }
        h2 {
            margin-bottom: 0;
        }
    }
`;

export const Divisor = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.borderColor};
    margin: 30px 0;
`;

export const Tags = styled.div`
    font-size: 0;
    margin-top: -5px;
    margin-bottom: -5px;
    margin-right: -5px;
    margin-left: -5px;
`;

export const Tag = styled.a`
    display: inline-block;
    vertical-align: middle;
    margin: 5px;
    font-size: 12px;
    background-color: #e8e6e9;
    padding: 5px 10px;
    border-radius: 30px;
    box-shadow: 0px 4px 5px -4px rgba(0, 0, 0, 0.2);
    color: #464646;
    cursor: pointer;
`;

export const Back = styled.div`
    display: flex;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    color: ${props => props.theme.primaryDark};
    svg {
        width: 12px;
        margin-right: 10px;
        color: ${props => props.theme.primaryDark};
    }
`;
