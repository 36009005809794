import React, { useState } from "react";

function useSelect({ options }) {
    const [value, setValue] = useState(options ? options[0].value : "");

    const select = (
        <select value={value} onChange={(e) => setValue(e.target.value)}>
            {options.map((index) => (
                <option value={index.value} key={index.key}>
                    {index.label}
                </option>
            ))}
        </select>
    );
    return [value, select, setValue];
}

export default useSelect;
