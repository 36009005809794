import React from "react";
import styled from "styled-components";
import { Media, Player, controls } from "react-media-player";

import Section, { SectionHeader, SectionSubtitle } from "./Section";
import MediaButton from "./MediaButton";
import Container from "./Container";
import renderIf from "../utils/renderIf";
const {
    CurrentTime,

    SeekBar,
    Duration,
} = controls;

const PlayerWrapper = styled.div`
    width: 90% !important;
    max-width: 400px !important;
    margin: auto;
    height: 56px;
    -webkit-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.07);
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.07);
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #fff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
    margin: auto;
`;

const Holder = styled.div`
    position: relative;
    /*display: block;*/
    /*max-width: 300px;*/
    width: 100%;
    video,
    audio {
        height: 0;
        width: 0;
    }
`;

const ControlWrapper = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 18px;
    color: #55606e;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 0px;
    margin-right: 15px;
    top: -8px;
    position: relative;
    outline: none;
    width: 100%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    input {
        width: calc(100%);
    }
    time {
        font-size: 16px;
        line-height: 18px;
        color: #55606e;
    }
`;

const FlexAign = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-top: 20px;
`;

const PlayButtonWrapper = styled.div`
    max-width: 40px;
    height: 50px;
    margin-top: 3px;
    float: left;
    align-items: center;
    width: 100%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
`;

export default function Audio({ config, className }) {
    if (config == null || config.length === 0 || config[0] == null) return null;
    return (
        <Section gray>
            <Container>
                {renderIf(config[0].title && config[0].title !== "")(
                    <SectionHeader centered>
                        <SectionSubtitle centered>
                            {config[0].title}
                        </SectionSubtitle>
                        <p>{config[0].paragraph}</p>
                    </SectionHeader>
                )}
                <Media>
                    <PlayerWrapper className={className}>
                        <Holder>
                            <div className="">
                                <Player
                                    src={config[0].audio.img[0]}
                                    autoPlay={false}
                                />
                            </div>
                            <FlexAign>
                                <PlayButtonWrapper>
                                    {/* <PlayPause></PlayPause> */}
                                    <MediaButton />
                                    {/* <MuteUnmute /> */}
                                </PlayButtonWrapper>

                                <ControlWrapper>
                                    <CurrentTime />
                                    <SeekBar />
                                    <Duration />
                                </ControlWrapper>
                            </FlexAign>
                        </Holder>
                    </PlayerWrapper>
                </Media>
            </Container>
        </Section>
    );
}
