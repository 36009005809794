import React, { Component } from "react";
import { withMediaProps } from "react-media-player";
import styled from "styled-components";

const Button = styled.button`
    border: 0;
    cursor: pointer;
    background-color: transparent;
`;
class MediaButton extends Component {
    shouldComponentUpdate({ media }) {
        return this.props.media.isPlaying !== media.isPlaying;
    }

    _handlePlayPause = () => {
        this.props.media.playPause();
    };

    render() {
        const { className, style, media } = this.props;
        return (
            <Button
                type="button"
                className={className}
                style={style}
                onClick={this._handlePlayPause}
            >
                {media.isPlaying ? (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={18}
                        height={24}
                        viewBox="0 0 18 24"
                    >
                        <path
                            fill="#566574"
                            fillRule="evenodd"
                            d="M0 0h6v24H0zM12 0h6v24h-6z"
                        />
                    </svg>
                ) : (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={18}
                        height={24}
                        viewBox="0 0 18 24"
                    >
                        <path
                            fill="#566574"
                            fillRule="evenodd"
                            d="M18 12L0 24V0"
                        />
                    </svg>
                )}
            </Button>
        );
    }
}

export default withMediaProps(MediaButton);
