import React, { useState } from "react";
// import { openConsentManager, } from "@segment/consent-manager";
import IconLibrary from "../Icons2";
import {
    Footer,
    Brand,
    Social,
    Nav,
    Copy,
    Appstore,
    Language,
    ConsentButton,
} from "./style";
import FooterMenu from "./components/FooterMenu";
import ConsentManager from "./components/ConsentManager";

import {
    Container,
    Section,
    Columns,
    Column,
    Level,
    LevelLeft,
    LevelRight,
    LevelItem,
} from "../../sdk/Layout";
import { Buttons, Button } from "../../sdk/Button";
import Logo from "../Logo";

function FooterComponent({
    lang,
    _t,
    gotoRegister,
    registerLink,
    config,
    isLoggedIn,
    themeToggler,
    theme,
}) {
    const [cookies, setCookies] = useState(true);
    const [isLanguagePicker, setIsLanguagePicker] = useState(false);

    const close = () => {
        setCookies(false);
    };
    const toggleLanguagePicker = () => {
        setIsLanguagePicker((prev) => !prev);
    };

    const clearCache = () => {
        console.log("clear");

        navigator.serviceWorker.getRegistrations().then((registrations) => {
            console.log(registrations);
            for (let registration of registrations) {
                registration.unregister();
            }
            location.reload();
        });
    };

    return (
        <>
            <Footer>
                <Container>
                    <Columns>
                        <Brand isNarrow>
                            <Logo></Logo>

                            <div>
                                <Social>
                                    <a href="https://www.instagram.com/noritexworldwide/" target="_blank" rel="noopener">
                                        <IconLibrary icon="instagram"></IconLibrary>
                                    </a>
                                    <a href="https://www.linkedin.com/company/noritex-s.a./" target="_blank" rel="noopener">
                                        <IconLibrary icon="linkedin"></IconLibrary>
                                    </a>
                                    <a href="https://www.facebook.com/noritexworldwide/" target="_blank" rel="noopener">
                                        <IconLibrary icon="facebook"></IconLibrary>
                                    </a>
                                </Social>
                                {/* <Appstore>
                                    <a href="https://play.google.com/store/apps/details?id=com.noritex.twa&hl=en">
                                        <IconLibrary icon="playstore"></IconLibrary>
                                    </a>
                                </Appstore> */}
                            </div>

                            <h3>{_t("whantToBeDistributor")}</h3>
                            <p>
                                <a
                                    onClick={gotoRegister}
                                    href={registerLink()}
                                    className="click"
                                    title="Register"
                                >
                                    {_t("getMoreInformation")}
                                </a>{" "}
                                {_t("getMoreInformation2")}
                            </p>
                            <div>
                                <ConsentButton
                                    as="a"
                                    href={`/${lang}/cookie-manager`}
                                >
                                    Website Data Collection Preferences
                                </ConsentButton>
                            </div>
                        </Brand>
                        {config
                            ? config.map((index, i) => (
                                  <FooterMenu
                                      key={`footer-column-${i}`}
                                      config={index}
                                      isLoggedIn={isLoggedIn}
                                  />
                              ))
                            : null}
                    </Columns>
                </Container>
                <Copy>
                    <Container isFluid>
                        <Level>
                            <LevelLeft>
                                <LevelItem>
                                    <p>
                                        © Copyright Noritex 2024 - App Version:{" "}
                                        <strong>2.1.80</strong>
                                    </p>
                                </LevelItem>
                            </LevelLeft>
                            <LevelRight>
                                <LevelItem>
                                    <Buttons>
                                        <Language
                                            isUp
                                            isActive={isLanguagePicker}
                                        >
                                            <Button
                                                onClick={toggleLanguagePicker}
                                                small
                                            >
                                                <IconLibrary icon="globe"></IconLibrary>

                                                <span>
                                                    {lang === "es"
                                                        ? "Español"
                                                        : lang === "en"
                                                        ? "English"
                                                        : "Português"}
                                                </span>
                                            </Button>
                                            <div>
                                                <div>
                                                    <ul>
                                                        <li>
                                                            <a href="/en/">
                                                                English
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/es/">
                                                                Español
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/pt/">
                                                                Português
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Language>
                                        <Button small onClick={clearCache}>
                                            Clear cache
                                        </Button>
                                        <Button small onClick={themeToggler}>
                                            Dark Mode:{" "}
                                            {theme === "light" ? "off" : "on"}
                                        </Button>
                                    </Buttons>
                                </LevelItem>
                            </LevelRight>
                        </Level>
                    </Container>
                </Copy>
                <ConsentManager lang={lang} />
            </Footer>
        </>
    );
}

export default FooterComponent;
