import React from "react";

export default function HeartIcon() {
    return (
        <svg
            width="23px"
            height="21px"
            viewBox="0 0 23 21"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <g
                    id="current_bar"
                    transform="translate(-668.000000, -139.000000)"
                    fill="#000000"
                    fillRule="nonzero"
                >
                    <g
                        id="Group-8-Copy"
                        transform="translate(563.000000, 120.000000)"
                    >
                        <g id="Group">
                            <g
                                id="Stacked-Group"
                                transform="translate(34.000000, 18.000000)"
                            >
                                <g
                                    id="heart"
                                    transform="translate(71.000000, 0.000000)"
                                >
                                    <path d="M20.7761095,2.37670314 C18.3321251,0.29595312 14.6773595,0.641249997 12.4082657,2.98209377 L11.5248438,3.89690627 L10.641422,2.98657814 C8.81179694,1.09417188 5.12115629,-0.0448593829 2.27357814,2.37670314 C-0.542609379,4.78032815 -0.690593755,9.09429694 1.82962501,11.6952345 L10.5068907,20.6550158 C10.784922,20.9420158 11.1526407,21.0900002 11.5203595,21.0900002 C11.8880782,21.0900002 12.255797,20.9465002 12.5338282,20.6550158 L21.2110939,11.6952345 C23.7402814,9.09429694 23.5922971,4.78032815 20.7761095,2.37670314 Z M20.1886564,10.6997032 L11.5427813,19.6594845 L2.86103127,10.6997032 C1.13903126,8.92389069 0.780281256,5.53818753 3.20632815,3.47089064 C5.66376567,1.37220313 8.55170319,2.89240626 9.6100157,3.98659377 L11.5248438,5.96420316 L13.439672,3.98659377 C14.480047,2.91034376 17.3948908,1.38565625 19.8433595,3.47089064 C22.264922,5.53370316 21.9106564,8.91940631 20.1886564,10.6997032 Z" />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
