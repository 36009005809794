import styled, { css } from "styled-components";

import { Title, Subtitle, Box, Columns, Column } from "../../sdk/Layout";
import { Modal } from "../../sdk/Elements";
import { Buttons, Button } from "../../sdk/Button";

export const Card = styled(Modal)`
    z-index: 9999;
    text-align: center;
    > div {
        max-width: 980px;
    }
`;

export const Wrap = styled(Box)`
    position: relative;
    text-align: left;
    padding: 50px 200px;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ${Subtitle} {
        line-height: 24px;
    }
    p {
        font-size: 14px;
        margin-top: 15px;
    }
    @media (max-width: 768px) {
        ${Title} {
            margin-bottom: 10px;
        }
    }
`;

export const Skip = styled.a`
    margin-top: 20px;
    height: 30px;
    padding: 0px 20px;
    display: inline-flex;
    align-items: center;
    border: solid 1px white;
    color: white;
    border-radius: 20px;
    opacity: 0.2;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 12px;
    user-select: none;
    cursor: pointer;
    @media (min-width: 769px) {
        &:hover {
            background-color: #fff;
            border-color: white;
            color: black;
            opacity: 0.8;
        }
    }
`;

export const Image = styled.figure`
    position: relative;
    overflow: hidden;
    display: block;
    border-radius: 20px;
    &:before {
        content: "";
        display: block;
        padding-top: 100%;
    }
    img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
    }
    margin-bottom: 20px;
    user-select: none;
`;

// export const Page = styled.div`
//   position: absolute;
//   bottom: 10px;
//   right: 10px;
//   font-size: 12px;
//   width: 30px;
//   height: 30px;
//   border-radius: 100%;
//   background-color: #e7eaea;
//   color: #757678;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   user-select: none;
// `;

export const Footer = styled(Buttons)`
    padding-top: 30px;

    @media (max-width: 768px) {
        padding-top: 20px;
    }
`;

export const Logo = styled.div`
    text-align: center;
    margin-bottom: 10px;
    svg {
        fill: #112c55;
        width: 120px;
    }
`;

export const SelectedMixin = css`
    background-color: #112c55 !important;
    border-color: #112c55 !important;
    color: #fff !important;
`;

export const ToggleBtn = styled.button`
    border-radius: 30px;
    border: none;
    background-color: #fff;
    border: solid 2px #e3e3e3;
    height: 40px;
    display: inline-flex;
    align-items: center;
    padding: 0px 20px;
    font-size: 14px;
    width: 100%;
    color: #5c5c5c;
    cursor: pointer;
    justify-content: center;
    font-weight: bold;
    &:hover {
        background-color: #f8f8f8;
        color: #323232;
    }
    ${(props) => props.selected && SelectedMixin}
`;

export const ToggleBtns = styled.div`
    margin-left: -1%;
    margin-right: -1%;
    ${ToggleBtn} {
        width: 48%;
        margin: 1%;
    }
`;

export const Back = styled.div`
    position: absolute;
    left: 20px;
    top: 20px;
    cursor: pointer;
    svg {
        width: 22px;
        color: #032c58;
    }
`;

export const Ico = styled.div`
    text-align: center;
    padding-bottom: 20px;
    opacity: 0.3;
    svg {
        width: 200px;
    }
`;
