import { useState, useMemo } from "react";
import { BlueberryData } from "../BlueberryData";

function useInCart2(productId) {
    const [inCart, setInCart] = useState(null);
    const [cookieTokens, setCookieTokens] = useState([]);

    useMemo(() => {
        const tokens = [];
        let inCartF = null;
        const carts = BlueberryData.cart;
        if (productId && carts && carts.length > 0) {
            for (let index = 0; index < carts.length; index++) {
                const currentCart = carts[index];
                for (let j = 0; j < currentCart.items.length; j++) {
                    if (currentCart.items[j].productId === productId) {
                        tokens.push(currentCart.cookie_token);
                        inCartF = currentCart.items[j].quantity;
                        break;
                    }
                }
            }
        }
        setCookieTokens(tokens);
        setInCart(inCartF);
    }, [BlueberryData.cart, productId]);
    return { inCart, cookieTokens };
}

export default useInCart2;
