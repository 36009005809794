import { useState, useEffect } from "react";
import { BlueberryData } from "../BlueberryData";

function useDirectos() {
    const [isDirectos, setIsDirectos] = useState(false);

    useEffect(() => {
        if (
            BlueberryData.user &&
            BlueberryData.user.id &&
            BlueberryData.user.tags &&
            BlueberryData.user.tags.indexOf("directos") > -1
        )
            setIsDirectos(true);
    }, [BlueberryData.user]);
    return isDirectos;
}

export default useDirectos;
