import React from "react";
import styled from "styled-components";
import SwipeableViews from "react-swipeable-views";
import Sticky from "./ReactSticky/Sticky";
import { virtualize, bindKeyboard } from "react-swipeable-views-utils";
import { mod } from "react-swipeable-views-core";
import QuickView from "./QuickView";

const WrapperScroll = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100vh;
`;

const QuickViewWrapper = styled.div`
    flex: none;
    /* width: 100%; */
    width: 42%;
    background-color: ${(props) => props.theme.white};

    @media (max-width: 768px) {
        box-shadow: 0 8px 12px -9px rgba(0, 0, 0, 0.5);
        background-color: ${(props) => props.theme.white};
        width: 100%;
        z-index: 5;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 302;
        max-height: 100vh;
        overflow-y: auto;
        overflow-x: hidden;

        --safe-area-inset-top: env(safe-area-inset-top, 10px);

        padding-top: var(--safe-area-inset-top) !important;

        -webkit-animation-duration: 0.4s;
        animation-duration: 0.4s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;

        @-webkit-keyframes slideInUp {
            from {
                -webkit-transform: translate3d(0, 100%, 0);
                transform: translate3d(0, 100%, 0);
                visibility: visible;
            }

            to {
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
            }
        }

        @keyframes slideInUp {
            from {
                -webkit-transform: translate3d(0, 100%, 0);
                transform: translate3d(0, 100%, 0);
                visibility: visible;
            }

            to {
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
            }
        }

        -webkit-animation-name: slideInUp;
        animation-name: slideInUp;
    }
`;

const StickyWrapper = ({ isSticky, children }) => {
    // if (!isSticky) return children;
    return (
        <Sticky topOffset={-106} disableCompensation={true}>
            {({ style, distanceFromBottom, distanceFromTop, stikcyHeight }) => {
                const newStyle = {
                    ...style,
                    top: 106,
                    height: stikcyHeight,
                };

                return (
                    <WrapperScroll style={isSticky ? newStyle : {}}>
                        {children}
                    </WrapperScroll>
                );
            }}
        </Sticky>
    );
};

const VirtualizeSwipeableViews = bindKeyboard(virtualize(SwipeableViews));

function QuickViewVirtualized(props) {
    const {
        products,
        productIndex,
        setProductIndex,
        isSticky,
        isBackProductActive,
        isNextProductActive,
        productLength,
        asociatedProducts,
        hideQuickView,
        viewItem,
        addToCart,
        width,
        removeItem,
        nextProduct,
        backProduct,
        itemToItem,
        ambientProducts,
        jsonSettings,
        refetch,
        replaceProductObject,
    } = props;

    const positionRef = React.useRef(null);
    const refTimeout = React.useRef(null);

    function slideRenderer(params) {
        const { index, key } = params;
        const modInd = mod(index, props.productLength);

        if (modInd >= productIndex - 1 && modInd <= productIndex + 1) {
            let productForCurrent =
                products && modInd > -1 ? products[modInd] : null;

            if (replaceProductObject && modInd === productIndex)
                productForCurrent = replaceProductObject;
            return (
                <QuickView
                    {...props}
                    key={key}
                    product={productForCurrent}
                    isActive={productIndex === modInd}
                    isBackProductActive={isBackProductActive}
                    isNextProductActive={isNextProductActive}
                    productLength={productLength}
                    hideQuickView={hideQuickView}
                    asociatedProducts={asociatedProducts}
                    viewItem={viewItem}
                    addToCart={addToCart}
                    jsonSettings={jsonSettings}
                    removeItem={removeItem}
                    isSticky={isSticky}
                    width={width}
                    refetch={refetch}
                    itemToItem={itemToItem}
                    nextProduct={nextProduct}
                    backProduct={backProduct}
                    ambientProducts={ambientProducts}
                />
            );
        } else return <div key={key} />;
    }

    React.useEffect(() => {
        if (width < 769 && productIndex >= 0) {
            const nextPosition =
                window.pageYOffset || document.documentElement.scrollTop;

            if (nextPosition > 0) {
                positionRef.current = nextPosition;
            }

            refTimeout.current = setTimeout(() => {
                const d = document.getElementById("main-body");
                d.className += " ReactModal__Body--open";
                refTimeout.current = null;
            }, 1200);

            return () => {
                if (refTimeout.current) clearTimeout(refTimeout.current);
                const d = document.getElementById("main-body");

                d.className = d.className.replace(
                    /\ReactModal__Body--open\b/g,
                    ""
                );
                document.documentElement.scrollTop = positionRef.current;
            };
        }
    }, [productIndex]);

    if (productIndex < 0) return null;

    return (
        <QuickViewWrapper>
            <StickyWrapper isSticky={isSticky}>
                <VirtualizeSwipeableViews
                    index={productIndex}
                    onChangeIndex={setProductIndex}
                    overscanSlideAfter={1}
                    animateTransitions={width < 769}
                    overscanSlideBefore={1}
                    slideCount={props.productLength}
                    slideRenderer={slideRenderer}
                />
            </StickyWrapper>
        </QuickViewWrapper>
    );
}

export default QuickViewVirtualized;
