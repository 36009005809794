import React from "react";
import Helmet from "react-helmet";
// import ReactTooltip from "react-tooltip";
import ProductSectionTemplate from "../templates/ProductsSectionTemplate";

import withTracker from "../../blueberry/connectPromise";
import Blueberry from "../../blueberry/index";
import ColectionView2 from "./CollectionView2";
import Banner from "../components/Banner";
import renderIf from "../utils/renderIf";
// import { getParameterByName } from "../utils";

export const getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
};

class Collection extends ProductSectionTemplate {
    constructor(props) {
        super(props);
        const lastKnownProduct = Blueberry.getLastKnownProduct();
        this.state = {
            key: 0,
            lastKnownProduct,
            isHeaderOpen: true,
            // tags: [],
            // allTags: [],
            ...this.productDefaultState,
        };
    }

    changeQueries = (text, page, limit, tags, order, userTags) => {
        this.setWindowTop();

        const query = `?qry=${text}&page=${page}&limit=${limit}&order=${order}&${
            tags ? `facet=${tags ? tags.join("+") : ""}` : ""
        }&userTags=${userTags ? userTags.join("+") : ""}`;

        this.props.history.push(`/${this.getBasePath()}/search/${query}`);
    };

    retrieveProducts = (text, page, limit, tags, order, userTags) => {
        const { defaultTags, brandSlug } = this.props.match.params;
        this.setState({ isRetrivingProducts: true });

        Blueberry.productSearch({
            text,
            page,
            limit,
            tags: userTags,
            facetsTags: tags ? tags : [],
            order,
            brandSlug,
        })
            .then((resp) => {
                resolve(resp);
                this.setState({ isRetrivingProducts: false });
            })
            .catch((err) => {
                this.setState({ isRetrivingProducts: false });
            });
    };

    getRecomendations = (slug) => {
        Blueberry.itemToItem({ slug, scenario: "similaritems" })
            .then(() => {})
            .catch((err) => {
                // this.setState({isLoadingItems: false, errorLoadingItems: true})
            });
    };

    onRemoveTag = (e) => {
        const tag = e.currentTarget.getAttribute("data-tag");
        let tags = [...this.state.tags];
        tags = tags.filter((index) => index !== tag);
        this.setState({ tags });
    };

    customTracker(slug, product) {
        if (product) {
            Blueberry.productClick({
                slug,
                productId: product._id,
                searchQuery: getParameterByName("qry"),
            });
        }
    }

    // handleSearchProducts = (text) => {
    //     if (this.searchTimeout != null) {
    //         clearTimeout(this.searchTimeout);
    //     }
    //     this.searchTimeout = setTimeout(() => {
    //         window.scrollTo(0, 0);
    //         const { lang } = this.props.match.params;
    //         const searchQuery = this.props.location.search;
    //         this.setWindowTop();

    //         let currentPage = 1;
    //         let limit = getParameterByName("limit", searchQuery);
    //         let order = getParameterByName("order", searchQuery);

    //         let facet = getParameterByName("facet", searchQuery);

    //         currentPage = currentPage ? currentPage : 1;
    //         limit = limit ? limit : 35;
    //         order = order ? order : null;
    //         facet = [];

    //         const query = `?qry=${text}&page=${currentPage}&limit=${limit}&order=${order}`;

    //         this.props.history.push(`/${lang}/search/${query}`);
    //     }, 400);
    // };

    getCompleteTheLook = (slug) => {
        Blueberry.backWardFindCollectionProducts({ slug })
            .then((resp) => {
                this.setState({
                    isLoadingAmbient: false,
                    ambientProducts: resp,
                });
            })
            .catch(() => {
                this.setState({
                    isLoadingAmbient: false,
                    ambientProducts: null,
                });
            });
        Blueberry.productAssociations({ slug })
            .then((resp) => {
                this.setState({
                    asociatedProducts: resp,
                });
            })
            .catch(() => {
                this.setState({
                    asociatedProducts: null,
                });
            });
    };

    renderBody() {
        const { search, itemToItem, user } = this.props.store;
        const { isRetrivingProducts, tags, asociatedProducts } = this.state;

        const { lang } = this.props.match.params;
        const { location } = this.props;
        const slug = this.props.match.params.collection;
        const globalTags = this.props.match.params.globalTags;
        let searchQ = getParameterByName("qry", location.search);
        const data = {
            title: `${this._t("searchResults")}: ${searchQ}`,
            descrition: "Noritex search",
        };
        const { ambientProducts, lastKnownProduct } = this.state;

        const title =
            data && data.seo && data.seo.title != ""
                ? data.seo.title
                : data
                ? data.title
                : "";
        const description = data.descrition;
        const image = "https://www.noritex.com/facebook-ntx.jpg";

        const baseUrl = this.stripLangDir(lang, location.pathname);
        const enUrl = this.addLangDir("en", location.pathname, baseUrl);
        const esUrl = this.addLangDir("es", location.pathname, baseUrl);

        return (
            <>
                <Helmet htmlAttributes={{ lang: lang }}>
                    <title>{title}</title>

                    <meta name="description" content={description} />

                    {/*Alternate*/}
                    <link rel="alternate" hreflang="es" href={`${esUrl}`} />
                    <link rel="alternate" hreflang="en" href={`${enUrl}`} />
                    {/*Facebook*/}
                    <meta property="og:site_name" content="Noritex" />
                    <meta
                        property="og:url"
                        content={`https://www.noritex.com${location.pathname}`}
                    />
                    <meta property="og:title" content={title} />
                    <meta property="og:type" content="website" />
                    <meta property="og:image" content={image} />
                    <meta property="og:description" content={description} />
                    {/*Twitter*/}
                    <meta name="twitter:title" content={title} />
                    <meta name="twitter:description" content={description} />
                </Helmet>
                {/* <ReactTooltip id="help-text" /> */}
                <ColectionView2
                    data={data}
                    slug={slug}
                    _t={this._t}
                    user={user}
                    isSearch={true}
                    isLoggedIn={this.isLoggedIn()}
                    products={search}
                    retrieveProducts={this.retrieveProducts}
                    changeQueries={this.changeQueries}
                    ambientProducts={ambientProducts}
                    itemToItem={itemToItem}
                    selectedTags={globalTags}
                    prices={search ? search.prices : null}
                    availableFilter={search ? search.available : null}
                    getRecomendations={this.getRecomendations}
                    getCompleteTheLook={this.getCompleteTheLook}
                    searchQuery={location.search}
                    location={location}
                    addToCart={this.addToCart}
                    isRetrivingProducts={isRetrivingProducts}
                    viewItem={this.viewItem}
                    customTracker={this.customTracker}
                    lastKnownProduct={lastKnownProduct}
                    removeItem={this.removeItem}
                    setInitialTags={this.setInitialTags}
                    asociatedProducts={asociatedProducts}
                />
                {renderIf(!this.isLoggedIn())(
                    <Banner
                        config={[
                            {
                                title: this._t("readyToBuyProductsOnline"),
                                cta: {
                                    title: this._t("registerToBuy"),
                                    link: this.registerLink(),
                                },
                                list: [
                                    { p: this._t("unique1") },
                                    { p: this._t("unique3") },
                                    { p: this._t("unique2") },
                                ],
                            },
                        ]}
                    />
                )}
            </>
        );
    }
}

export default withTracker()(Collection);
