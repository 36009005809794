import React from "react";
import Recomendations from "../../components/Recomendations";
import CustomFields from "../../components/CustomFields";
import renderIf from "../../utils/renderIf";
import { Preloader } from "../../sdk/Elements";
import { Heading } from "../../sdk/Layout";
import { PreloaderContainer } from "../styles";

function HomePage({
    location,
    _t,
    recomendations,
    addToCart,
    viewItem,
    gotoThankYouPage,
    toggleModalProduct,
    childCollection,
    data,
    isLoadingPage,
}) {
    const [isInitialize, setstate] = React.useState(false);

    React.useEffect(() => {
        setTimeout(() => {
            setstate(true);
        }, 300);
    }, []);

    return (
        <>
            <CustomFields
                top
                customFields={data.customFields}
                _t={_t}
                recomendations={recomendations}
                location={location}
                childCollection={childCollection}
                gotoThankYouPage={gotoThankYouPage}
                viewItem={viewItem}
                toggleModalProduct={toggleModalProduct}
            />
            <CustomFields
                customFields={data.customFields}
                _t={_t}
                recomendations={recomendations}
                location={location}
                childCollection={childCollection}
                gotoThankYouPage={gotoThankYouPage}
                viewItem={viewItem}
                toggleModalProduct={toggleModalProduct}
            />
            {renderIf(data && data.customFields && isInitialize)(
                <Recomendations
                    toggleModalProduct={toggleModalProduct}
                    addToCart={addToCart}
                    viewItem={viewItem}
                    _t={_t}
                />
            )}
        </>
    );
}

export default HomePage;
