import { Fragment } from "react";
import styled, { keyframes, css } from "styled-components";
import WishlistButton from "../WishlistButton";
import { Control, Field, SelectBox, Checkbox, Radio } from "../../sdk/Form";
import { Columns, Column, Title } from "../../sdk/Layout";
import { Button, Buttons, Icon } from "../../sdk/Button";
import { Dropdown } from "../../sdk/Elements";
import { AnimateFadeIn } from "../../sdk/Animations";

export const Qty = styled(Field)`
    margin-bottom: 0 !important;
    input {
        text-align: center;
        z-index: 2;
    }
    svg {
        pointer-events: none;
    }
`;

// export const Qty = styled.div`
//     flex: none;
// `;

export const Btn = styled.div`
    width: 100%;
    padding-right: 0px;
`;
export const New = styled.span`
    padding: 2px 4px;
    color: ${(props) => props.theme.btnWhiteBg};
    background-color: ${(props) => props.theme.primary};
    float: right;
    font-size: 11px;
    border-radius: 3px;
`;

export const WrapperScroll = styled.div`
    overflow: auto;
    max-height: calc(100vh - 7px);
`;

export const Add = styled.div`
    display: flex;
    padding-bottom: 20px;
    padding-top: 10px;
    @media (min-width: 769px) and (max-width: 980px) {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
        & ${Btn} {
            padding-left: 0;
            padding-right: 0;
            padding-top: 10px;
        }
    }
    @media (max-width: 480px) {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
        & ${Btn} {
            padding-right: 0;
            padding-left: 0;
            padding-top: 10px;
        }
    }
`;

export const WishlistButtonExtended = styled(WishlistButton)`
    padding-left: 10px;
    @media (max-width: 480px) {
        width: 100%;
        padding-top: 10px;
        padding-left: 0px;
        button {
            width: 100%;
        }
    }
    @media (min-width: 769px) and (max-width: 980px) {
        width: 100%;
        padding-top: 10px;
        padding-left: 0px;
        button {
            width: 100%;
        }
    }
`;

export const BottomBar = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 100;
    border-top: 1px solid #e3e3e3;
    box-shadow: 0 -1px 13px -5px rgba(0, 0, 0, 0.29);
    text-align: center;
    display: none;
    align-items: center;
    justify-content: center;
    padding: 5px 20px;
    z-index: 999;
    --safe-area-inset-bottom: env(safe-area-inset-bottom);

    padding-bottom: calc(var(--safe-area-inset-bottom) + 5px);
    @media (max-width: 980px) ${(props) =>
            !props.theme.isStandalone ? "and (display-mode: standalone)" : ""} {
        display: flex;
    }
    ${Button} {
        border: transparent;
        border-radius: 100px;
        min-width: 45px;
        height: 45px;
        svg {
            width: 20px;
            height: 20px;
            color: black;
        }
        &:not(:last-child):not(:only-child) {
            margin-right: 10px;
        }
    }
`;

export const RoundedButton = styled.a`
    background: #112c55;
    border-radius: 100px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    color: white;
    text-decoration: none;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 19px;
    border: none;
`;

export const Rows = styled.div`
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
`;
export const Row = styled.div`
    & + & {
        margin-top: 20px;
        padding-top: 20px;
        border-top: solid 1px #e3e3e3;
    }
`;

export const PopupOptions = styled.div`
    position: fixed;
    z-index: 999;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;

    > span {
        background-color: black;
        opacity: 0.3;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    > div {
        background-color: #fff;
        padding: 10px;
        z-index: 2;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 80%;
        border-radius: 30px 30px 0 0;
        padding-bottom: 55px;
        padding-top: 30px;

        --safe-area-inset-bottom: env(safe-area-inset-bottom);

        padding-bottom: calc(var(--safe-area-inset-bottom) + 55px);

        @-webkit-keyframes slideInUp {
            from {
                -webkit-transform: translate3d(0, 100%, 0);
                transform: translate3d(0, 100%, 0);
                visibility: visible;
            }

            to {
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
            }
        }

        @keyframes slideInUp {
            from {
                -webkit-transform: translate3d(0, 100%, 0);
                transform: translate3d(0, 100%, 0);
                visibility: visible;
            }

            to {
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
            }
        }
        -webkit-animation-duration: 0.4s;
        animation-duration: 0.4s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-name: slideInUp;
        animation-name: slideInUp;
    }
    ${Columns} {
        display: flex;
        align-items: center;
        margin: 0;
    }
    ${Column} {
        padding: 0;
    }
    h1 {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
        padding-right: 35px;
        line-height: 17px;
    }
    span {
        font-size: 10px;
        & + span:before {
            margin: 0 5px;
        }
    }
`;

export const PopupCloser = styled.div`
    position: absolute;
    right: 15px;
    top: 10px;
    svg {
        width: 22px;
        height: 22px;
    }
`;

export const PopupOptionsFooter = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 5px 20px;
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    padding-bottom: calc(var(--safe-area-inset-bottom) + 5px);
    ${Button} {
        height: 45px;
        border-radius: 100px;
    }
`;

export const Thumbnail = styled.figure`
    padding-right: 10px;
    img {
        width: 100px;
        height: 100px;
        object-fit: contain;
    }
`;

export const Price = styled.div`
    font-size: 16px;
    margin: 10px 0;
    span {
        font-size: 16px;
    }
`;

export const QtyPopup = styled.div`
    display: flex;
    align-items: center;
    span {
        width: 35px;
        height: 35px;
        line-height: 31px;
        font-family: serif;
        text-align: center;
        font-size: 30px;
        background-color: #f1f1f1;
        border-radius: 100%;
        color: #8c8c8c;
    }
    input {
        border: none;
        background-color: transparent;
        width: 60px;
        text-align: center;
        font-size: 19px;
    }
`;

export const WishList = styled(Checkbox)`
    label {
        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }
`;

export const AddList = styled.div`
    display: flex;
    align-items: center;
    color: royalblue;
    margin-top: 15px;
    span {
        font-size: 18px;
        line-height: 18px;
        margin-right: 5px;
        text-transform: uppercase;
    }
`;

export const StorePrice = styled.div`
    .price {
        padding-right: 5px;
    }
    a {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        color: ${(props) => props.theme.link};
        &:hover {
            text-decoration: underline;
        }
        svg {
            width: 12px;
            margin-left: 5px;
        }
    }
`;

export const MoreInfoRow = styled.tr`
    ${AnimateFadeIn}
`;

export const LinkMoreDetails = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 10px;
    color: ${(props) => props.theme.gray};
    font-size: 14px;
    position: relative;
    cursor: pointer;

    &:after {
        content: "";
        display: block;
        width: 5px;
        height: 5px;
        border-right: solid 2px ${(props) => props.theme.gray};
        border-bottom: solid 2px ${(props) => props.theme.gray};
        margin-left: 5px;
        transform: ${(props) =>
            props.expanded ? "rotate(-135deg)" : "rotate(45deg)"};
        margin-bottom: ${(props) => props.expanded && "-5px"};
    }
    &:hover {
        text-decoration: underline;
    }
`;

export const DropdownExtended = styled(Dropdown)`
    width: 100%;
    > div {
        width: 100%;
        > div {
            padding: 10px;
            li {
                padding: 0 1rem;
            }
        }
    }
`;

export const ButtonExtended = styled(Button)`
    justify-content: space-between;
    padding: 0;
    .label {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .icon-down {
        border-left: solid 1px rgba(255, 255, 255, 0.2);
        padding: 7px 10px;
        flex: none;
        svg {
            margin: 0;
            margin-bottom: -2px;
        }
    }
    &.in-cart {
        .icon-down {
            border-left-color: ${(props) => props.theme.btnWhiteBorder};
        }
    }
`;

export const RadioExtended = styled(Radio)`
    display: block;
    ${(props) =>
        props.disabled
            ? `
        opacity: 0.4;
        text-decoration: line-through;
        cursor: not-allowed;
    
    `
            : ""};

    input:checked {
        background: red;
        + p {
            color: ${(props) => props.theme.primaryDark} !important;
            & + span {
                background-color: ${(props) =>
                    props.isLoading
                        ? props.theme.white
                        : props.theme.primaryDarkMode} !important;
                border-color: ${(props) => props.theme.primaryDarkMode};
                box-shadow: inset 0 0 0px 2px ${(props) => props.theme.white};
            }
        }
    }
    label {
        border: none;
        padding: 0;
        justify-content: space-between;
    }
    p {
        padding-left: 0;
    }
`;
