import { keyframes, css } from "styled-components";

const FadeIn = keyframes`
    0% {opacity: 0;}
    100% {opacity: 1;}
`;

const FadeInDown = keyframes`
    0% {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
`;

export const AnimateFadeIn = css`
    animation-duration: 1s;
    animation-name: ${FadeIn};
`;

export const AnimateFadeInDown = css`
    animation-duration: 1s;
    animation-name: ${FadeInDown};
`;