import React from "react";
import loadable from "@loadable/component";
import retry from "../../componentLoader";
import Header from "../components/Header/index";
import Footer from "../components/Footer2";
import BackToTop from "../components/BackToTop";
import { lang } from "../../lang/config";
import Blueberry from "../../blueberry/index";
import { LangContext } from "../context";
// import Translate from "../../lang/"

const Popup = loadable(() => retry(() => import("../components/Popup")));
const RequireLogin = loadable(() =>
    retry(() => import("../components/RequireLogin"))
);
const Offline = loadable(() =>
    retry(() => import(/* webpackPrefetch: true */ "../components/Offline"))
);

class MainTemplate extends React.Component {
    constructor(props) {
        super(props);
        if (this.renderBody) this.renderBody = this.renderBody.bind(this);

        let { lang } = props.match.params;
        Blueberry.setLang(lang);
    }

    setWindowTop() {
        if (typeof window !== "undefined") window.scrollTo(0, 0);
    }

    searchProducts = (text) => {
        if (window.searchTimeout != null) {
            clearTimeout(window.searchTimeout);
        }
        window.searchTimeout = setTimeout(() => {
            Blueberry.searchSugestion({
                text,
                tags: this.props.store.user.tags,
            })
                .then(() => {})
                .catch((err) => {
                    console.log(err);
                });
            if (analytics)
                analytics.track("Products Sugestions", {
                    query: text,
                });
        }, 300);
    };

    goToSearch = (query) => {
        this.props.history.push(`/${this.getBasePath()}/search/?qry=${query}`);
    };

    getCart(text) {
        Blueberry.getCart();
    }

    _t = (expresion) => {
        return lang(expresion, this.props.match.params.lang);
    };

    requestBrandAccess = ({ brandSlug }) => {
        return Blueberry.insertBrandRequest({ brandSlug });
    };

    viewItem = (itemId) => {
        if (analytics)
            analytics.track("Product Clicked", {
                product_id: itemId,
            });
        this.props.history.push(
            `/${this.getBasePath()}/products/${itemId.replace("/", "-sz")}/`
        );
    };

    gotoLogin = () => {
        this.props.history.push(`/${this.getBasePath()}/account/login/`);
    };

    setLanguage = (lang) => {
        const pathname = this.props.location.pathname;
        const paths = pathname.split("/");
        paths[1] = lang;
        if (analytics)
            analytics.track("setLanguage", {
                lang: lang,
            });
        window.location.replace(paths.join("/"));
    };

    removeItem = (productId, slug, cookie_token) => {
        if (analytics)
            analytics.track("Product Removed", {
                product_id: slug,
            });
        return Blueberry.removeFromCart({ productId, cookie_token });
    };

    renderPrivate = () => {
        return <RequireLogin gotoRegister={this.gotoRegister} _t={this._t} />;
    };

    getFooterConfig(lang, isLoggedIn, menus) {
        if (menus.length < 3) return;
        return menus[2].menu;
    }

    leadSet() {
        if (analytics) analytics.track("Lead Clicked");
    }

    stripLangDir(lang, pathname) {
        var url = `https://www.noritex.com${pathname}`;
        var RE = new RegExp("/" + lang + "/");
        return url.replace(RE, "/");
    }

    addLangDir(lang, pathname, baseUrl) {
        var url = `${baseUrl}`;
        var RE = new RegExp("(\\.com/)");
        return url.replace(RE, "$1" + lang + "/");
    }

    getBasePath = () => {
        const { lang, brandSlug } = this.props.match.params;
        return `${lang}${brandSlug ? `/${brandSlug}` : ""}`;
    };

    // pushLink = (to) => {
    //     const { lang } = this.props.match.params;
    //     this.props.history.push(
    //         `/${lang}/${to}`
    //     );
    // }

    registerLink = (cookie_token) => {
        const { lang } = this.props.match.params;

        switch (lang) {
            case "es":
                return `/es/pages/registro-es/?${
                    typeof cookie_token === "string"
                        ? `cookie_token=${cookie_token}`
                        : ""
                }`;

            case "en":
                return `/en/pages/sign-up/?${
                    typeof cookie_token === "string"
                        ? `cookie_token=${cookie_token}`
                        : ""
                }`;

            case "pt":
                return `/pt/pages/registro-pt/?${
                    typeof cookie_token === "string"
                        ? `cookie_token=${cookie_token}`
                        : ""
                }`;
        }
    };

    gotoRegister = (cookie_token) => {
        this.props.history.push(this.registerLink(cookie_token));
    };

    isLoggedIn = () => {
        return this.props.store.user && this.props.store.user.id != null
            ? true
            : false;
    };

    isOnline = () => {
        return (
            typeof window === "undefined" ||
            (window.navigator && window.navigator.onLine)
        );
    };

    renderOffline = () => {
        return <Offline />;
    };

    renderExtraSections = () => {
        if (
            this.props.store.user &&
            this.props.store.user.notifications &&
            this.props.store.user.notifications.length > 0
        )
            return <Popup />;
    };

    renderHeader = () => {
        const { menusData, jsonSettings, location, hasBackButton } = this.props;
        const { user, stats, search, suggestions } = this.props.store;
        const { lang, brandSlug } = this.props.match.params;

        if (menusData == null) return null;
        return (
            <Header
                placeholder={this._t("searchPlaceholder")}
                _t={this._t}
                isLoggedIn={this.isLoggedIn()}
                menusData={menusData}
                requestBrandAccess={this.requestBrandAccess}
                brandSlug={brandSlug}
                // searchResults={search}
                gotoRegister={this.gotoRegister}
                registerLink={this.registerLink}
                user={user}
                lang={lang}
                stats={stats}
                suggestions={suggestions}
                searchProducts={this.searchProducts}
                goToSearch={this.goToSearch}
                viewItem={this.viewItem}
                jsonSettings={jsonSettings}
                hasBackButton={hasBackButton}
                path={location.pathname}
            />
        );
    };

    renderFooter = (footerConfig) => {
        const { lang } = this.props.match.params;
        const { themeToggler, theme } = this.props;
        return (
            <Footer
                config={footerConfig}
                registerLink={this.registerLink}
                lang={lang}
                _t={this._t}
                theme={theme}
                themeToggler={themeToggler}
                isLoggedIn={this.isLoggedIn()}
                gotoRegister={this.gotoRegister}
            />
        );
    };

    render() {
        const { privateTemplate, menusData } = this.props;
        const { user } = this.props.store;
        const { lang, brandSlug } = this.props.match.params;
        let footerConfig = this.getFooterConfig(
            lang,
            this.isLoggedIn(),
            menusData
        );

        return (
            <LangContext.Provider
                value={{
                    lang: lang ? lang : "es",
                    brandSlug,
                    userId: user ? user.id : null,
                }}
            >
                <BackToTop lang={lang} />
                <div className="main__content-wrapper">
                    {this.renderHeader()}
                    <section
                        id="main-content"
                        className="main__content site-main"
                    >
                        {!this.isOnline()
                            ? this.renderOffline()
                            : privateTemplate === true && user.id == null
                            ? this.renderPrivate()
                            : this.renderBody()}
                    </section>
                    {this.renderFooter(footerConfig)}
                </div>
                {this.renderExtraSections()}
            </LangContext.Provider>
        );
    }
}

MainTemplate.defaultProps = {
    user: {},
    cart: {},
    search: [],
    lang: "es",
    jsonSettings: {
        isInHomeFair: true,
        isInChristmasFair: true,
    },
};

export default MainTemplate;
