import styled from "styled-components";

import { Section, Title } from "../../sdk/Layout";
import { Buttons, Button } from "../../sdk/Button";
import { Item, TagExtented } from "../Product2";

export const Scrollable = styled.div`
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    > div {
        white-space: nowrap;
        padding-left: 0;
        padding-right: 100px;
    }
    @media (max-width: 768px) {
        > div {
            padding-right: 50px;
        }
    }
    ${Item} {
        border-radius: 10px;
        opacity: 0.9;
        transition: opacity 200ms linear;
        overflow: hidden;
        &:hover {
            opacity: 1;
        }
        @media (max-width: 768px) {
            width: 24% !important;
            display: inline-block;
            padding: 10px !important;
            margin-bottom: 10px;
            border: solid 1px #e3e3e3;
            ${TagExtented} {
                height: 25px;
                line-height: 25px;
                padding: 0 10px;
                bottom: 120px !important;
                top: inherit !important;
                font-size: 12px;
            }
            > div {
                padding-top: 20px !important;
                padding-left: 0;
                width: 100%;
            }

            ${Buttons} {
                position: absolute;
                top: 0;
                margin-top: -20px;
                left: 0;
                width: 100%;
                padding: 0px 10px;
                justify-content: center !important;
                right: inherit;
            }
            .info {
                display: none;
            }
        }
        @media (max-width: 620px) {
            width: 49% !important;
        }
    }
`;

export const Block2 = styled.div`
    &:not(:last-child) {
        margin-bottom: 30px;
    }
`;

export const Loader = styled.div`
    background-color: #141414;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    &:before,
    &:after {
        content: "";
        display: block;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 50%;
        background: linear-gradient(
            180deg,
            rgba(20, 20, 20, 1) 89%,
            rgba(20, 20, 20, 0.2595238779105392) 100%
        );
    }
    &:after {
        top: initial;
        bottom: 0;
        height: calc(50% - 35px * 5);
        background: linear-gradient(
            0deg,
            rgba(20, 20, 20, 1) 89%,
            rgba(20, 20, 20, 0.2595238779105392) 100%
        );
    }
    > div {
        transition: all 200ms ease-out;
        position: absolute;
        left: calc(50% - 200px);
        ${(props) =>
            `top: calc(50% - ${
                props.length > 1 ? props.length - 1 : "0"
            } * 35px);`}
        @media (max-width: 480px) {
            left: 20px;
        }
    }
    ul {
        li {
            display: flex;
            align-items: center;
            color: white;
            opacity: 0.5;
            &:not(:last-child) {
                margin-bottom: 15px;
            }
            .icon {
                margin-right: 7px;
                width: 20px;
                height: 20px;
            }
            .spin {
                display: inline-block;
                width: 20px;
                height: 20px;
                @keyframes spin {
                    from {
                        transform: rotate(0deg);
                    }
                    to {
                        transform: rotate(360deg);
                    }
                }
                animation-name: spin;
                animation-duration: 3000ms;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
            }
            svg {
                width: 20px;
            }
        }
    }
`;

export const TitleExtended = styled(Title)`
    color: ${(props) => (props.light ? "lightgray" : "textDefault")};
    text-transform: capitalize;
    span {
        font-weight: normal;
        font-size: 17px;
    }
    @media (max-width: 768px) {
        font-size: 16px;
        span {
            font-size: 14px;
            padding-top: 5px;
            display: block;
        }
    }
    @media (max-width: 480px) {
        font-size: 3.333vw;
        span {
            font-size: 2.917vw;
        }
    }
`;
