import React from "react";

export default function HeartIcon() {
    return (
        <svg
            width="23px"
            height="21px"
            viewBox="0 0 23 21"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <g
                    id="current_bar"
                    transform="translate(-668.000000, -209.000000)"
                    fill="#000000"
                    fillRule="nonzero"
                >
                    <g
                        id="Group-8-Copy-2"
                        transform="translate(563.000000, 190.000000)"
                    >
                        <g id="Group">
                            <g
                                id="Stacked-Group"
                                transform="translate(34.000000, 18.000000)"
                            >
                                <g
                                    id="heart-fill"
                                    transform="translate(71.000000, 0.000000)"
                                >
                                    <path d="M20.7268276,2.37337699 C18.2699814,0.279677746 14.6160951,0.656274612 12.3609972,2.98310525 L11.4777879,3.89321434 L10.5945786,2.98310525 C8.34396396,0.656274612 4.6855944,0.279677746 2.22874818,2.37337699 C-0.586761728,4.77642366 -0.734710497,9.08935444 1.78490187,11.6941494 L10.4600797,20.6517749 C11.0204917,21.2301201 11.9306008,21.2301201 12.4910128,20.6517749 L21.1661906,11.6941494 C23.6902863,9.08935444 23.5423375,4.77642366 20.7268276,2.37337699 Z" />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
