import React from "react";
import Blueberry from "../../blueberry";
import { BlueberryData } from "../../blueberry/BlueberryData";
import { config } from "../../config";
import { LangContext } from "../context";

export default function useBrand() {
    const refUser = React.useRef();

    const [isLoadingBrand, setIsLoadingBrand] = React.useState(false);
    const context = React.useContext(LangContext);
    const [brand, setBrand] = React.useState(
        BlueberryData.brand[context.brandSlug]
    );

    const getBrand = () => {
        setIsLoadingBrand(true);

        Blueberry.brand({
            slug: context.brandSlug,
            includeMenus: true,
            includeBrandAccess: true,
        })
            .then((resp) => {
                setIsLoadingBrand(false);
                setBrand(resp);
            })
            .catch(() => {
                setIsLoadingBrand(false);
            });
    };
    const getMainMenu = () => {
        setIsLoadingBrand(true);

        Blueberry.menu(
            context.lang === "en"
                ? config.MAIN_MENU_SLUG_LOGIN_ENG
                : context.lang === "pt"
                ? config.MAIN_MENU_SLUG_LOGIN_PT
                : config.MAIN_MENU_SLUG_LOGIN,
            true
        )
            .then((resp) => {
                window._INITIAL_DATA_[window._INITIAL_DATA_.length - 1][1] =
                    resp;
            })
            .catch((err) => {
                console.log(err);
            });
    };

    React.useMemo(() => {
        const userId = context.userId;
        if (
            context.brandSlug &&
            (BlueberryData.brand[context.brandSlug] == null ||
                refUser.current != userId)
        ) {
            refUser.current = userId;
            getBrand();
        } else if (
            context.brandSlug &&
            BlueberryData.brand[context.brandSlug]
        ) {
            setBrand(BlueberryData.brand[context.brandSlug]);
        } else if (
            context.brandSlug == null &&
            refUser.current != userId &&
            userId
        ) {
            refUser.current = userId;

            getMainMenu();
        }
    }, [context.brandSlug, context.userId]);

    return [brand, isLoadingBrand, getBrand];
}
