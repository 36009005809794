import { useContext } from "react";
import { lang } from "../config_tags";
import { LangContext } from "../../js/context";

function useTranslationTags() {
    // const [text, setText] = useState(null);
    const context = useContext(LangContext);

    const _tags = (expression) => {
        return lang(expression, context.lang);
    };

    // useEffect(() => {}, [context.lang]);

    return [_tags];
}

export default useTranslationTags;
