import React from "react";

export default function BackIcon({ white }) {
    return (
        <svg
            width="20px"
            height="20px"
            viewBox="0 0 20 20"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <g
                    id="home-copy-8"
                    transform="translate(-15.000000, -54.000000)"
                    fill={white ? "#fff" : "#000000"}
                    fillRule="nonzero"
                >
                    <g id="header">
                        <g
                            id="arrow-left"
                            transform="translate(15.000000, 54.000000)"
                        >
                            <path d="M10.3364178,19.8423952 L10.6520878,19.5254258 C10.8612844,19.3153383 10.8612844,18.9746971 10.6520878,18.7645648 L2.68359675,10.7621415 L19.4642851,10.7621415 C19.7601337,10.7621415 20,10.5212538 20,10.2241454 L20,9.77581534 C20,9.47870698 19.7601337,9.23781922 19.4642851,9.23781922 L2.68359675,9.23781922 L10.6520878,1.235396 C10.8612844,1.02530851 10.8612844,0.684667307 10.6520878,0.474534991 L10.3364178,0.157565612 C10.1272211,-0.0525218708 9.78802432,-0.0525218708 9.57882765,0.157565612 L0.156897497,9.6195723 C-0.0522991655,9.82965978 -0.0522991655,10.170301 0.156897497,10.3804333 L9.57882765,19.84244 C9.78802432,20.0525275 10.1272211,20.0525275 10.3364178,19.8423952 Z" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
