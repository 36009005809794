import React from "react";
import styled from "styled-components";

import Icons2 from "./Icons2";

const HelpTooltipComp = styled.span`
    display: inline-block;
    margin-left: 5px;
    margin-right: ${(props) => (props.right ? "5px" : "0px")};
    position: relative;
    svg {
        width: 13px;
        height: 13px;
        cursor: pointer;
        color: ${(props) => props.theme.gray};
        &:hover {
            color: ${(props) => props.theme.primaryDark};
        }
    }
    > div {
        position: absolute;
        top: 100%;
        left: 50%;
        margin-top: 5px;
        transform: translateX(-50%);
        background-color: ${(props) => props.theme.primaryDark};
        color: white;
        padding: 10px;
        font-size: 10px;
        z-index: 99;
        border-radius: 5px;
        font-weight: normal;
        width: 100px;
        line-height: 12px;
        display: none;
        &:before {
            bottom: 100%;
            left: 50%;
            border: solid transparent;
            content: "";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-bottom-color: ${(props) => props.theme.primaryDark};
            border-width: 5px;
            margin-left: -5px;
        }
    }
    &:hover {
        > div {
            display: block;
        }
    }
`;

export default function HelpTooltip({ text, right }) {
    return (
        <HelpTooltipComp right={right}>
            <Icons2 icon="question-circle" />
            <div>{text}</div>
        </HelpTooltipComp>
    );
}
