import { useState, useEffect } from "react";
import { BlueberryData } from "../BlueberryData";

function useInCart(productId) {
    const [inCart, setInCart] = useState(null);

    useEffect(() => {
        setInCart(
            BlueberryData.cartMap &&
                BlueberryData.cartMap.hasOwnProperty(productId)
                ? BlueberryData.cartMap[productId]
                : null
        );
    }, [BlueberryData.cartMap, productId]);
    return inCart;
}

export default useInCart;
