import React from "react";
import styled from "styled-components";
import Icon2 from "../components/Icons2";
import Blueberry from "../../blueberry/index";
import MasterHead from "./components/MasterHead";
import AmbientBlock from "../components/AmbientBlock";
import CollectionProducts from "./components/CollectionProducts";
import CustomFields from "../components/CustomFields";
// import BrandHeader from "./components/BrandHeader";

import { Button } from "../sdk/Button";
import {
    getParameterByName,
    getPredefinedOrder,
    getParameterByNameNoReplace,
} from "../utils";

const MasterHeadHidden = styled.div`
    width: 100%;
    padding: 10px 0;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
    svg {
        height: 20px;
        width: 21px;
        color: black;
        fill: black;
        display: block;
        margin: auto;
    }
`;

const ActionButtonsWrapper = styled.div`
    padding: 10px;
    margin-left: -5px;
    margin-right: -5px;
    text-align: center;
`;

const ActionButton = styled(Button)`
    margin: 5px;
    text-transform: capitalize;
    display: inline-block;
    svg {
        margin-right: 10px;
        position: relative;
        top: 1px;
        width: 13px;
        height: 13px;
        fill: white;
    }
`;

const getIntialTags = (tags) => {
    let userTags = tags.filter(
        (index) =>
            index.indexOf("pricelevel") <= -1 &&
            index.indexOf("lang") <= -1 &&
            index.indexOf("directos") <= -1 &&
            index !== ""
    );

    return userTags;
};

function CollectionView({
    data,
    slug,
    childCollection,
    _t,
    retrieveProducts,
    isRetrivingProducts,
    getRecomendations,
    getCompleteTheLook,
    searchQuery,
    products,
    addToCart,
    removeItem,
    viewItem,
    itemToItem,

    changeQueries,
    defaultTags,
    selectedTags,
    lastKnownProduct,
    ambientProducts,
    isSearch,
    user,
    location,
    prices,
    availableFilter,
    asociatedProducts,
    jsonSettings,
    isLoggedIn,
    customTracker,
}) {
    const requestAmbients = () => {};
    const [page, setPage] = React.useState(1);
    const [userTags, setUserTags] = React.useState(user ? user.tags : []);
    const [acceptableUserTags, setAcceptableUserTags] = React.useState(
        getIntialTags(user && user.tags ? user.tags : [])
    );
    const [hideMasterHead, setHideMasterHead] = React.useState(false);
    const [limit, setLimit] = React.useState(() => {
        const defaultLimit =
            typeof window !== "undefined"
                ? window.localStorage.getItem("limit")
                : null;

        if (defaultLimit != null) return parseInt(defaultLimit);
        return 35;
    });
    const [order, setOrder] = React.useState(() => {
        return getPredefinedOrder(isSearch);
    });

    let configAmbient = [
        {
            title: _t("ambientes"),
            paragraph: _t("ambientesHowTo"),
            slug,
        },
    ];

    const getSelectedTags = React.useCallback(() => {
        let facet = getParameterByNameNoReplace("facet", searchQuery);

        return selectedTags && !isSearch
            ? selectedTags.split("+")
            : isSearch
            ? facet
                ? facet.split("+")
                : []
            : [];
    }, [searchQuery, selectedTags, isSearch]);

    const handleLimitChange = React.useCallback(
        (newLimit) => {
            window.localStorage.setItem("limit", newLimit);
            let qry = getParameterByName("qry", searchQuery);
            changeQueries(
                slug ? slug : qry,
                1,
                newLimit,
                getSelectedTags(),
                order,
                userTags
            );
        },
        [searchQuery, slug, order, userTags, getSelectedTags, changeQueries]
    );

    const handleOrderChange = React.useCallback(
        (newOrder) => {
            window.localStorage.setItem("order", newOrder);
            let qry = getParameterByName("qry", searchQuery);
            changeQueries(
                slug ? slug : qry,
                1,
                limit,
                getSelectedTags(),
                newOrder,
                userTags
            );
        },
        [searchQuery, slug, limit, userTags, getSelectedTags, changeQueries]
    );

    const handlePageChange = React.useCallback(
        (newPage) => {
            setHideMasterHead(true);
            let qry = getParameterByName("qry", searchQuery);

            changeQueries(
                slug ? slug : qry,
                newPage,
                limit,
                getSelectedTags(),
                order,
                userTags
            );
        },
        [searchQuery, slug, limit, getSelectedTags, order, userTags]
    );

    const handleTagSelected = React.useCallback(
        (tag) => {
            let qry = getParameterByName("qry", searchQuery);

            changeQueries(slug ? slug : qry, 1, limit, tag, order, userTags);
        },
        [searchQuery, limit, order, userTags, slug]
    );

    const openMasterHead = () => {
        setHideMasterHead(false);
    };

    const handleRemoveTag = (e) => {
        let qry = getParameterByName("qry", searchQuery);
        const tag = e.currentTarget.getAttribute("data-tag");
        let tags = [...userTags];
        tags = tags.filter((index) => index !== tag);
        setUserTags(tags);
        changeQueries(
            slug ? slug : qry,
            1,
            limit,
            getSelectedTags(),
            order,
            tags
        );
    };

    const handleAddTag = (e) => {
        let qry = getParameterByName("qry", searchQuery);
        const tag = e.currentTarget.getAttribute("data-tag");
        let tags = [...userTags];
        tags.push(tag);
        setUserTags(tags);
        changeQueries(
            slug ? slug : qry,
            1,
            limit,
            getSelectedTags(),
            order,
            tags
        );
    };

    const getInAdvancePage = React.useCallback(
        (page) => {
            if (isSearch === true) return null;
            let currentPage = parseInt(page);
            let newLimit = getParameterByName("limit", searchQuery);
            let newOrder = getParameterByName("order", searchQuery);
            let qry = getParameterByName("qry", searchQuery);
            let userTags = getParameterByName("userTags", searchQuery);

            currentPage = currentPage ? currentPage : 1;
            newLimit = newLimit ? newLimit : limit;
            userTags =
                userTags != null && userTags !== ""
                    ? userTags.split(" ")
                    : getIntialTags(user && user.tags ? user.tags : []);
            newOrder = newOrder ? newOrder : isSearch ? null : order;

            retrieveProducts(
                slug ? slug : qry,
                currentPage,
                newLimit,
                getSelectedTags(),
                newOrder,
                userTags
            );
        },
        [searchQuery, retrieveProducts, limit, user, userTags, isSearch, order]
    );

    const refreshCurrentPage = React.useCallback(() => {
        getInAdvancePage(page);
    }, [page]);

    React.useEffect(() => {
        if (slug) Blueberry.collectionChildren({ slug }).catch(() => {});
        configAmbient = [
            {
                title: "Some Title",
                paragraph: "Some Paragraph",
                slug,
            },
        ];
        let currentPage = parseInt(getParameterByName("page", searchQuery));
        let newLimit = getParameterByName("limit", searchQuery);
        let newOrder = getParameterByName("order", searchQuery);
        let qry = getParameterByName("qry", searchQuery);
        let userTags = getParameterByName("userTags", searchQuery);

        currentPage = currentPage ? currentPage : 1;
        newLimit = newLimit ? parseInt(newLimit) : limit;
        userTags =
            userTags != null && userTags !== ""
                ? userTags.split(" ")
                : getIntialTags(user && user.tags ? user.tags : []);
        newOrder = newOrder ? newOrder : isSearch ? "" : order;

        setLimit(newLimit);
        setOrder(newOrder);
        setPage(currentPage);
        setUserTags(userTags);

        retrieveProducts(
            slug ? slug : qry,
            currentPage,
            newLimit,
            getSelectedTags(),
            newOrder,
            userTags
        );
    }, [slug, searchQuery, selectedTags, acceptableUserTags, defaultTags]);

    React.useEffect(() => {
        const initialTags = getIntialTags(user && user.tags ? user.tags : []);
        // changeQueries(
        //     slug ? slug : qry,
        //     1,
        //     limit,
        //     getSelectedTags(),
        //     order,
        //     initialTags
        // );
        setUserTags((prev) => {
            if (prev == null || prev.length === 0) return initialTags;
            return prev;
        });
        if (initialTags.length != acceptableUserTags.length) {
            setAcceptableUserTags(initialTags);
        }
    }, [user]);

    // React.useEffect(() => {
        
    //     setHideMasterHead(true);
    // }, [selectedTags]);

    React.useEffect(() => {
        setHideMasterHead(!!selectedTags);
    }, [slug, selectedTags]);

    const collectionProducts =
        products && !isSearch ? products[page - 1] : isSearch ? products : {};

    const tags = collectionProducts ? collectionProducts.tags : [];

    const tagsNotIndex =
        acceptableUserTags && userTags
            ? acceptableUserTags.filter(
                  (index) => userTags.indexOf(index) <= -1
              )
            : [];

    return (
        <>
            {hideMasterHead ? (
                <MasterHeadHidden onClick={openMasterHead}>
                    <Icon2 />
                </MasterHeadHidden>
            ) : (
                <>
                    <MasterHead
                        images={data ? data.images : null}
                        content={data ? data.content : null}
                        title={data ? data.title : null}
                        _t={_t}
                        isLoggedIn={isLoggedIn}
                        imageSetAutomatically={
                            data
                                ? data.imageSetAutomatically || data.hideImage
                                : true
                        }
                        audio={
                            data &&
                            data.customFields &&
                            data.customFields.audio &&
                            data.customFields.audio.img.length > 0
                                ? data.customFields.audio
                                : null
                        }
                    />
                    <CustomFields
                        customFields={data ? data.customFields : {}}
                        _t={_t}
                        defaultTags={
                            defaultTags ? defaultTags.replace("+", ",") : ""
                        }
                        location={location}
                    />
                    <AmbientBlock
                        childCollection={childCollection}
                        config={configAmbient}
                        pathname={location.pathname}
                        limit={6}
                        title={_t("allAmbients")}
                    />
                </>
            )}
            <CollectionProducts
                products={collectionProducts}
                _t={_t}
                limit={limit}
                page={page}
                order={order}
                prices={prices}
                isDirectos={
                    user && user.tags && user.tags.indexOf("directos") > -1
                }
                availableFilter={availableFilter}
                asociatedProducts={asociatedProducts}
                getInAdvancePage={getInAdvancePage}
                addToCart={addToCart}
                isSearch={isSearch}
                removeItem={removeItem}
                viewItem={viewItem}
                selectedTags={getSelectedTags()}
                slug={slug}
                refetch={refreshCurrentPage}
                pathname={location.pathname}
                isRetrivingProducts={isRetrivingProducts}
                itemToItem={itemToItem}
                tags={tags}
                ambientProducts={ambientProducts}
                handleTagSelected={handleTagSelected}
                handleOrderChange={handleOrderChange}
                handlePageChange={handlePageChange}
                handleLimitChange={handleLimitChange}
                getRecomendations={getRecomendations}
                getCompleteTheLook={getCompleteTheLook}
                lastKnownProduct={lastKnownProduct}
                jsonSettings={jsonSettings}
                customTracker={customTracker}
            />
        </>
    );
}

export default CollectionView;
