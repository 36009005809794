import { useState, useEffect } from "react";
import { BlueberryData } from "../BlueberryData";

function useCart() {
    const [cart, setCart] = useState([]);

    useEffect(() => {
        setCart(BlueberryData.cart);
    }, [BlueberryData.cart]);
    return cart;
}

export default useCart;
