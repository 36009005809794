import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router";
import { config } from "../../config";
import { useTranslation } from "../../lang";

export const websiteName = config.websiteName;

export function getDefaultUrl() {
	return `${
		config.IS_NORITEX
			? "https://www.noritex.com"
			: "https://www.mayorea.com"
	}`;
}

function stripLangDir(lang, pathname) {
	var url = `${getDefaultUrl()}${pathname}`;
	var RE = new RegExp("/" + lang + "/");
	return url.replace(RE, "/");
}

function addLangDir(lang, pathname, baseUrl) {
	var url = `${baseUrl}`;
	var RE = new RegExp("(\\.com/)");
	return url.replace(RE, "$1" + lang + "/");
}

const SEOFieldsHelmet = ({
	seo,
	defaultTitle,
	defaultDescription,

	bodyClassName,
}) => {
	const location = useLocation();
	const [_, lang] = useTranslation();
	const { pathname } = location;

	const baseUrl = stripLangDir(lang, pathname);

	const enUrl = addLangDir("en", pathname, baseUrl);
	const esUrl = addLangDir("es", pathname, baseUrl);
	const ptUrl = addLangDir("pt", pathname, baseUrl);

	const title = seo && seo.title != "" ? seo.title : defaultTitle;
	const description =
		seo && seo.description != "" ? seo.description : defaultDescription;
	const image =
		seo && seo.images && seo.images.length > 0 != ""
			? seo.images[0]
			: "https://www.noritex.com/uplds/2022/4/13/N4PhhtiTWoZgSqPiaNoritex_-_Venta_de_Productos_de_Decoracion,_Hogar,_Navidad_y_Escolar_al_por_mayor_png.png";

	return (
		<Helmet
			htmlAttributes={{ lang }}
			// bodyAttributes={{ class: bodyClassName }}
		>
			<title>{title}</title>
			<body className={bodyClassName ? bodyClassName : ""} />
			<meta name="description" content={description} />
			<script type="application/ld+json">
				{seo && seo.structured ? structured : null}
			</script>
			<meta http-equiv="content-language" content={lang} />
			{/*Alternate*/}
			<link rel="alternate" hreflang="es" href={`${esUrl}`} />
			<link rel="alternate" hreflang="en" href={`${enUrl}`} />
			<link rel="alternate" hreflang="pt" href={`${ptUrl}`} />
			<meta
				property="og:url"
				content={`${getDefaultUrl()}/${pathname}`}
			/>
			<meta property="og:site_name" content={websiteName} />

			<meta property="og:title" content={title} />
			<meta property="og:type" content="website" />
			<meta property="og:image" content={image} />
			<meta property="og:description" content={description} />
			{/*Twitter*/}
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			{seo && seo.isNoIndex ? (
				<meta name="robots" content="noindex,nofollow" />
			) : null}
			{seo && seo.isNoIndex ? (
				<meta name="googlebot" content="noindex, nofollow" />
			) : null}
		</Helmet>
	);
};

export default React.memo(SEOFieldsHelmet);
