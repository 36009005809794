import React from "react";

export default function UserIcon() {
    return (
        <svg
            width="23px"
            height="23px"
            viewBox="0 0 23 23"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                    id="current_bar"
                    transform="translate(-881.000000, -138.000000)"
                    fill="#000000"
                    fillRule="nonzero"
                >
                    <g
                        id="Group-8-Copy"
                        transform="translate(563.000000, 120.000000)"
                    >
                        <g id="Group">
                            <g
                                id="Stacked-Group"
                                transform="translate(34.000000, 18.000000)"
                            >
                                <g
                                    id="user"
                                    transform="translate(284.000000, 0.000000)"
                                >
                                    <path d="M11.5,1.4375 C14.2761719,1.4375 16.53125,3.69257812 16.53125,6.46875 C16.53125,9.24492187 14.2761719,11.5 11.5,11.5 C8.72382813,11.5 6.46875,9.24492187 6.46875,6.46875 C6.46875,3.69257812 8.72382813,1.4375 11.5,1.4375 Z M17.25,15.8125 C19.6263672,15.8125 21.5625,17.7486328 21.5625,20.125 L21.5625,21.5625 L1.4375,21.5625 L1.4375,20.125 C1.4375,17.7486328 3.37363281,15.8125 5.75,15.8125 C9.56835937,15.8125 8.77324219,16.53125 11.5,16.53125 C14.2357422,16.53125 13.4271484,15.8125 17.25,15.8125 Z M11.5,0 C7.92871094,0 5.03125,2.89746094 5.03125,6.46875 C5.03125,10.0400391 7.92871094,12.9375 11.5,12.9375 C15.0712891,12.9375 17.96875,10.0400391 17.96875,6.46875 C17.96875,2.89746094 15.0712891,0 11.5,0 Z M17.25,14.375 C13.0992187,14.375 14.0605469,15.09375 11.5,15.09375 C8.9484375,15.09375 9.89628906,14.375 5.75,14.375 C2.57402344,14.375 0,16.9490234 0,20.125 L0,21.5625 C0,22.3576172 0.642382812,23 1.4375,23 L21.5625,23 C22.3576172,23 23,22.3576172 23,21.5625 L23,20.125 C23,16.9490234 20.4259766,14.375 17.25,14.375 Z" />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
