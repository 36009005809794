import styled from "styled-components";
import { Section, Container } from "../../sdk/Layout";

export const SearchFlyDown = styled.div`
    position: absolute;
    z-index: 302;
    display: none;
    width: 100%;
    font-size: 1rem;
    background-color: ${props => props.theme.white};
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;

    box-shadow: 0 5px 7px -4px rgba(127, 127, 127, 0.157);
    border: 1px solid  ${props => props.theme.borderColor};
    border-top: 0;
    margin-top: -1px;
    top: 100%;
    left: 0;
    padding: 0px;
    @media (max-width: 980px) {
        width: 100%;
        max-width: 100%;
        position: fixed;
        left: 0;
        border-right: 0;
        border-left: 0;
        overflow: auto;
        height: calc(100vh - 100px);
        top: 106px;
    }
    &.visible {
        z-index: 200;
        display: block;
    }
    @media (max-width: 980px) ${(props) =>
            !props.theme.isStandalone ? "and (display-mode: standalone)" : ""} {
        --safe-area-inset-top: env(safe-area-inset-top, 60px);
        top: calc(var(--safe-area-inset-top) + 43px) !important;
        height: calc(100vh - 60px) !important;
        border-radius: 0 !important;
        border: none !important;
        box-shadow: none !important;
        z-index: 9999 !important;
    }
`;

export const Closer = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    svg {
        height: 15px;
        width: 15px;
        fill: ${props => props.theme.gray};
    }
`;

export const Footer = styled.div`
    display: block;
    width: 100%;
    padding: 15px;
    text-align: center;
    a {
        color: ${props => props.theme.primaryDark};
        font-size: 14px;
        text-decoration: underline;
        cursor: pointer;
        &:hover {
            color: ${props => props.theme.primaryDarkMode};
        }
    }
`;

export const Results = styled.div``;

export const Items = styled.div`
    display: inline-block;
    width: 100%;
    vertical-align: top;
`;

export const Product = styled.a`
    // display: block;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    font-size: 0;
    color: ${props => props.theme.primaryDark};
    text-decoration: none;
    background-color: ${props => props.theme.white};
    -webkit-transition: color 0.1s ease-in, background-color 0.1s ease-in;
    transition: color 0.1s ease-in, background-color 0.1s ease-in;
    cursor: pointer;
    &:hover {
        color: ${props => props.theme.primaryDarkMode};
    }
`;

export const Image = styled.figure`
    width: 5rem;
    height: 5rem;
    font-size: 16px;
    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }
`;

export const Text = styled.div`
    max-width: calc(~"100% - (4rem)");
    margin-left: 1rem;
`;

export const Title = styled.div`
    font-size: 16px;
    line-height: 1.3;
`;
export const Price = styled.div`
    font-size: 1.2375rem;
    line-height: 1.5;
    color: ${props => props.theme.primaryDarkMode};
    font-weight: bold;
`;

export const Suggestions = styled.div`
    .divisor {
        margin: 20px 0;
        display: block;
        width: 100%;
    }
    h3 {
        font-size: 12px;
        margin-bottom: 5px;
    }
    ul {
        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &.is-active {
                a {
                    text-decoration: underline;
                    background-color: rgba(0, 0, 0, 0.04);
                }
            }
            a {
                padding: 10px 10px;
                width: 100%;
                text-decoration: none;
                color: ${props => props.theme.primaryDark};
                cursor: pointer;
                font-size: 12px;
                &:hover {
                    text-decoration: underline;
                }
                &.delete {
                    color: ${props => props.theme.link};
                }
            }
        }
    }
`;
