import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Section, PageContent, Container } from "../../sdk/Layout";
import Blueberry from "../../../blueberry";
import { useTranslation } from "../../../lang";
import CustomFields from "../../components/CustomFields";

const ContainerExtended = styled(Container)`
    max-width: 800px;
`;

function getSlug(page, lang) {
    if (page === "faq") {
        switch (lang) {
            case "en":
                return "frequently-asked-questions";
            case "pt":
                return "perguntas-frequentes-faq";
            default:
                return "faq";
        }
    } else if (page === "tutorials") {
        switch (lang) {
            case "en":
                return "tutoriales-en";
            case "pt":
                return "tutoriales-pt";
            default:
                return "tutoriales-en-espanol";
        }
    }
}

function Pages({
    recomendations,
    addToCart,
    location,
    viewItem,
    gotoThankYouPage,
    toggleModalProduct,
}) {
    const [_t] = useTranslation();
    let { page, lang } = useParams();
    const [isLoading, setIsLoading] = React.useState(false);
    const [pageContent, setPageContent] = React.useState(null);
    React.useEffect(() => {
        setIsLoading(true);
        Blueberry.page({
            slug: getSlug(page, lang),
            lang,
        })
            .then((resp) => {
                setPageContent(resp);

                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [page, lang]);

    return (
        <>
            <CustomFields
                customFields={pageContent ? pageContent.customFields : {}}
                _t={_t}
                recomendations={recomendations}
                location={location}
                gotoThankYouPage={gotoThankYouPage}
                viewItem={viewItem}
                toggleModalProduct={toggleModalProduct}
            />
            <Section>
                <Container>
                    <PageContent>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: pageContent ? pageContent.content : "",
                            }}
                        />
                    </PageContent>
                </Container>
            </Section>
        </>
    );
}

export default Pages;
