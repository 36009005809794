import React from "react";
import useTranslationTags from "../../../lang/hooks/useTranslationTags";
import ProductGrid from "../ProductGrid2";
import { Block2, TitleExtended, Scrollable } from "./style";
import { Preloader } from "../../sdk/Elements";

function BlockSection({
	index,
	viewItem,
	addToCart,
	onQuickView,
	isLoading,
	light,
}) {
	const [_tags] = useTranslationTags();

	if (isLoading) {
		return (
			<Block2>
				<TitleExtended as="h2" size="5">
					<Preloader />
				</TitleExtended>
				<Scrollable>
					<ProductGrid
						viewItem={viewItem}
						addToCart={addToCart}
						isRetrivingProducts={isLoading}
						onQuickView={onQuickView}
					/>
				</Scrollable>
			</Block2>
		);
	}
	if (
		index.products == null ||
		index.products.products == null ||
		index.products.products.length === 0
	)
		return null;

	return (
		<Block2>
			<TitleExtended as="h2" size="5" light={light}>
				{/* Tu Top Categoría en la feria fue{" "} */}
				{index.tag.indexOf("_") > -1
					? _tags(index.tag.split("_")[1])
					: _tags(index.tag.toLowerCase())}{" "}
				<span>{/* - Similares productos tenemos... */}</span>
			</TitleExtended>
			<Scrollable>
				<ProductGrid
					products={index.products}
					viewItem={viewItem}
					addToCart={addToCart}
					lazyLoadAll={true}
					onQuickView={onQuickView}
				/>
			</Scrollable>
		</Block2>
	);
}

export default React.memo(BlockSection);
