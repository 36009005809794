import React from "react";
// import InternalLink from "../../../InternalLink";

function MovilLink({ onClick, title, children, index, lang }) {
    return (
        <li className={children && children.length > 0 ? "has-children" : ""}>
            <a data-index={index} href="" onClick={onClick}>
                {title}
            </a>
        </li>
    );
}

export default MovilLink;
