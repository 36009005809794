import React from "react";
import styled from "styled-components";
import Icon2 from "../Icons2";
import useTranslationTags from "../../../lang/hooks/useTranslationTags";
import { useTranslation } from "../../../lang";
import { Filters, Tag } from "../../sdk/Elements";
import { Control } from "../../sdk/Form";

const FilterMenu = styled(Filters)`
    ul {
        list-style: none;
        /* padding: 0; */
        margin: 0;
    }
    ul li label {
        max-width: 160px;
        word-break: break-word;
    }
    p {
        text-transform: capitalize;
    }
    input[type="text"] {
        max-width: 160px;
        font-size: 14px;
    }
    @media (max-width: 768px) {
        position: absolute;
        left: 0;
        top: 0;
        margin-top: 10px;
        z-index: 6;
        background: #fff;
        width: 100%;
        min-height: 100%;
        box-shadow: 0 8px 12px -9px rgba(0, 0, 0, 0.5);
    }
`;

const Head3 = styled.h3`
    margin: 0;
`;

const ClearFilter = styled.div`
    height: 40px;
    background-color: #112c55;
    color: white;
    cursor: pointer;
    align-items: center;
    span {
        color: white;
        display: inline-block;
        padding: 11px;
    }
    svg {
        border-left: 1px solid white;
        padding: 10px;
        color: white;
        height: 40px;
        width: 40px;
        float: right;
    }
`;

const TagGroup = ({
    tagKey,
    group,
    onClick,
    currentTags,
    _tags,
    isDirectos,
    searchString,
}) => {
    const [searchQuery, setSearchQuery] = React.useState("");
    const [isVisible, setIsVisible] = React.useState(false);
    const toggleModal = () => {
        setIsVisible((prevState) => !prevState);
    };

    const handleTagClicked = (e) => {
        // console.log(e.currentTag.value);
        onClick(tagKey === "descuento" ? "descuento" : `${tagKey}_${e.target.value}`);
    };
    const handleOnSearch = (e) => {
        // console.log(e.currentTag.value);
        setSearchQuery(e.target.value);
    };

    const finalGroup = group.filter(
        (index) =>
            index.toLocaleLowerCase().indexOf(searchQuery.toLocaleLowerCase()) >
            -1
    );

    if (tagKey === "puerto" && !isDirectos) return null;

    return (
        <div>
            <Head3 onClick={toggleModal}>
                {_tags(tagKey)}
                <Icon2 icon={isVisible ? "chevron-down" : "chevron-up"} />
            </Head3>
            <ul className={isVisible ? "is-visible" : ""}>
                {
                    searchString && (
                        <li>
                            <Control>
                                <input
                                    type="text"
                                    onChange={handleOnSearch}
                                    value={searchQuery}
                                    placeholder={`${searchString} ${_tags(tagKey)}`}
                                />
                            </Control>
                        </li>
                    )
                }
                {finalGroup.map((index) => {
                    if (index === "") return null;
                    
                    const tagIndex = tagKey === "descuento" ? "descuento" : `${tagKey}_${index}`;
                    return (
                        <li key={`${tagIndex}`}>
                            <label>
                                <input
                                    type="checkbox"
                                    onChange={handleTagClicked}
                                    value={index}
                                    checked={
                                        currentTags
                                            ? currentTags.indexOf(
                                                  `${tagIndex}`
                                              ) > -1
                                            : false
                                    }
                                />
                                <span></span>
                                <p>{_tags(index)}</p>
                            </label>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

function areEqual(prevProps, nextProps) {
    if (
        prevProps.group !== nextProps.group ||
        prevProps.currentTags !== nextProps.currentTags
    )
        return false;
    return true;
}

function getTagsKeys(tags) {
    const tagsKeys = Object.keys(tags);
    const cat = tagsKeys.findIndex((index) => index === "categoria")
    const sub = tagsKeys.findIndex((index) => index === "sub categoria")
    if (cat >= 0 && sub >= 0) {
        tagsKeys.splice(cat + 1, 0, "sub categoria");
        tagsKeys.splice(sub + 1, 1);
    }
    return tagsKeys;
}

const MemoimizedGroup = React.memo(TagGroup, areEqual);

function Filter({
    filterActive,
    tags,
    selectedTags,
    handleTagSelected,
    prices,
    availableFilter,
    isDirectos,
    slug,
}) {
    const [currentTags, setCurrentTags] = React.useState([]);
    const [showDiscount, setShowDiscount] = React.useState(false);
    const [_tags] = useTranslationTags();
    const [_t] = useTranslation();
    const getTagsMap = () => {
        const newTags = {};
        const newSelectedTags = selectedTags ? selectedTags : [];
        let discountTag = false;
        if (tags != null) {
            for (var i = tags.length - 1; i >= 0; i--) {
                if (tags[i].indexOf("_") > -1 && tags[i].indexOf("/") === -1) {
                    const t = tags[i].split("_");
                    if (newTags[t[0]] == null) newTags[t[0]] = [];
                    newTags[t[0]].push(t[1]);
                    if (newSelectedTags[t[0]] == null)
                        newSelectedTags[t[0]] = "";
                }
                if (["descuento", "discount"].includes(tags[i]) && slug !== "sale") {
                    discountTag = true;
                }
            }
        }
        if (newSelectedTags != null) {
            for (var i = newSelectedTags.length - 1; i >= 0; i--) {
                if (newSelectedTags[i].indexOf("_") > -1) {
                    const t = newSelectedTags[i].split("_");
                    if (newSelectedTags[t[0]] != null)
                        newSelectedTags[t[0]] = newSelectedTags[i];
                }
            }
        }

        return { newTags, newSelectedTags, discountTag };
    };

    const [tagsGroups, setTagGroups] = React.useState(() => {
        const { newTags } = getTagsMap();

        return newTags;
    });

    const setTag = (tag) => {
        let params = currentTags;
        // let params = this.state.selectedTags;
        const index = params.indexOf(tag);
        if (index > -1) {
            params.splice(index, 1);
        } else {
            if (tag.indexOf("_") && params !== "") {
                const t = tag.split("_");
                params = params.filter((index) => index.indexOf(t[0]) <= -1);
            }
            params.push(tag);
        }

        handleTagSelected(params);
    };

    const resetFilters = () => {
        handleTagSelected([]);
    };

    React.useEffect(() => {
        const { newTags, discountTag } = getTagsMap();

        setTagGroups(newTags);
        setShowDiscount(discountTag);
    }, [tags]);

    React.useEffect(() => {
        const { newTags, newSelectedTags } = getTagsMap();

        setTagGroups(newTags);
        setCurrentTags(newSelectedTags);
    }, [selectedTags]);

    const tagsKeys = getTagsKeys(tagsGroups);

    return (
        <FilterMenu className={filterActive ? "" : "is-hidden"}>
            {currentTags.length > 0 ? (
                <ClearFilter onClick={resetFilters}>
                    <span>{_t("clearFilters")}</span>
                    <Icon2 icon="close" />
                </ClearFilter>
            ) : null}
            {tagsKeys
                ? tagsKeys.map((index) => (
                      <MemoimizedGroup
                          key={`tag-group-${index}`}
                          tagKey={index}
                          currentTags={currentTags}
                          _tags={_tags}
                          searchString={_t("search")}
                          group={tagsGroups[index]}
                          onClick={setTag}
                          isDirectos={isDirectos}
                      />
                  ))
                : null}
            {prices ? (
                <MemoimizedGroup
                    tagKey={"prices"}
                    currentTags={currentTags}
                    _tags={_tags}
                    group={prices}
                    onClick={setTag}
                />
            ) : null}
            {availableFilter ? (
                <MemoimizedGroup
                    tagKey={"available"}
                    currentTags={currentTags}
                    _tags={_tags}
                    group={availableFilter}
                    onClick={setTag}
                />
            ) : null}
            {
                showDiscount ? (
                    <MemoimizedGroup
                        tagKey={"descuento"}
                        currentTags={currentTags}
                        _tags={_tags}
                        group={["descuento"]}
                        onClick={setTag}
                    />
                ) : null
            }
        </FilterMenu>
    );
}

export default React.memo(Filter);
