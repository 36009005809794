import React from "react";
import { Helmet } from "react-helmet";
import Blueberry from "../../blueberry/index";
import withTracker from "../../blueberry/connectPromise";
import ProductsSectionTemplate from "../templates/ProductsSectionTemplate";
// import loadable from '@loadable/component'
import Loader from "../components/Loader";
import Listener from "../components/Listener";
import HomeContainer from "./HomeContainer";
import Tutorial from "../components/Tutorial";
import Onboarding from "../components/Onboarding/index";
import SEOFieldsHelmet, { websiteName } from "../utils/SEOFieldsHelmet";

// const LoadableComponent = loadable(() => import('./AccountView'))

class HomeLogin extends ProductsSectionTemplate {
    state = {
        ...this.productDefaultState,
    };

    static async getInitialData(props) {
        // const token = props.initialToken ? props.initialToken : (props.token ? props.token.token : "")
        let { lang } = props.match.params;

        const slugF = "home-login";

        return new Promise((resolve, reject) => {
            return Blueberry.page({ slug: slugF, lang })
                .then((resp) => {
                    if (
                        resp &&
                        resp.customFields &&
                        resp.customFields.ambientBlock &&
                        resp.customFields.ambientBlock.length > 0
                    ) {
                        Blueberry.collectionChildren({
                            limit: 6,
                            slug: resp.customFields.ambientBlock[0].slug,
                        }).catch((err) => {});
                    }

                    if (
                        resp &&
                        resp.customFields &&
                        resp.customFields.recomendedProducts &&
                        resp.customFields.recomendedProducts !== ""
                    ) {
                        Blueberry.itemToUser({
                            limit: 25,
                            filter: resp.customFields.recomendedProducts,
                        }).catch(() => {});
                    }
                    resolve(resp);
                })
                .catch((err) => {
                    console.log(err);
                    resolve({
                        title: "Porque Nosotros - Noritex",
                        content: "",
                        protected: false,

                        seo: { title: "", description: "" },
                    });
                });
        });
    }

    handleCartChange = () => {
        this.setState({ cahnge: 100 });
    };

    renderBody() {
        if (this.props.store.user.id == null) {
            return <Loader />;
        }

        const { lang } = this.props.match.params;

        const {
            user,
            wishlists,
            menus,
            recomendations,
            history,
            childCollection,
        } = this.props.store;
        const { location, jsonSettings, isLoading } = this.props;

        const data = this.props.data
            ? this.props.data
            : {
                  content: "",
                  title: "",
                  seo: {
                      title: "Noritex",
                      page: "Noritex es una tienda en linea para comprar mayorista de decoracion",
                  },
              };

        const title = `Home - ${websiteName}`;

        return (
            <>
                <SEOFieldsHelmet
                    defaultTitle={title}
                    defaultDescription={""}
                    lang={lang}
                    seo={data.seo}
                />

                {this.props.store.user &&
                typeof window !== "undefined" &&
                window.localStorage.getItem("didTutorial") == null &&
                (this.props.store.user.didTutorial == null ||
                    !this.props.store.user.didTutorial) ? (
                    <Tutorial handleCartChange={this.handleCartChange} />
                ) : null}
                <Onboarding user={user} />

                <HomeContainer
                    location={location}
                    user={user}
                    wishlists={wishlists}
                    lang={lang}
                    menus={menus}
                    data={data}
                    recomendations={recomendations}
                    childCollection={childCollection}
                    history={history}
                    jsonSettings={jsonSettings}
                    _t={this._t}
                    addToCart={this.addToCart}
                    viewItem={this.viewItem}
                    isLoadingPage={isLoading}
                    gotoThankYouPage={this.gotoThankYouPage}
                    toggleModalProduct={this.toggleModalProduct}
                />
                <Listener user={user} action={this.props.refetch} />
            </>
        );
    }
}

export default withTracker()(HomeLogin);

// export default MyAccount
