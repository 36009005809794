import { useState, useEffect } from "react";
import { BlueberryData } from "../BlueberryData";
import Blueberry from "../index.js";

function useWishlist(productId) {
    const [wishlists, setWishlist] = useState([]);

    useEffect(() => {
        const newWishlist = [...BlueberryData.wishlists];

        newWishlist.map((index, i) => {
            newWishlist[i] = {
                ...index,
                checked:
                    index && index.products && index.products.length > 0
                        ? index.products.indexOf(productId) > -1
                        : false,
            };
        });
        setWishlist(newWishlist);
    }, [BlueberryData.wishlists, productId]);

    return [wishlists];
}

export default useWishlist;
