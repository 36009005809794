import React from "react";
import Helmet from "react-helmet";

import ProductSectionTemplate from "../templates/ProductsSectionTemplate";
import { removeHtml, getPredefinedOrder } from "../utils";
import withTracker from "../../blueberry/connectPromise";
import Blueberry from "../../blueberry/index";
import ColectionView2 from "./CollectionView2";
import Banner from "../components/Banner";
import renderIf from "../utils/renderIf";
import SEOFieldsHelmet from "../utils/SEOFieldsHelmet";
import Listener from "../components/Listener";

// import { getParameterByName } from "../utils";

export const getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
};

class Collection extends ProductSectionTemplate {
    constructor(props) {
        super(props);
        const lastKnownProduct = Blueberry.getLastKnownProduct();
        this.state = {
            key: 0,
            lastKnownProduct,
            isHeaderOpen: true,
            ...this.productDefaultState,
            isRetrivingProducts: true,
        };
    }

    static async getInitialData(props) {
        const self = this;
        // console.log(props);
        const { collection, defaultTags, globalTags, brandSlug } =
            props.match.params;
        const { location, isServer } = props;

        return new Promise((resolve) => {
            Blueberry.collection(decodeURI(collection), brandSlug)
                .then((resp) => {
                    if (
                        resp &&
                        resp.customFields &&
                        resp.customFields.recomendedProducts &&
                        resp.customFields.recomendedProducts !== ""
                    ) {
                        Blueberry.itemToUser({
                            limit: 25,
                            filter: resp.customFields.recomendedProducts,
                        }).catch(() => {});
                    }

                    let currentPage =
                        location.search != null && location.search != ""
                            ? parseInt(
                                  getParameterByName("page", location.search)
                              )
                            : 1;

                    let limit =
                        location.search && location.search != ""
                            ? getParameterByName("limit", location.search)
                            : 35;
                    let order =
                        location.search && location.search != ""
                            ? getParameterByName("order", location.search)
                            : getPredefinedOrder(false);
                    let tags =
                        defaultTags &&
                        defaultTags !== "no-tags" &&
                        defaultTags.indexOf("?") > -1
                            ? decodeURI(defaultTags).split("?")[0].split("+")
                            : defaultTags
                            ? defaultTags.split("+")
                            : [];

                    if (globalTags) {
                        let filters =
                            globalTags && globalTags.indexOf("?") > -1
                                ? decodeURI(globalTags).split("?")[0]
                                : globalTags;

                        filters = filters ? filters.split("+") : [];

                        tags = tags.concat(filters);
                    }

                    if (
                        typeof window !== "undefined" &&
                        typeof analytics !== "undefined"
                    )
                        analytics.track("Product List Viewed", {
                            category: collection,
                            slug: collection,
                        });

                    if (isServer) {
                        Blueberry.productCollections({
                            slug: decodeURI(collection),
                            currentPage,
                            limit,
                            tags: [...tags],
                            order,
                            brandSlug,
                        })
                            .then((products) => {
                                resolve(resp);
                            })
                            .catch((err) => {
                                resolve(null);
                            });
                    } else {
                        resolve(resp);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    resolve(null);
                });
        });
    }

    changeQueries = (slug, page, limit, tags, order) => {
        const { lang, defaultTags } = this.props.match.params;
        this.setWindowTop();

        const query = `?page=${page}&limit=${limit}&order=${order}`;

        if (defaultTags != null) {
            this.props.history.push(
                `/${this.getBasePath()}/collection/${slug}/${defaultTags}/${tags.join(
                    "+"
                )}${query}`
            );
            // return
        } else if (tags != null && tags.length > 0) {
            this.props.history.push(
                `/${this.getBasePath()}/collection/${slug}/no-tags/${tags.join(
                    "+"
                )}${query}`
            );
        } else {
            this.props.history.push(
                `/${this.getBasePath()}/collection/${slug}${query}`
            );
        }
    };

    retrieveProducts = (slug, page, limit, tags, order) => {
        const { lang, defaultTags, brandSlug } = this.props.match.params;
        this.setState({ isRetrivingProducts: true });

        const defaultTagsObj =
            defaultTags && defaultTags !== "no-tags"
                ? defaultTags.split("+")
                : [];
        const newTags = [...tags].concat(defaultTagsObj);

        Blueberry.productCollections({
            slug,
            currentPage: page,
            limit,
            tags: newTags,
            order,
            brandSlug,
        })
            .then((resp) => {
                resolve(resp);
                this.setState({ isRetrivingProducts: false });
                if (analytics)
                    analytics.track("Product List Viewed", {
                        list_id: `${slug}_page${currentPage}_limit${limit}_order${order}`,
                        category: slug,
                        page: currentPage,
                        order,
                        limit,
                        filters: [...tags],
                        products: [...resp.products],
                    });
            })
            .catch((err) => {
                this.setState({ isRetrivingProducts: false });
            });
    };

    getRecomendations = (slug) => {
        Blueberry.itemToItem({ slug, scenario: "similaritems" })
            .then(() => {})
            .catch((err) => {
                // this.setState({isLoadingItems: false, errorLoadingItems: true})
            });
    };

    getCompleteTheLook = (slug) => {
        Blueberry.backWardFindCollectionProducts({ slug })
            .then((resp) => {
                this.setState({
                    isLoadingAmbient: false,
                    ambientProducts: resp,
                });
            })
            .catch(() => {
                this.setState({
                    isLoadingAmbient: false,
                    ambientProducts: null,
                });
            });
        Blueberry.productAssociations({ slug })
            .then((resp) => {
                this.setState({
                    asociatedProducts: resp,
                });
            })
            .catch(() => {
                this.setState({
                    asociatedProducts: null,
                });
            });
    };

    renderBody() {
        const {
            collections,
            childCollection,
            products,
            itemToItem,

            user,
        } = this.props.store;
        const { isRetrivingProducts, asociatedProducts } = this.state;

        const { lang, globalTags, defaultTags } = this.props.match.params;
        const { location, jsonSettings } = this.props;
        const slug = this.props.match.params.collection;

        const data = collections[slug];

        const { ambientProducts, lastKnownProduct } = this.state;

        // console.log(data, slug, collections);

        const productsColleciton = products[slug];

        return (
            <>
                <SEOFieldsHelmet
                    seo={data ? data.seo : null}
                    lang={lang}
                    defaultTitle={data ? data.title : "Busqueda no encontrada"}
                    defaultDescription={
                        data ? data.description : "Busqueda no encontrada"
                    }
                />
                <ColectionView2
                    data={data}
                    slug={slug}
                    _t={this._t}
                    user={user}
                    isLoggedIn={this.isLoggedIn()}
                    products={productsColleciton}
                    retrieveProducts={this.retrieveProducts}
                    changeQueries={this.changeQueries}
                    ambientProducts={ambientProducts}
                    jsonSettings={jsonSettings}
                    itemToItem={itemToItem}
                    selectedTags={globalTags}
                    defaultTags={defaultTags}
                    getRecomendations={this.getRecomendations}
                    getCompleteTheLook={this.getCompleteTheLook}
                    childCollection={childCollection}
                    searchQuery={location.search}
                    location={location}
                    addToCart={this.addToCart}
                    isRetrivingProducts={isRetrivingProducts}
                    viewItem={this.viewItem}
                    asociatedProducts={asociatedProducts}
                    lastKnownProduct={lastKnownProduct}
                    removeItem={this.removeItem}
                />

                {renderIf(!this.isLoggedIn())(
                    <Banner
                        config={[
                            {
                                title: this._t("readyToBuyProductsOnline"),
                                cta: {
                                    title: this._t("registerToBuy"),
                                    link: this.registerLink(),
                                },
                                list: [
                                    { p: this._t("unique1") },
                                    { p: this._t("unique3") },
                                    { p: this._t("unique2") },
                                ],
                            },
                        ]}
                    />
                )}

                <Listener user={user} action={this.props.refetch} />
            </>
        );
    }
}

export default withTracker()(Collection);
