import React from "react";
import Helmet from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import InternalLink from "../../InternalLink";

function is_touch_device() {
    try {
        document.createEvent("TouchEvent");
        return true;
    } catch (e) {
        return false;
    }
}

function MegaLink({
    to,
    link,
    slug,
    type,
    title,
    children,
    tag,
    images,
    path,
}) {
    const [menuIndexOpen, setMenuIndexOpen] = React.useState(0);
    const [isVisible, setIsMenuVisible] = React.useState(false);
    const timeoutPosition = React.useRef();
    const refTimout = React.useRef();
    const position0 = React.useRef();
    const [img, setImage] = React.useState(
        images && images.length > 0 ? images[0] : null
    );
    const [forceOpen] = React.useState(is_touch_device());

    const timerRef = React.useRef();

    const insideTriangle = (pos0, pos1) => {
        const x0 = pos1[0],
            y0 = pos1[1],
            x1 = pos0[0],
            y1 = pos0[1],
            x2 = pos0[0] + 200,
            y2 = pos0[1] - 200, // bottom point
            x3 = pos0[0] + 200,
            y3 = pos0[1] + 200; // top point

        var b0 = (x2 - x1) * (y3 - y1) - (x3 - x1) * (y2 - y1),
            b1 = ((x2 - x0) * (y3 - y0) - (x3 - x0) * (y2 - y0)) / b0,
            b2 = ((x3 - x0) * (y1 - y0) - (x1 - x0) * (y3 - y0)) / b0,
            b3 = ((x1 - x0) * (y2 - y0) - (x2 - x0) * (y1 - y0)) / b0;

        // var span1 = document.createElement("span");
        // span1.innerHTML = "0";
        // span1.className = "span-dot";
        // span1.setAttribute(
        //     "style",
        //     `width: 5px; height: 5px ;background-color:red; position: absolute; top: ${y0}px; left:${x0}px; z-index: 999999999;`
        // );
        // document.body.appendChild(span1);

        // var span1 = document.createElement("span");
        // span1.innerHTML = "1";
        // span1.className = "span-dot";
        // span1.setAttribute(
        //     "style",
        //     `width: 5px; height: 5px ;background-color:red; position: absolute; top: ${y1}px; left:${x1}px; z-index: 999999999;`
        // );
        // document.body.appendChild(span1);

        // var span1 = document.createElement("span");
        // span1.innerHTML = "2";
        // span1.className = "span-dot";
        // span1.setAttribute(
        //     "style",
        //     `width: 5px; height: 5px ;background-color:red; position: absolute; top: ${y2}px; left:${x2}px; z-index: 999999999;`
        // );
        // document.body.appendChild(span1);

        // var span1 = document.createElement("span");
        // span1.innerHTML = "3";
        // span1.className = "span-dot";
        // span1.setAttribute(
        //     "style",
        //     `width: 5px; height: 5px ;background-color:red; position: absolute; top: ${y3}px; left:${x3}px; z-index: 999999999;`
        // );
        // document.body.appendChild(span1);

        return b1 > 0 && b2 > 0 && b3 > 0;
    };

    const setPosition = (newPos) => {
        if (refTimout.current) clearTimeout(refTimout.current);
        position0.current = newPos;
        refTimout.current = setTimeout(() => {
            position0.current = null;
        }, 1000);
    };

    const handleSetIndex = (newIndex) => {
        setMenuIndexOpen(newIndex);
        if (children != null && children.length > 0) {
            setImage(
                children[newIndex].images &&
                    children[newIndex].images.length > 0
                    ? children[newIndex].images[0]
                    : images && images.length > 0
                    ? images[0]
                    : null
            );
        }
    };

    const onShowMenuIndex = (e) => {
        e.preventDefault();
        const newIndex = parseInt(e.target.getAttribute("data-index"));
        const newPos = [e.clientX, e.clientY];
        if (
            position0.current == null ||
            (menuIndexOpen !== newIndex &&
                !insideTriangle(position0.current, newPos))
        ) {
            setPosition(newPos);
            if (timeoutPosition.current) {
                clearTimeout(timeoutPosition.current);
            }
            handleSetIndex(newIndex);
        } else if (menuIndexOpen === newIndex) {
            setPosition(newPos);
        }
    };

    const onMouseLeave = (e) => {
        // const newIndex = parseInt(e.target.getAttribute("data-index"));
        if (timeoutPosition.current) {
            clearTimeout(timeoutPosition.current);
        }
        timeoutPosition.current = setTimeout(() => {
            position0.current = null;
            // handleSetIndex(newIndex);
        }, 600);
    };

    const handleOnMouseEnter = () => {
        if (timerRef.current != null) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }
        if (forceOpen || window.__isMenuVisible) {
            setIsMenuVisible(true);
        } else {
            timerRef.current = setTimeout(() => {
                setIsMenuVisible(true);
            }, 0);
        }
    };

    const handleOnMouseLeave = () => {
        if (timerRef.current != null) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = null;
        setIsMenuVisible(false);
    };

    React.useEffect(() => {
        setIsMenuVisible(false);
    }, [path]);

    React.useEffect(() => {
        window.__isMenuVisible = isVisible;
    }, [isVisible]);

    return React.useMemo(
        () => (
            <li
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
                onTouchStart={handleOnMouseEnter}
            >
                <Helmet>
                    <link
                        rel="prefetch"
                        href={`${img}?d=300x300`}
                        as="image"
                        data-header="header"
                    />
                </Helmet>
                <InternalLink
                    preventClick={forceOpen}
                    to={to}
                    link={link}
                    slug={slug}
                    type={type}
                    tag={tag}
                >
                    {title}
                </InternalLink>
                {isVisible ? (
                    <div className={`submenu`}>
                        <div className="wrap">
                            <div className="submenu-left">
                                <div className="level">
                                    <ul className="is-active categories">
                                        {children &&
                                            children.map((index, i) => {
                                                return !index.hide ? (
                                                    <li
                                                        key={
                                                            index.title +
                                                            "mega link top level" +
                                                            i
                                                        }
                                                        className={
                                                            menuIndexOpen === i
                                                                ? "is-active"
                                                                : ""
                                                        }
                                                    >
                                                        <a
                                                            data-index={i}
                                                            href=""
                                                            onMouseOver={
                                                                onShowMenuIndex
                                                            }
                                                            onMouseLeave={
                                                                onMouseLeave
                                                            }
                                                            onClick={
                                                                onShowMenuIndex
                                                            }
                                                        >
                                                            {index.title}
                                                        </a>
                                                    </li>
                                                ) : null;
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className="level second-level">
                                    {children &&
                                        children.map((index, i) => (
                                            <ul
                                                key={
                                                    index.title +
                                                    "desktop nav" +
                                                    i
                                                }
                                                className={
                                                    menuIndexOpen === i
                                                        ? "is-active"
                                                        : ""
                                                }
                                            >
                                                {index.children
                                                    ? index.children.map(
                                                            (link, j) => {
                                                                return !link.hide ? (
                                                                    <li
                                                                        key={
                                                                            index.title +
                                                                            "desktop sub" +
                                                                            j +
                                                                            i
                                                                        }
                                                                    >
                                                                        <InternalLink
                                                                            {...link}
                                                                        >
                                                                            {
                                                                                link.title
                                                                            }
                                                                        </InternalLink>
                                                                    </li>
                                                                ) : null;
                                                            }
                                                      )
                                                    : null}
                                            </ul>
                                        ))}
                                </div>
                            </div>
                            {img ? (
                                <div className="submenu-right">
                                    <LazyLoadImage
                                        src={`${img}?d=300x300`}
                                        alt={`${title} - Noritex`}
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>
                ) : null}
            </li>
        ),
        [isVisible, menuIndexOpen, img]
    );
}

export default MegaLink;
