import React from "react";
import styled from "styled-components";
import { Heading, Level, LevelItem, Title } from "../../sdk/Layout";

const TitleExtended = styled(Title)`
    margin-top: 0;
`;

function ProductTitle({ size, slug, title, title_eng, title_pt }) {
    const productSlug = slug ? slug : title || title_eng || title_pt;

    return (
        <TitleExtended id="product-detail-title" size={size}>
            {title || title_eng || title_pt}
        </TitleExtended>
    );
}

export default React.memo(ProductTitle);
