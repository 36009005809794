import React, { Component } from "react";
import loadable from "@loadable/component";

import PersonsIcon from "./icons/PersonsIcon";
const TruckIcon = loadable(() => import("./icons/TruckIcon"));
const PhoneIcon = loadable(() => import("./icons/PhoneIcon"));
const ChatIcon = loadable(() => import("./icons/ChatIcon"));
const LoaderIcon = loadable(() => import("./icons/LoaderIcon"));
import CheckBoxIcon from "./icons/CheckBoxIcon";
const CheckIcon = loadable(() => import("./icons/CheckIcon"));
const EnlargeIcon = loadable(() => import("./icons/EnlargeIcon"));

// App component - represents the whole app
const Icon = props => {
	if (props.icon === "persons") {
		return <PersonsIcon />;
	}

	if (props.icon === "chevron-right") {
		return (
			<svg className="flickity-button-icon" viewBox="0 0 100 100">
				<path
					d="M 10,50 L 60,100 L 65,95 L 20,50  L 65,5 L 60,0 Z"
					className="arrow"
					transform="translate(100, 100) rotate(180) "
				></path>
			</svg>
		);
	}

	if (props.icon === "chevron-left") {
		return (
			<svg className="flickity-button-icon" viewBox="0 0 100 100">
				<path
					d="M 10,50 L 60,100 L 65,95 L 20,50  L 65,5 L 60,0 Z"
					className="arrow"
				></path>
			</svg>
		);
	}

	if (props.icon === "truck") {
		return <TruckIcon />;
	}

	if (props.icon === "phone") {
		return <PhoneIcon />;
	}

	if (props.icon === "chat") {
		return <ChatIcon />;
	}

	if (props.icon === "checkbox") {
		return <CheckBoxIcon />;
	}

	if (props.icon === "close") {
		return (
			<svg
				aria-hidden="true"
				focusable="false"
				role="presentation"
				width={10}
				height={10}
				viewBox="0 0 10 10"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="M6.08785659,5 L9.77469752,1.31315906 L8.68684094,0.225302476 L5,3.91214341 L1.31315906,0.225302476 L0.225302476,1.31315906 L3.91214341,5 L0.225302476,8.68684094 L1.31315906,9.77469752 L5,6.08785659 L8.68684094,9.77469752 L9.77469752,8.68684094 L6.08785659,5 Z" />
			</svg>
		);
	}

	if (props.icon === "cart") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				height={24}
				viewBox="0 0 24 24"
				width={24}
			>
				<path d="M0 0h24v24H0V0z" fill="none" />
				<path d="M15.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z" />
			</svg>
		);
	}

	if (props.icon === "loader") {
		return <LoaderIcon />;
	}

	if (props.icon === "check") {
		return <CheckIcon />;
	}

	if (props.icon === "enlarge") {
		return <EnlargeIcon small={props.small} />;
	}

	if (props.icon === "check2") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
			>
				<path d="M21.856 10.303c.086.554.144 1.118.144 1.697 0 6.075-4.925 11-11 11s-11-4.925-11-11 4.925-11 11-11c2.347 0 4.518.741 6.304 1.993l-1.422 1.457c-1.408-.913-3.082-1.45-4.882-1.45-4.962 0-9 4.038-9 9s4.038 9 9 9c4.894 0 8.879-3.928 8.99-8.795l1.866-1.902zm-.952-8.136l-9.404 9.639-3.843-3.614-3.095 3.098 6.938 6.71 12.5-12.737-3.096-3.096z" />
			</svg>
		);
	}

	if (props.icon === "person") {
		return (
			<svg
				className="icon-person"
				xmlns="http://www.w3.org/2000/svg"
				height="24"
				viewBox="0 0 24 24"
				width="24"
			>
				<path d="M0 0h24v24H0V0z" fill="none" />
				<path d="M12 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0 10c2.7 0 5.8 1.29 6 2H6c.23-.72 3.31-2 6-2m0-12C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 10c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
			</svg>
		);
	}

	if (props.icon === "home") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				height={24}
				viewBox="0 0 24 24"
				width={24}
			>
				<path d="M0 0h24v24H0V0z" fill="none" />
				<path d="M12 5.69l5 4.5V18h-2v-6H9v6H7v-7.81l5-4.5M12 3L2 12h3v8h6v-6h2v6h6v-8h3L12 3z" />
			</svg>
		);
	}

	return (
		<span
			className={`navmenu-icon navmenu-icon-depth-1 icon ${
				props.open ? "open" : ""
			} ${props.icon === "arrow-right" ? "twisted" : ""} `}
		>
			<svg
				aria-hidden="true"
				focusable="false"
				role="presentation"
				xmlns="http://www.w3.org/2000/svg"
				width="8"
				height="6"
				viewBox="0 0 8 6"
			>
				<g fill="currentColor" fillRule="evenodd">
					<polygon
						className="icon-chevron-down-left"
						points="4 5.371 7.668 1.606 6.665 .629 4 3.365"
					></polygon>
					<polygon
						className="icon-chevron-down-right"
						points="4 3.365 1.335 .629 1.335 .629 .332 1.606 4 5.371"
					></polygon>
				</g>
			</svg>
		</span>
	);
};

export default React.memo(Icon);
