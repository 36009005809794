import React from "react";
import { config } from "../../../../config";

function Icons({ icon }) {
    if (icon === "chevron-left") {
        return (
            <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fal"
                data-icon="chevron-left"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 256 512"
            >
                <path
                    fill="currentColor"
                    d="M238.475 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L50.053 256 245.546 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L10.454 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z"
                />
            </svg>
        );
    }
    if (icon === "login") {
        return (
            <svg viewBox="0 0 24 24">
                <path
                    fill="currentColor"
                    d="M19,3H5C3.89,3 3,3.89 3,5V9H5V5H19V19H5V15H3V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M10.08,15.58L11.5,17L16.5,12L11.5,7L10.08,8.41L12.67,11H3V13H12.67L10.08,15.58Z"
                />
            </svg>
        );
    }
    if (icon === "hogar") {
        return (
            <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fal"
                data-icon="lamp"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 416 512"
            >
                <path
                    fill="currentColor"
                    d="M413.7 237.8l-71.9-216C337.3 8.6 327.2 0 316 0H112c-10.6 0-20.3 7.7-25 19.9l-84 216C-6.3 259.8 7.2 288 28 288h92.7C86.3 327.8 64 383.9 64 423.6c0 26.1 9.7 50.3 26.2 70.1 9.9 11.9 26.1 18.3 42.7 18.3h150.3c16.6 0 32.8-6.4 42.7-18.3 16.5-19.8 26.2-43.9 26.2-70.1 0-39.7-22.3-95.8-56.7-135.6H388c20.1 0 33.6-26.4 25.7-50.2zM320 423.6c0 17.6-6.5 34.8-18.8 49.6-3.4 4.1-10.5 6.8-18 6.8H132.9c-7.5 0-14.6-2.7-18-6.8-12.3-14.8-18.8-32-18.8-49.6 0-41.7 31-106 70.4-135.6h83.1C289 317.6 320 381.9 320 423.6zM377.4 256H38.6c-4.3 0-7.3-4.2-5.9-8.2 0-.1.1-.2.1-.3L116.7 32h194.7l71.9 215.9v.1c1.4 4-1.7 8-5.9 8z"
                />
            </svg>
        );
    }
    if (icon === "christmas") {
        return (
            <svg viewBox="0 0 24 24">
                <path
                    fill="currentColor"
                    d="M12,7L13.3,9.8L16.5,9.4L14.5,12L16.4,14.5L13.2,14.1L12,17L10.7,14.2L7.5,14.6L9.5,12L7.6,9.5L10.8,9.9L12,7M12,2L9.5,7.7L3.3,7L7,12L3.4,17L9.6,16.3L12,22L14.5,16.3L20.7,16.9L17,12L20.6,7L14.4,7.7L12,2Z"
                />
            </svg>
        );
    }
    if (icon === "christmas-2") {
        return (
            <svg viewBox="0 0 24 24">
                <path
                    fill="currentColor"
                    d="M20.79,13.95L18.46,14.57L16.46,13.44V10.56L18.46,9.43L20.79,10.05L21.31,8.12L19.54,7.65L20,5.88L18.07,5.36L17.45,7.69L15.45,8.82L13,7.38V5.12L14.71,3.41L13.29,2L12,3.29L10.71,2L9.29,3.41L11,5.12V7.38L8.5,8.82L6.5,7.69L5.92,5.36L4,5.88L4.47,7.65L2.7,8.12L3.22,10.05L5.55,9.43L7.55,10.56V13.45L5.55,14.58L3.22,13.96L2.7,15.89L4.47,16.36L4,18.12L5.93,18.64L6.55,16.31L8.55,15.18L11,16.62V18.88L9.29,20.59L10.71,22L12,20.71L13.29,22L14.7,20.59L13,18.88V16.62L15.5,15.17L17.5,16.3L18.12,18.63L20,18.12L19.53,16.35L21.3,15.88L20.79,13.95M9.5,10.56L12,9.11L14.5,10.56V13.44L12,14.89L9.5,13.44V10.56Z"
                />
            </svg>
        );
    }
    if (icon === "christmas-3") {
        return (
            <svg viewBox="0 0 24 24">
                <path
                    fill="currentColor"
                    d="M12,6.7L13.45,10.55L17.3,12L13.45,13.45L12,17.3L10.55,13.45L6.7,12L10.55,10.55L12,6.7M12,1L9,9L1,12L9,15L12,23L15,15L23,12L15,9L12,1Z"
                />
            </svg>
        );
    }

    if (icon === "escolar") {
        return (
            <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fal"
                data-icon="graduation-cap"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
            >
                <path
                    fill="currentColor"
                    d="M612.16 153.99l-265-85.68c-17.81-5.75-36.5-5.75-54.31 0l-265 85.68C10.94 159.46 0 174.38 0 192s10.94 32.54 27.84 38.01l29.71 9.6c-3.3 6.18-5.74 12.83-7.33 19.8C39.53 264.59 32 275.32 32 288c0 12.73 7.57 23.52 18.33 28.67L32.28 428.53C30.67 438.52 36.19 448 43.62 448h40.75c7.43 0 12.96-9.48 11.34-19.47L77.67 316.67C88.43 311.52 96 300.73 96 288c0-10.6-5.49-19.54-13.43-25.37 1.49-4.66 3.8-8.86 6.57-12.81l53.47 17.29L128 384c0 35.35 85.96 64 192 64s192-28.65 192-64l-14.61-116.89L612.16 230c16.9-5.46 27.84-20.38 27.84-38s-10.94-32.54-27.84-38.01zM479.48 381.86C468.72 393.19 414.04 416 320 416c-94.04 0-148.72-22.81-159.48-34.14l13.09-104.73 119.24 38.55c2.6.84 25.72 9.23 54.31 0l119.24-38.55 13.08 104.73zm122.8-182.28l-265 85.68c-11.31 3.66-23.25 3.66-34.56 0l-175.67-56.8 195.89-36.74c8.69-1.62 14.41-9.98 12.78-18.67-1.62-8.7-10.16-14.39-18.66-12.77l-203.78 38.2c-12.4 2.32-23.51 7.65-33.08 14.83l-42.49-13.74c-7.85-2.55-7.46-12.74 0-15.15l265-85.68c15.1-4.88 27.84-2.17 34.56 0l265 85.68c7.39 2.39 7.91 12.6.01 15.16z"
                />
            </svg>
        );
    }
    if (icon === "ambient") {
        return (
            <svg viewBox="0 0 24 24">
                <path
                    fill="currentColor"
                    d="M21 9V7C21 5.35 19.65 4 18 4H14C13.23 4 12.53 4.3 12 4.78C11.47 4.3 10.77 4 10 4H6C4.35 4 3 5.35 3 7V9C1.35 9 0 10.35 0 12V17C0 18.65 1.35 20 3 20V22H5V20H19V22H21V20C22.65 20 24 18.65 24 17V12C24 10.35 22.65 9 21 9M14 6H18C18.55 6 19 6.45 19 7V9.78C18.39 10.33 18 11.12 18 12V14H13V7C13 6.45 13.45 6 14 6M5 7C5 6.45 5.45 6 6 6H10C10.55 6 11 6.45 11 7V14H6V12C6 11.12 5.61 10.33 5 9.78V7M22 17C22 17.55 21.55 18 21 18H3C2.45 18 2 17.55 2 17V12C2 11.45 2.45 11 3 11S4 11.45 4 12V16H20V12C20 11.45 20.45 11 21 11S22 11.45 22 12V17Z"
                />
            </svg>
        );
    }
    if (icon === "register") {
        return (
            <svg viewBox="0 0 24 24">
                <path
                    fill="currentColor"
                    d="M20,6C20.58,6 21.05,6.2 21.42,6.59C21.8,7 22,7.45 22,8V19C22,19.55 21.8,20 21.42,20.41C21.05,20.8 20.58,21 20,21H4C3.42,21 2.95,20.8 2.58,20.41C2.2,20 2,19.55 2,19V8C2,7.45 2.2,7 2.58,6.59C2.95,6.2 3.42,6 4,6H8V4C8,3.42 8.2,2.95 8.58,2.58C8.95,2.2 9.42,2 10,2H14C14.58,2 15.05,2.2 15.42,2.58C15.8,2.95 16,3.42 16,4V6H20M4,8V19H20V8H4M14,6V4H10V6H14M12,9A2.25,2.25 0 0,1 14.25,11.25C14.25,12.5 13.24,13.5 12,13.5A2.25,2.25 0 0,1 9.75,11.25C9.75,10 10.76,9 12,9M16.5,18H7.5V16.88C7.5,15.63 9.5,14.63 12,14.63C14.5,14.63 16.5,15.63 16.5,16.88V18Z"
                />
            </svg>
        );
    }
    if (icon === "whatsapp") {
        return (
            <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="whatsapp"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
            >
                <path
                    fill="currentColor"
                    d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
                />
            </svg>
        );
    }
    if (icon === "wizard") {
        return (
            <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fal"
                data-icon="hat-wizard"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
            >
                <path
                    fill="currentColor"
                    d="M288 192l-16-32-16 32-32 16 32 16 16 32 16-32 32-16-32-16zm216 288h-24L379.18 247.34a73.068 73.068 0 0 1-2.21-52.44L442.67 0 223.83 123.68c-27.61 15.6-49.46 39.51-62.37 68.25L32 480H8c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h496c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8zm-436.92 0l31.72-70.6 55.86 27.93L176 480H67.08zM192 440.45l-13.48-26.97L151.55 400l26.96-13.48L192 359.55l13.48 26.96L232.45 400l-26.96 13.48L192 440.45zM208 480l21.33-42.67L304 400l-74.67-37.33L192 288l-37.33 74.67-44.96 22.48 80.94-180.11c10.19-22.67 27.11-41.18 48.93-53.51l146.08-82.55-39 115.7c-8.32 24.69-7.2 51.47 3.17 75.38l23.7 54.7L352 304l-16-32-16 32-32 16 32 16 16 32 16-32 25.26-12.63L445.12 480H208z"
                />
            </svg>
        );
    }

    if (icon === "home") {
        return (
            <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fal"
                data-icon="home"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
            >
                <path
                    fill="currentColor"
                    d="M541 229.16l-61-49.83v-77.4a6 6 0 0 0-6-6h-20a6 6 0 0 0-6 6v51.33L308.19 39.14a32.16 32.16 0 0 0-40.38 0L35 229.16a8 8 0 0 0-1.16 11.24l10.1 12.41a8 8 0 0 0 11.2 1.19L96 220.62v243a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16v-128l64 .3V464a16 16 0 0 0 16 16l128-.33a16 16 0 0 0 16-16V220.62L520.86 254a8 8 0 0 0 11.25-1.16l10.1-12.41a8 8 0 0 0-1.21-11.27zm-93.11 218.59h.1l-96 .3V319.88a16.05 16.05 0 0 0-15.95-16l-96-.27a16 16 0 0 0-16.05 16v128.14H128V194.51L288 63.94l160 130.57z"
                />
            </svg>
        );
    }

    return null;
}

export default React.memo(Icons);
