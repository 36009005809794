import React from "react";

export default function BagIcon() {
    return (
        <svg
            width="21px"
            height="23px"
            viewBox="0 0 21 23"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <g
                    id="current_bar"
                    transform="translate(-811.000000, -138.000000)"
                    fill="#000000"
                    fillRule="nonzero"
                >
                    <g
                        id="Group-8-Copy"
                        transform="translate(563.000000, 120.000000)"
                    >
                        <g id="Group">
                            <g
                                id="Stacked-Group"
                                transform="translate(34.000000, 18.000000)"
                            >
                                <g
                                    id="bag"
                                    transform="translate(213.000000, 0.000000)"
                                >
                                    <path d="M16.8125,5.75 C16.8125,2.57945898 14.233041,0 11.0625,0 C7.89191406,0 5.3125,2.57945898 5.3125,5.75 L1,5.75 L1,19.40625 C1,21.3910332 2.6089668,23 4.59375,23 L17.53125,23 C19.5160332,23 21.125,21.3910332 21.125,19.40625 L21.125,5.75 L16.8125,5.75 Z M11.0625,1.4375 C13.4404395,1.4375 15.375,3.37206055 15.375,5.75 L6.75,5.75 C6.75,3.37206055 8.68456055,1.4375 11.0625,1.4375 Z M19.6875,19.40625 C19.6875,20.5951973 18.7201973,21.5625 17.53125,21.5625 L4.59375,21.5625 C3.40480273,21.5625 2.4375,20.5951973 2.4375,19.40625 L2.4375,7.1875 L5.3125,7.1875 L5.3125,9.34375 C5.3125,9.74072461 5.63432031,10.0625 6.03125,10.0625 C6.42817969,10.0625 6.75,9.74072461 6.75,9.34375 L6.75,7.1875 L15.375,7.1875 L15.375,9.34375 C15.375,9.74072461 15.6967754,10.0625 16.09375,10.0625 C16.4907246,10.0625 16.8125,9.74072461 16.8125,9.34375 L16.8125,7.1875 L19.6875,7.1875 L19.6875,19.40625 Z" />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
