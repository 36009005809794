export const tags_en = {
	categoria: "category",
	Disponibilidad: "Availability",
	disponibilidad: "Availability",
	Inmediato: "Immediate",
	inmediato: "Immediate",
	Futuro: "Future",
	futuro: "Future",
	puerto: "port",
	feria: "fair",
	colecciones: "collections",
	Transito: "Transit",
	categoria: "category",
	"flores y plantas": "flowers and plants",
	color: "color",
	gris: "Gray",
	amarillo: "yellow",
	chocolate: "chocolate",
	rojo: "Red",
	bolas: "balls",
	"floreros ceramica": "ceramic vases",
	cojines: "cushions",
	lila: "purple",
	"portavela 1 vela": "candle holder 1 candle",
	verde: "green",
	"accesorios mesa": "table accessories",
	"floreros vidrio": "glass vases",
	"personas figuras": "people figures",
	azul: "blue",
	flores: "flowers",
	"otras figuras": "other figures",
	"arbol decorativo": "decorative tree",
	"rojo 2": "red 2",
	portavelas: "candleholder",
	santas: "santas",
	marcos: "Canvas",
	crema: "cream",
	"mugs y tazas": "mugs and cups",
	velas: "candles",
	"otras plantas": "other plants",
	"animales figuras": "animals figures",
	"relojes de pared": "Wall clocks",
	"cojines y fundas": "cushions and covers",
	animales: "animals",
	"otros colgantes": "other pendants",
	"figuras country": "country figures",
	canvas: "canvas",
	marron: "runaway",
	"aves figuras": "birds figures",
	renos: "reindeer",
	"frutas figuras": "fruit figures",
	"elefantes figuras": "elephants figures",
	"potes con pie": "pots with foot",
	"portaretratos 4x6": "picture frames 4x6",
	"portaretratos 5x7": "picture frames 5x7",
	sillas: "chairs",
	"lampara de mesa": "desk lamp",
	"potes sin pie": "footless pots",
	portaplatos: "Plate rack",
	"portaretratos 8x10": "picture frames 8x10",
	"accesorios de mesa": "table accessories",
	cortinas: "curtains",
	turquesa: "turquoise",
	"textil mesa": "textile table",
	"coronas y guirnaldas": "crowns and garlands",
	"acc recamara": "bedroom accesories",
	"acc bar": "bar accesories",
	alfombras: "rugs",
	angeles: "angeles",
	"mesas auxiliares": "auxiliary tables",
	"articulos de fomi": "fomi articles",
	"acc baño": "acc bathroom",
	rosado: "pinkish",
	figuras: "figures",
	"santas country": "santas country",
	envases: "packaging",
	"accesorios sala": "living room accessories",
	"espejo redondo": "round mirror",
	"platos decorativos": "decorative plates",
	"otros navideños": "other Christmas",
	"centro mesa vidrio": "glass center table",
	"portavelas daily": "daily holders",
	"accesorios cocina": "kitchen accessories",
	"placas de pared": "wall plates",
	cintas: "ribbons",
	organizadores: "organizers",
	"caminos de mesa": "table runners",
	"textil navidad country": "christmas country textile",
	funda: "Cushion",
	villas: "villas",
	manteleria: "table linen",
	"figuras nacimientos": "figures births",
	"juego de nacimientos": "birth game",
	"bolsas de regalo": "gift bags",
	linternas: "flashlights",
	bar: "bar",
	taburetes: "stools",
	picks: "picks",
	individuales: "individual",
	"vajillas por piezas": "tableware by pieces",
	"floreros metal": "metal vases",
	"textil cocina": "kitchen textile",
	"jarras y vasos": "jugs and glasses",
	orquideas: "orchids",
	abstractas: "abstract",
	fuentes: "sources",
	"set de portavelas": "set of candle holders",
	suculentas: "succulents",
	cascanueces: "nutcracker",
	"luces led": "led lights",
	"adornos para jardin": "garden ornaments",
	"esferas decorativas ceramica": "ceramic decorative spheres",
	"centro mesa ceramica": "ceramic center table",
	"floreros y centros": "vases and centers",
	"portaretratos multiples": "multiple picture frames",
	"sillas de bar": "barstools",
	"espejos rectangulares": "rectangular mirrors",
	anforas: "amphorae",
	"sets de baño": "bathroom sets",
	"otros muebles sala": "other living room furniture",
	"centro mesa metal": "metal table center",
	mantas: "mantas",
	"centros de mesa": "centerpieces",
	"bandejas metal": "metal trays",
	naranja: "Orange",
	"arbol premium": "arbol premium",
	pebeteros: "cauldrons",
	letreros: "signs",
	"hombre de nieve- country": "snowman- country",
	"africanas figuras": "african figures",
	"adornos colgantes": "hanging ornaments",
	candelabros: "chandeliers",
	adornos: "ornaments",
	poltronas: "armchairs",
	"set espejos": "set mirrors",
	"bandejas resina": "resin trays",
	"velas daily": "velas daily",
	"punta de arbol": "tree top",
	mallas: "tights",
	"falda arbol": "tree skirt",
	"portaretratos otras medidas": "picture frame other measures",
	"set cuadros": "set frames",
	"textil accesorios": "textile accessories",
	vajillas: "tableware",
	"hombre de nieve": "Snowman",
	cuberteria: "cutlery",
	mesas: "tables",
	estantes: "shelves",
	"adornos mesa": "table decorations",
	"bandejas ceramica": "ceramic trays",
	"recamara infantil": "childrenns bedroom",
	"floreros otros": "other vases",
	"marinas figuras": "marine figures",
	cactus: "cactus",
	arlequines: "harlequins",
	"plantas y hojas": "plants and leaves",
	"sala nautica": "nautical room",
	leggings: "leggings",
	"bandejas vidrio": "glass trays",
	"material didactico escolar": "school teaching material",
	"bandejas madera": "wooden trays",
	"relojes de mesa": "table clocks",
	lazos: "ties",
	consolas: "consoles",
	"mesas de centro": "coffee table",
	"accesorios nacimientos": "birth accessories",
	etiquetas: "labels",
	frutas: "fruit",
	"lampara de pie": "floor lamp",
	gabinetes: "cabinets",
	tops: "tops",
	"pinceles para manualidades": "craft brushes",
	copas: "glasses",
	"centro mesa resina": "resin table centerpiece",
	"adornos exteriores": "exterior trim",
	"acc de baño": "bath acc",
	otros: "others",
	"esferas decorativas vidrio": "decorative glass spheres",
	"cajas de regalos": "gift boxes",
	"bandejas para hornear": "baking trays",
	"espejo utilitario": "utility mirror",
	repisas: "shelves",
	"alfombras de exterior": "outdoor rugs",
	agendas: "agendas",
	"arbol basico": "basic tree",
	"sports bra": "sports good",
	arreglos: "arrangements",
	bases: "bases",
	"stickers para manualidades": "stickers for crafts",
	"adornos con luz led": "ornaments with led light",
	"juegos de tazas de café": "coffee cup sets",
	"carritos de servir": "serving carts",
	"muebles para jardin": "garden furniture",
	"bolas de agua": "water balls",
	"plantas surtidas": "assorted plants",
	trenes: "trains",
	"juegos de tazas de te": "teacup sets",
	casas: "houses",
	"oficina y escritorio": "office and desk",
	"bomboneras/pastilleros": "candy / pill boxes",
	"cojines country": "country cushions",
	"portaretratos 6x8": "picture frames 6x8",
	"cartucheras y lapiceras": "pencil cases and pens",
	"portaretratos especiales": "special picture frames",
	"esferas decorativas resina": "resin decorative beads",
	"sillas tapizadas": "upholstered chairs",
	lamparas: "lamps",
	pompones: "pompons",
	"potes de pared": "wall legs",
	"articulos para fiesta": "party supplies",
	estacas: "piles",
	"decoración de cocina": "kitchen decor",
	peso: "weight",
	"santas colgantes country": "santas country pendants",
	"muñecos animados": "animated dolls",
	"santas fantasia": "holy fantasy",
	"textil bano": "bathroom textile",
	"duendes- country": "goblins- country",
	niños: "kids",
	"bienvenidos country": "welcome country",
	"papel adhesivo para forrar": "adhesive paper for lining",
	shorts: "shorts",
	muebles: "furniture",
	"escenas villas": "villa scenes",
	resistencia: "endurance",
	"relojes de arena": "hourglasses",
	"lampara de techo": "ceiling light",
	"jgo tazas": "jgo mugs",
	"muebles cocina": "Kitchen furniture",
	"renos- country": "renos- country",
	"carpeteria para oficina": "office folder",
	"otros adornos": "other ornaments",
	"articulos para manualidades": "craft supplies",
	"ollas y sartenes": "pots and pans",
	"cepillo de inodoro": "toilet brush",
	accesorios: "accessories",
	"articulos de madera": "wooden articles",
	"juegos de mesa": "table games",
	"botas y medias country": "country boots and socks",
	"hoodies y sueter": "hoodies and sweater",
	fundas: "holsters",
	golosinas: "candies",
	sets: "sets",
	botas: "boots",
	"pinturas para manualidades": "craft paints",
	"boligrafos escolares": "boligrafos escolares",
	escarchas: "frost",
	"articulos de sederia": "silk items",
	"accesorios de entrenamiento": "training accessories",
	ajedrez: "Chess",
	"textil sala otros": "textile room others",
	"marcadores escolares": "school markers",
	"candelero con luz led": "candle holder with led light",
	"otros decorados": "other decorations",
	"comedor nautico": "nautical dining room",
	"arboles con luz": "trees with light",
	"velas de bateria": "battery candles",
	"accesorios villas": "villas accessories",
	palmeras: "palms",
	"carpetería escolar": "school folder",
	"rojo 3": "red 3",
	"accesorios yoga y pilates": "yoga and pilates accessories",
	"accesorios para oficina": "office accessories",
	"limpia pipas": "clean pipes",
	lapices: "pencils",
	papeles: "papers",
	"sillas plastico": "plastic chairs",
	"gnomo-country": "gnome-country",
	termos: "terms",
	"matts o tapetes": "matts or wallpaper",
	"porta carnet / gafetes / platificadoras":
		"ID holder / badges / plating machines",
	"accesorios para computadora": "computer accessories",
	pantalones: "pants",
	"arlequines fantasia": "fantasy harlequins",
	maletas: "suitcases",
	potes: "buddies",
	borradores: "drafts",
	"portavela 1 vela vidrio": "candle holder 1 glass candle",
	reglas: "rules",
	"nacimientos acrilicos": "acrylic births",
	"juegos vasos y jarra": "glasses and jug sets",
	colgantes: "pendants",
	"zapatillas flyknit": "flyknit sneakers",
	"sagrada familia": "sacred Family",
	"arcangel miguel": "archangel michael",
	tape: "tape",
	"caja de regalo": "gift box",
	"muñecos inflables": "inflatable dolls",
	"taza con cuchara": "cup with spoon",
	"de fátima / con pastores /con corazon":
		"of fatima / with shepherds / with heart",
	"san jose": "san jose",
	"sagrado corazon de jesus": "sacred Heart of Jesus",
	"figuras con luz": "figures with light",
	"basureros para baño": "bathroom trash cans",
	"cobertores de silla y respaldo country": "country chair and back covers",
	"luces de exterior": "outdoor lights",
	"esferas decorativas fibra": "decorative fiber spheres",
	ojitos: "little eyes",
	fechallegada: "Availability Date",
	"mas 120 dias": "over 120 days",
	bolsas: "bags",
	"luces basicas": "basic lights",
	"cangureras y cinturones": "fanny packs and belts",
	"milagrosa / medalla milagrosa": "miraculous / miraculous medal",
	canastas: "baskets",
	bonsai: "bonsai",
	"acc de cabello": "hair acc",
	"copa para vino tinto": "glass for red wine",
	"jackets y chalecos": "jackets and vests",
	"copa para agua": "water cup",
	"resaltadores escolares": "school highlighters",
	"comedor infantil": "children&#39;s dining room",
	"textil baño": "bathroom textile",
	bienvenidos: "Welcome",
	"accesorios personales religiosos": "religious personal accessories",
	"set de manualidades": "craft set",
	"articulos de fieltro": "felt items",
	"inmaculada concepción de maria": "Immaculate Conception",
	escritorios: "desks",
	bamboo: "bamboo",
	inciensos: "incense",
	"consolas metal": "metal consoles",
	"bandejas metal redondas": "round metal trays",
	"boligrafos para oficina": "office pens",
	cuadernos: "notebooks",
	bolsos: "handbags",
	"notas adhesivas": "adhesive notes",
	tableros: "boards",
	"crucifijos pared / mesa": "wall / table crucifixes",
	"angel de la guarda": "Guardian angel",
	"santisima trinidad": "Holy Trinity",
	"santas cajas 12 pzs country": "santas boxes 12 pcs country",
	guadalupe: "guadeloupe",
	"mesas auxiliares metalicas": "metal side tables",
	"luces led de 10 o menos": "led lights of 10 or less",
	"san antonio": "san antonio",
	"recuperacion y terapia": "recovery and therapy",
	"gomas y pegamentos escolares": "school rubbers and glues",
	"piedras decorativas": "decorative stones",
	"perforadoras para oficina": "office drills",
	"sacapuntas escolares": "school pencil sharpener",
	"otros muñecos - country": "other dolls - country",
	autos: "cars",
	"tijeras para oficina": "office scissors",
	coronas: "crowns",
	"copas sin tipo": "glasses without type",
	"divino niño": "divine child",
	"pintura escolar y accesorios": "school paint and accessories",
	"gomas y pegamentos para manualidades": "rubbers and glues for crafts",
	"acc arbol": "acc tree",
	mapas: "maps",
	"santas con musica": "santas with music",
	"consolas vidrio": "glass consoles",
	"textil infantil": "children&#39;s textile",
	toys: "toys",
	"bandejas decorativas": "decorative trays",
	"san judas / judas tadeo": "san judas / judas tadeo",
	"del carmen": "from Carmen",
	tintas: "paints",
	"copa para champaña": "champagne glass",
	"set de placas": "set of plates",
	mochilas: "backpacks",
	"maria auxiliadora": "Mary helper",
	"exactos y guillotinas": "exact and guillotines",
	confeti: "confetti",
	cajas: "boxes",
	"juegos de bar": "bar games",
	"mama santa-country": "mama santa-country",
	"material de marketing": "marketing material",
	engrapadoras: "engrapadoras",
	"cocina nautica": "nautical kitchen",
	"cara de santa": "santa face",
	"huevos decorativos": "decorative eggs",
	ficheros: "files",
	foco: "focus",
	"copa para vino blanco": "glass for white wine",
	revisteros: "magazine racks",
	"referencias sin foto hogar": "references without home photo",
	"rosarios y denarios": "rosaries and denarii",
	geometría: "geometry",
	"arboles decor": "decor trees",
	"jg de mesas metalicas": "jg of metal tables",
	"faldas country": "country skirts",
	"sets escolares": "school sets",
	rosas: "roses",
	"muebles navideños": "christmas furniture",
	"perpetuo socorro": "perpetual help",
	"angel fibra optica": "angel fiber optics",
	crayones: "crayons",
	candeleros: "candlesticks",
	"cuadros con marco": "frames with frame",
	"jesus misericordioso / divina misericordia":
		"merciful jesus / divine mercy",
	"arcangel gabriel": "archangel Gabriel",
	cuadros: "paintings",
	"copa para licor": "cup for liquor",
	"sagrado corazon de maria": "sacred heart of maria",
	querubines: "cherubs",
	"de la dulce espera": "from the sweet wait",
	"bomboneras y pastilleros": "candy boxes and pill boxes",
	"relojes y tracker": "watches and tracker",
	"mesas auxiliares madera": "wooden side tables",
	"angeles acrilicos": "acrylic angels",
	plantillas: "templates",
	botellas: "bottles",
	"bases para potes": "bases for pots",
	"imagenes para pintar": "pictures to paint",
	"sin foto": "without picture",
	lupas: "magnifying glass",
	"arcagel rafael": "arcagel rafael",
	"bolso de playa": "Beach bag",
	"cajas de regalo": "Gift boxes",
	"pinceles escolares": "school brushes",
	"tijeras escolares": "school scissors",
	"potes soporte metal": "metal stand pots",
	"plantas colgantes": "hanging plants",
	"ensaladeras/bowl": "salad bowls / bowl",
	"cristo resucitado": "Resurrected Christ",
	"potes y maceteros": "pots and planters",
	"santa ana": "santa ana",
	mugs: "mugs",
	"santa teresa": "Santa Teresa",
	"infante de praga": "infant of prague",
	"correctores para oficina": "correctors for office",
	"potes metal": "you can metal",
	albumes: "albums",
	espejos: "Mirrors",
	"otras bolas": "other balls",
	desatanudos: "untie",
	"san francisco": "san francisco",
	"renos con luz": "reindeer with light",
	"de lourdes": "heavy",
	nieve: "snow",
	"sillas bar": "bar chairs",
	"portavela 1 vela metal": "candle holder 1 metal candle",
	"juego de mesas": "board game",
	"vestidos y faldas": "dresses and skirts",
	"consolas acero inoxidable": "stainless steel consoles",
	"san / sao expedito": "san / san expedito",
	estrellas: "stars",
	aves: "birds",
	"santa marta": "Santa Marta",
	"set de licoreras y cokteleras": "set of liquors and cocktail shakers",
	"caminos de mesa country": "country table runners",
	"silicona liquida": "liquid silicone",
	bandejas: "trays",
	"placas virgenes": "virgin plates",
	"santas poliresina": "santas polish",
	"aguas benditas": "holy waters",
	"otras escenas": "other scenes",
	"santa- country": "santa- country",
	"baño nautico": "nautical bathroom",
	"cascanueces - country": "nutcracker - country",
	"basculas digitales": "digital scales",
	"espiritu santo": "Holy Spirit",
	"padre pio (santo)": "father pio (saint)",
	"dispensador de jabón": "soap dispenser",
	"rosa mistica": "mystical rose",
	"fuentes con lampara/ luz": "fountains with lamp / light",
	"placas sagrada familia": "holy family plates",
	"de vidrio": "of glass",
	"santa eduviges": "santa eduviges",
	"taburete individual": "individual stool",
	"plastilina escolar": "school plasticine",
	"tijeras para manualidades": "craft scissors",
	"barras de silicona": "silicone rods",
	"utensilios de cocina": "Cookware",
	"juego 7 arcangeles": "game 7 archangels",
	"accesorios para escritorio": "desk accessories",
	"luces led otros tamaños": "led lights other sizes",
	escaleras: "ladders",
	"jesus nazareno": "jesus nazarene",
	decanters: "decanters",
	cupulas: "domes",
	"jgs de bano": "jgs de bano",
	"platos ceramica": "ceramic plates",
	"san pancracio": "san pancracio",
	"de las 3 rosas": "of the 3 roses",
	"san benito": "San Benito",
	"caridad de cobre": "copper charity",
	"material marketing": "material marketing",
	"santas con accesorios country": "santas with country accessories",
	calculadoras: "calculators",
	"reyes magos": "wise men",
	"material de cortar y troquelar": "cut and die material",
	"luces led de 20": "20 led lights",
	"osos- country": "osos- country",
	"velas aromaticas": "velas aromaticas",
	"otros santas": "other saints",
	"candados y cierres de seguridad": "padlocks and security locks",
	"picks flores": "picks flores",
	latas: "cans",
	"porta vasos": "pot holder",
	"calculadoras escolares": "school calculators",
	"ultima cena": "Last Supper",
	"bandejas metal rectangulares": "rectangular metal trays",
	"bases para pastel": "Cake bases",
	"del rosario": "Rosary",
	promocionales: "promotional",
	"bandeja decor": "decor tray",
	"lampara mesa base ceramica": "ceramic base table lamp",
	enterizos: "enterizos",
	"angeles- country": "angeles- country",
	"virgen medalla milagrosa": "virgin miraculous medal",
	"calendarios colgantes": "hanging calendars",
	"descansa cucharas": "rest spoons",
	sofa: "sofa",
	loncheras: "loncheras",
	"santas acrilicos": "acrylic saints",
	"porta retrato 8x10": "8x10 picture frame",
	"angeles decorativos": "decorative angels",
	"bola decorativa": "decorative ball",
	"textil exterior nautico": "nautical exterior textile",
	"reloj pared plastico": "plastic wall clock",
	"12plg": "12plg",
	"juego 6 piezas": "set 6 pieces",
	tecnologia: "technology",
	"16plg": "16plg",
	"24plg": "24plg",
	"san juan bautista": "Saint John Baptist",
	"5plg": "5plg",
	"de la salud": "Of the health",
	jaulas: "cages",
	"calendarios country": "country calendars",
	"8plg": "8plg",
	"otros niños": "Other children",
	"santa familia acrilica": "holy acrylic family",
	"santa rita": "saint rita",
	"florero vidrio -25 cm": "glass vase -25 cm",
	joyeros: "jewelers",
	"santa lucia": "Saint Lucia",
	"san juan bosco": "san juan bosco",
	"de chiquinquira": "from chiquinquira",
	"funda p/cojin-country": "country cushion cover",
	"flores y follaje": "flowers and foliage",
	"niña maria": "girl maria",
	"placas angeles": "angels license plates",
	"jg de mesas madera": "jg of wooden tables",
	"articulos de cuentas": "account items",
	"niño/niña- country": "boy / girl- country",
	"san lazaro": "Saint Lazarus",
	"santa librada": "holy freed",
	fondue: "fondue",
	"san jorge": "Saint George",
	"piks y entremeseros": "piks and entremeseros",
	magnetos: "magnets",
	"soportes / atriles": "stands / music stands",
	"santo niiño de atocha": "holy child of atocha",
	"otros textiles country": "other country textiles",
	"nuestra señora aparecida / la aparecida":
		"our lady appeared / the appeared",
	"de la merced/con esposas/con niños":
		"de la merced / with handcuffs / with children",
	"virgen sagrado corazon de maria": "Virgin Sacred Heart of Mary",
	vinchas: "vinchas",
	"san gregorio hernandez": "san gregorio hernandez",
	"otras placas": "other plates",
	difusores: "diffusers",
	sofas: "sofas",
	"san cristobal": "San Cristobal",
	exhibidores: "exhibitors",
	"juego de velas": "candle set",
	"mesas para jardin": "garden tables",
	"bandejas metal ovaladas": "oval metal trays",
	tizas: "chalks",
	"san judas": "St. Jude",
	"pistolas de silicona": "silicone guns",
	"divina pastora": "divine shepherdess",
	"lampara mesa base vidrio": "glass base table lamp",
	"esferas decorativas madera": "wooden decorative spheres",
	"virgen de guadalupe": "Guadalupe&#39;s Virgin",
	"placas jesus": "jesus plates",
	"dispensadores de jabon": "soap dispensers",
	conos: "cones",
	relojes: "clocks",
	"buen pastor": "good Shepherd",
	tapacorcho: "corkscrew",
	"mesas auxiliares otras": "auxiliary tables other",
	"lampara de pared": "Wall lamp",
	"country colgante": "country pendant",
	campanas: "bells",
	"floreros de ceramica": "ceramic vases",
	"grama artificial": "artificial grass",
	"snowman acrilico": "acrylic snowman",
	"florero vidrio 33 a 40 cm": "glass vase 33 to 40 cm",
	"portaretratos 3x3": "picture frames 3x3",
	"virgen del carmen": "virgin of carmen",
	"consolas madera": "wooden consoles",
	"virgen mistica 3 rosas": "mystic virgin 3 roses",
	"guirnaldas y coronas con luz": "garlands and wreaths with light",
	ramas: "remaining",
	"san cayetano": "San Cayetano",
	"la candelaria": "candelaria",
	"correctores escolares": "school correctors",
	"bandejas metal cuadradas": "square metal trays",
	"set espejos redondos": "round mirrors set",
	"porta retrato 4x6": "4x6 picture frame",
	vasos: "cups",
	"relojes country": "country watches",
	"san martin de porres": "San Martin de Porres",
	"cascanueces lata": "nutcracker can",
	"jesus divina misericordia": "jesus divine mercy",
	"baño infantil": "children&#39;s bathroom",
	letras: "letters",
	pastores: "shepherds",
	"marcadores para oficina": "bookmarks for office",
	"el calvario": "the Calvary",
	"bolas 60mm": "balls 60mm",
	"otros animales": "Other animals",
	manzanas: "apples",
	"cuadros y placas": "pictures and plates",
	"caminos tela": "cloth roads",
	"resaltadores para oficiina": "office highlighters",
	"la anunciacion": "the annunciation",
	"recamara nautica": "nautical bedroom",
	"carritos de servir negros": "black serving carts",
	fruteros: "fruit bowls",
	"acc para cerveza": "acc for beer",
	"accesorios escolares": "school accessories",
	"san charbel": "san charbel",
	"snowman con luz": "snowman with light",
	"cuidado de la salud": "health care",
	"santo niño de atocha": "holy child of atocha",
	"surtidos- country": "assorted- country",
	"otros angeles": "other angels",
	"virgen de la inmaculada": "virgin of the immaculate",
	"lampara mesa base metal": "metal base table lamp",
	"de coromoto": "by coromoto",
	"relojes de piso": "floor clocks",
	"jesus y angel": "jesus y angel",
	"lging 7/8 sculpt": "lging 7/8 sculpt",
	"lging seamless": "lging seamless",
	"milagroso de buga": "miraculous of buga",
	"referencias sin foto religioso": "references without religious photo",
	delfines: "dolphins",
	"nac 5plg a 7plg": "nac 5plg a 7plg",
	"otros cristo": "other christ",
	"potes ceramica": "you can ceramica",
	"maria la antigua": "old maria",
	"martin caballero": "martin knight",
	"canvas 80x80": "canvas 80x80",
	"abrazo de francisco": "Francisco hug",
	"set cuadros cuadrados": "square frames set",
	"lampara de aceite": "oil lamp",
	"san sebastian": "san sebastian",
	"corona 18plg": "crown 18plg",
	"bandejas madera cuadradas": "square wooden trays",
	"porta retrato 5x7": "5x7 picture frame",
	"jacket athleisure": "jacket athleisure",
	"virgen de lourdes": "virgin of lourdes",
	"bebe jesus": "baby jesus",
	ceniceros: "ashtrays",
	mascotas: "pets",
	"cascanueces acrilicos": "acrylic nutcracker",
	gallinas: "chickens",
	"aceiteras y vinagreras": "oil cans and cruet",
	"la dolorosa": "the hurt",
	"virgen maria auxiliadora": "virgin mary helper",
	"niño jesus pastor": "child jesus shepherd",
	"juegos de shots": "shots games",
	"jarras y termos": "jugs and thermos",
	"esferas decorativas metal": "metal decorative spheres",
	"adornos de cristal": "crystal ornaments",
	"copos de nieve": "snowflakes",
	"sacapuntas para oficina": "pencil sharpener for office",
	"relojes surtidos": "assorted watches",
	plastilina: "clay",
	"bolas +100mm": "balls + 100mm",
	"placas niño jesus": "baby jesus plates",
	"platos cuadrados y rectangulares": "square and rectangular plates",
	"bandejas resina rectangulares": "rectangular resin trays",
	"el justo juez": "the just judge",
	"arcangel chamuel": "archangel chamuel",
	"placas sagrado corazon de jesus": "plates sacred heart of jesus",
	"san expedito": "San expedito",
	"florero vidrio 25 a 28 cm": "glass vase 25 to 28 cm",
	elfos: "elves",
	"cojin 45 x 45 cm": "cojin 45 x 45 cm",
	"florero ceramica 33 a 40 cm": "ceramic vase 33 to 40 cm",
	perros: "dogs",
	"escenas nacimientos": "birth scenes",
	"de la providencia": "of providence",
	"virgen del perpetuo socorro": "virgin of perpetual help",
	osos: "Bears",
	"figuras surtidas": "assorted figures",
	servilletero: "napkin holder",
	"porta ret otras medidas": "porta ret other measures",
	"luces led de 50": "50 led lights",
	"platos redondos y ovalados": "round and oval plates",
	"santa barbara": "santa barbara",
	"libros con oracion": "prayer books",
	"de la ofrenda": "of the offering",
	"relojes de pared country": "country wall clocks",
	"florero vidrio 29 a 32 cm": "glass vase 29 to 32 cm",
	"accesorios para regalo": "gift accessories",
	tarjeteros: "card holders",
	licoreras: "liquor stores",
	"entrada a jerusalen": "entrance to jerusalem",
	especieros: "spices",
	"san juan diego": "san juan diego",
	"cintas 6 cm": "ribbons 6 cm",
	"juego de copas": "Drinking Game",
	"trenes resina/ceramica": "resin / ceramic trains",
	"candeleros juego": "candlesticks game",
	"niña maria / con libro/con rosario":
		"girl maria / with book / with rosary",
	"para ti": "For You",
	"mejores vendidos": "Best Sellers",
	"visto recientemente": "Recently Viewed",
	"15 a 30 dias": "15 to 30 Days",
	"15 dias": "15 Days",
	"30 a 45 dias": "30 to 45 Days",
	"45 a 60 dias": "45 to 60 Days",
	"60 a 90 dias": "60 to 90 Days",
	"90 a 120 dias": "90 to 120 Days",
	"sub categoria": "sub category",
	arbustos: "bushes",
	"asiento metal": "metal seat",
	"asiento plastico": "plastic seat",
	"asiento tapizado": "upholstered seat",
	"bandejas plasticas": "plastic trays",
	"cajas decorativas": "decorative boxes",
	"canastas decorativas": "decorative baskets",
	circulos: "circles",
	"cojin 30x50 cm": "cushion 30x50 cm",
	"cojin 45x45 cm": "cushion 45x45 cm",
	"cojin 50x50 cm": "cushion 50x50 cm",
	"cojin redondo": "round cushion",
	colgadores: "hangers",
	eucalipto: "eucalyptus",
	helechos: "ferns",
	hojas: "leaves",
	hortensias: "hydrangeas",
	jacintos: "hyacinths",
	"mano de tigre": "tiger claw",
	mantas: "blankets",
	"mesas de comedor": "dining tables",
	"mueble para tv": "tv stand",
	nudos: "knots",
	"otras formas espejos": "other shapes mirrors",
	"platos deco ceramica": "ceramic decorative plates",
	"platos deco resina": "resin decorative plates",
	portavasos: "coasters",
	ramos: "bouquets",
	sabila: "aloe vera",
	"sillas bar metal": "metal bar stools",
	"sillas bar plasticas": "plastic bar stools",
	"sillas bar tapizadas": "upholstered bar stools",
	sofás: "sofas",
	"sujetador de libros": "bookends",
	"velas aromaticas": "aromatic candles",
	"velas con luz": "led candles",
	"centro de mesa": "centerpiece",
	"centros de mesa": "centerpieces",
	comedor: "dining room",
	"esferas decorativas": "decorative spheres",
	floreros: "vases",
	jardin: "garden",
	"muebles sala": "living room furniture",
	"muebles tapizados": "upholstered furniture",
	"otros adornos sala": "other living room decorations",
	placas: "plaques",
	"plantas decorativas sala": "decorative plants for the living room",
	portaretratos: "picture frames",
	"potes decorativos sala": "decorative pots for the living room",
	recamara: "bedroom",
	regalos: "gifts",
	"textil sala": "living room textiles",
	margaritas: "daisies",
	descuento: "discount",
	"estilo de vida": "lifestyles",
	bohemio: "bohemian",
	categorias: "categories",
	contemporaneo: "contemporary",
	contemporaeo: "contemporary",
	etnico: "ethnic",
	industrial: "industrial",
	"mid century": "mid century",
	natural: "natural",
	naturall: "natural",
	nautico: "nautical",
	"neo clasico": "neo-classic",
	"neo clasicoo": "neo-classic",
	"rustico glam": "rustic glam",
};
