import React, { useState } from "react";

import { Title } from "../../sdk/Layout";
import { Button, Buttons, Icon } from "../../sdk/Button";
import MovilLink from "./Components/MovilLink";
import Icons from "./Components/Icons";
import IconsLibrary from "../Icons2";
import InternalLink from "../InternalLink";
import {
    Logo,
    Nav,
    NavHeader,
    Close,
    NavBody,
    Divisor,
    Tags,
    Tag,
    BackDrop,
    Wrapper,
    Back,
} from "./style";

const Hamburger = ({
    isActive,
    toggleMenu,
    config,
    checkTags,
    user,
    lang,
    jsonSettings,
    title,
    isLoggedIn,
    _t,
}) => {
    const hasCategory = false;

    const [menuIndexOpen, setMenuIndexOpen] = React.useState(null);
    const tags = user ? user.tags : [];
    const [menuIndexThirdLevelOpen, setMenuIndexThirdLevelOpen] =
        React.useState(null);

    const onShowMenuIndex = (e) => {
        e.preventDefault();
        setMenuIndexOpen(parseInt(e.target.getAttribute("data-index")));
    };

    const onSetThirdMenu = (e) => {
        e.preventDefault();
        // e.stopImmediatePropagation();
        setMenuIndexThirdLevelOpen(
            parseInt(e.target.getAttribute("data-index"))
        );
    };

    const backButton = () => {
        if (menuIndexThirdLevelOpen != null) {
            setMenuIndexThirdLevelOpen(null);
            return;
        }
        setMenuIndexOpen(null);
    };

    const menuToMap =
        menuIndexOpen != null
            ? menuIndexThirdLevelOpen != null
                ? config[menuIndexOpen].children[menuIndexThirdLevelOpen]
                : config[menuIndexOpen]
            : config;

    const menuTitle =
        menuIndexOpen != null
            ? menuIndexThirdLevelOpen != null
                ? config[menuIndexOpen].children[menuIndexThirdLevelOpen].title
                : config[menuIndexOpen].title
            : title;

    const buildHeaderMovil = () => {
        if (config == null) return;

        const child = menuToMap.children ? menuToMap.children : menuToMap;

        const header = child.map((index, i) => {
            const title = index.title.toLowerCase();
            if (!checkTags(title, tags, jsonSettings, user)) return null;

            if (title === "action items") return null;

            if (index.hide) return null;

            if (index.children == null || index.children.length === 0) {
                return (
                    <li key={index.title + "header" + i}>
                        <InternalLink {...index}>{index.title}</InternalLink>
                    </li>
                );
            }
            // if (tags != null && tags.indexOf(indexOf.title) < 0) return null
            return (
                <MovilLink
                    {...index}
                    lang={lang}
                    key={index.title + "header" + i}
                    index={i}
                    onClick={
                        menuIndexOpen != null ? onSetThirdMenu : onShowMenuIndex
                    }
                />
            );
        });

        if (menuIndexOpen != null) {
            header.push(
                <li key={"last-header"}>
                    <InternalLink {...menuToMap}>{_t("all")}</InternalLink>
                </li>
            );
        }
        return header;
    };

    const actionItems = config.filter(
        (index) => index.title.toLowerCase() === "action items"
    );

    // if (!isActive) return null;
    return (
        <>
            {/* <Icon>
                <Icons icon="bars"></Icons>
            </Icon> */}
            <BackDrop onClick={toggleMenu} isActive={isActive} />

            <Nav
                className={isActive ? "open" : ""}
                subLevelOpen={menuIndexOpen != null}
            >
                <div>
                    <NavHeader>
                        <Logo>
                            <InternalLink
                                to={isLoggedIn ? "/home-login/" : "/"}
                            >
                                <IconsLibrary icon="ntx-symbol"></IconsLibrary>
                            </InternalLink>
                        </Logo>
                        <Close onClick={toggleMenu}>
                            <IconsLibrary icon="close"></IconsLibrary>
                        </Close>
                    </NavHeader>

                    <NavBody>
                        <div className="menu-level">
                            <Title
                                className="hide-on-movil-open"
                                size="6"
                                as="h2"
                            >
                                Noritex.com
                            </Title>
                            <ul className="hide-on-movil-open">
                                {actionItems != null &&
                                actionItems.length > 0 &&
                                actionItems[0].children.length > 0
                                    ? actionItems[0].children.map(
                                          (index, i) => {
                                              const spl =
                                                  index.title.split("-");
                                              const title =
                                                  index.title.toLowerCase();

                                              if (
                                                  !checkTags(
                                                      title,
                                                      tags,
                                                      jsonSettings,
                                                      user
                                                  )
                                              ) {
                                                  return null;
                                              }
                                              return (
                                                  <li key={`action-item${i}`}>
                                                      <InternalLink {...index}>
                                                          <Icons
                                                              icon={
                                                                  spl[1]
                                                                      ? spl[1].trim()
                                                                      : ""
                                                              }
                                                          ></Icons>
                                                          {spl[0]}
                                                      </InternalLink>
                                                  </li>
                                              );
                                          }
                                      )
                                    : null}
                            </ul>
                            {/* <Divisor className="hide-on-movil-open"></Divisor> */}
                            {/* {!hasCategory && (
                                <div className="has-text-centered hide-on-movil-open">
                                    <Title size="7" as="h4">
                                        Personaliza tu experiencia
                                    </Title>
                                    <Buttons isCentered>
                                        <Button small>Comenzar</Button>
                                    </Buttons>
                                </div>
                            )} */}
                            {hasCategory && (
                                <>
                                    <Title
                                        className="hide-on-movil-open"
                                        size="7"
                                        as="h4"
                                    >
                                        Tus categorías
                                    </Title>
                                    <Tags className="hide-on-movil-open">
                                        <Tag>Category</Tag>
                                        <Tag>Tag2</Tag>
                                        <Tag>Tag3</Tag>
                                        <Tag>Tag4</Tag>
                                        <Tag>Tag5</Tag>
                                    </Tags>
                                </>
                            )}
                            <Divisor className="hide-on-movil-open"></Divisor>
                            {menuIndexOpen != null ? (
                                <>
                                    <Back onClick={backButton}>
                                        <Icons icon="chevron-left"></Icons>
                                        {_t("back")}
                                    </Back>
                                    <Divisor></Divisor>
                                </>
                            ) : (
                                <div className="menu-header">
                                    <Title size="6" as="h2">
                                        {_t("departments")}
                                    </Title>{" "}
                                    <InternalLink to="/all-departments">
                                        {_t("all")}
                                    </InternalLink>
                                </div>
                            )}
                            <ul>{buildHeaderMovil()}</ul>
                        </div>

                        {/* <div className="menu-level">
                                <Back >
                                    <Icons icon="chevron-left"></Icons>
                                    Volver
                                </Back>
                                <Divisor></Divisor>
                                <Title size="6" as="h4">
                                    Hogar
                                </Title>
                                <ul>
                                    <li className="has-children">
                                        <a href="">Colecciones</a>
                                    </li>
                                    <li className="has-children">
                                        <a href="">Sala</a>
                                    </li>
                                    <li className="has-children">
                                        <a href="">Comedor</a>
                                    </li>
                                    <li className="has-children">
                                        <a href="">Jardín</a>
                                    </li>
                                    <li className="has-children">
                                        <a href="">Otras categorías</a>
                                    </li>
                                </ul>
                            </div> */}
                    </NavBody>
                </div>
            </Nav>
        </>
    );
};

export default React.memo(Hamburger);
