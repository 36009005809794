import styled from "styled-components";

const Section = styled.div`
    padding: 3% 0;
    content-visibility: auto;
    ${(props) => (props.gray ? "background-color: whitesmoke;" : "")};
`;

const SectionTopSection = styled.div`
    max-width: 600px;
`;

export const SectionHeader = styled.div`
    padding-bottom: 20px;
    p {
        max-width: 600px;
        width: 90%;
        font-size: 15px;
        line-height: 22px;
        color: ${props => props.theme.primaryDark}
    }
    ${(props) =>
        props.centered
            ? `
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		.section-subtitle:before {
			left: 50%;
			margin-left: -25px;
		}
	`
            : ""}

    @media (max-width: 768px) {
        p {
            font-size: 13px;
            line-height: 18px;
        }
    }
`;

export const SectionTitle = styled.h1`
    margin-bottom: 10px;
    text-transform: uppercase;
    font-size: 22px;
    color: #484848;
    position: relative;
    padding-left: 15px;
    &:before {
        content: "";
        width: 10px;
        height: 10px;
        background-color: #112c55;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -5px;
    }
    @media (max-width: 768px) {
        font-size: 16px;
    }
`;

export const SectionSubtitle = styled.h2`
    max-width: 600px;

    font-size: 22px;
    color: ${props => props.theme.primaryDark};
    padding-bottom: 10px;
    margin-bottom: 10px;
    position: relative;

    &:before {
        content: "";
        width: 50px;
        height: 1px;
        background-color: ${props => props.theme.primaryDarkMode};
        position: absolute;
        bottom: 0;
        left: 0;
    }

    ${(props) =>
        props.centered
            ? `
		&:before {
			left: 50%;
			margin-left: -25px;
		}
	`
            : ""}

    @media (max-width: 768px) {
        font-size: 16px;
    }
`;

export default Section;
