import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Title, Subtitle, Box, Columns, Column } from "../../sdk/Layout";
import { Modal, Preloader } from "../../sdk/Elements";
import { Buttons, Button } from "../../sdk/Button";
import Icons from "../../homeLogin2/components/Icons";
import Blueberry from "../../../blueberry";
import { useTranslation } from "../../../lang";

import {
    Card,
    Wrap,
    Skip,
    Image,
    Footer,
    Logo,
    ToggleBtn,
    ToggleBtns,
    Back,
    Ico,
} from "./style";

const Onboarding = ({ user }) => {
    const [_t, lang] = useTranslation();
    const [currentOption, setCurrentOption] = useState(0);

    const [isOnboardingDone, setIsOnboardingDone] = React.useState(() => {
        if (user.didTutorial == null || !user.didTutorial) return true;
        const isOnboarding = window.localStorage.getItem("didOnboarding");

        if (isOnboarding === "true" || isOnboarding || user.didOnboarding)
            return true;
        return false;
    });
    const [menuConfig, setMenuConfig] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const [answers, setAnswers] = React.useState([[""]]);
    const handleNextItem = () => {
        setCurrentOption((prev) => {
            if (
                prev > 0 &&
                (answers[prev] == null || answers[prev].length === 0)
            )
                return prev;

            return prev + 1;
        });
    };
    const handlePrevItem = () => {
        setCurrentOption((prev) => prev - 1);
    };

    const handleAnswer = (e) => {
        const name = e.target.getAttribute("data-name");
        const value = e.target.getAttribute("data-value");
        setAnswers((prev) => {
            let element = prev[parseInt(name)];
            if (element == null) {
                element = [];
            }
            if (element.some((index) => value === index)) {
                element = element.filter((index) => value !== index);
            } else {
                element.push(value);
            }

            const clonePrev = [...prev];
            clonePrev[parseInt(name)] = element;
            return clonePrev;
        });
    };

    const handleDataSubmit = () => {
        Blueberry.didOnboarding({ answers })
            .then((resp) => {
                console.log(resp);
                window.localStorage.setItem("didOnboarding", true);
                if (user) user.answers = answers;
                setIsOnboardingDone(true);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const menuToMap =
        menuConfig && menuConfig.menu.length > 3 ? menuConfig.menu : null;

    React.useEffect(() => {
        if (!isOnboardingDone) {
            setIsLoading(true);
            Blueberry.menu(`onboarding-${lang}`)
                .then((resp) => {
                    setMenuConfig(resp);
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                });
        }
    }, []);

    if (
        isLoading ||
        isOnboardingDone ||
        user == null ||
        user.tags == null ||
        user.tags.indexOf("hogar") <= -1
    )
        return null;
    if (isLoading) {
        return (
            <Card className="is-active">
                <span></span>
                <div>
                    <Wrap>
                        <Logo>
                            <Icons icon="logotipo"></Icons>
                        </Logo>
                        <Preloader square />
                        <Preloader />
                    </Wrap>
                </div>
            </Card>
        );
    }

    if (menuConfig == null || menuToMap == null) return null;

    return (
        <>
            <Card className="is-active">
                <span></span>
                <div>
                    <Wrap>
                        {currentOption > 0 && currentOption != 4 && (
                            <Back onClick={() => handlePrevItem()}>
                                <Icons icon="arrow-left"></Icons>
                            </Back>
                        )}
                        <Logo>
                            <Icons icon="logotipo"></Icons>
                        </Logo>

                        {menuToMap.map((index, i) => {
                            if (currentOption !== i) return null;

                            return (
                                <div key={`page-${i}`}>
                                    <Title
                                        size="6"
                                        as="h3"
                                        {...(i === 0 ||
                                        i === menuToMap.length - 1
                                            ? {
                                                  size: "4",
                                                  as: "h2",
                                                  isCentered: true,
                                                  primary: true,
                                              }
                                            : {
                                                  size: 6,
                                                  as: "h3",
                                                  isCentered: false,
                                                  primary: false,
                                              })}
                                    >
                                        {index.title}
                                    </Title>
                                    {i === 0 ? (
                                        <Ico>
                                            <Icons icon="customize"></Icons>
                                        </Ico>
                                    ) : null}
                                    {i === menuToMap.length - 1 ? (
                                        <Ico>
                                            <Icons icon="approved"></Icons>
                                        </Ico>
                                    ) : null}
                                    {index.children.length === 1 ? (
                                        <Subtitle
                                            as="h3"
                                            size="6"
                                            isCentered
                                            dangerouslySetInnerHTML={{
                                                __html: index.children[0].title,
                                            }}
                                        >
                                            {}
                                        </Subtitle>
                                    ) : index.children &&
                                      index.children.length > 0 &&
                                      index.children[0].images &&
                                      index.children[0].images.length > 0 ? (
                                        <Columns>
                                            {index.children.map((option, j) => (
                                                <Column key={`column${i}-${j}`}>
                                                    <Image>
                                                        <img
                                                            src={
                                                                option.images &&
                                                                option.images
                                                                    .length > 0
                                                                    ? option
                                                                          .images[0]
                                                                    : ""
                                                            }
                                                            alt={option.title}
                                                        />
                                                    </Image>
                                                    <ToggleBtn
                                                        selected={
                                                            answers[i] &&
                                                            answers[i].indexOf(
                                                                option.title
                                                            ) > -1
                                                        }
                                                        onClick={handleAnswer}
                                                        data-name={i}
                                                        data-value={
                                                            option.title
                                                        }
                                                    >
                                                        {option.title}
                                                    </ToggleBtn>
                                                    <p>{option.tag}</p>
                                                </Column>
                                            ))}
                                        </Columns>
                                    ) : (
                                        <ToggleBtns>
                                            {index.children.map((option, j) => (
                                                <ToggleBtn
                                                    selected={
                                                        answers[i] &&
                                                        answers[i].indexOf(
                                                            option.title
                                                        ) > -1
                                                    }
                                                    onClick={handleAnswer}
                                                    data-name={i}
                                                    data-value={option.title}
                                                    key={`option${i}-${j}`}
                                                >
                                                    {option.title}
                                                </ToggleBtn>
                                            ))}
                                        </ToggleBtns>
                                    )}
                                </div>
                            );
                        })}

                        <Footer>
                            <Buttons isCentered>
                                {currentOption != 0 && currentOption != 4 && (
                                    <Button
                                        primary
                                        onClick={() => handleNextItem()}
                                    >
                                        {_t("continue")}
                                    </Button>
                                )}
                                {currentOption === 0 && (
                                    <Button
                                        primary
                                        onClick={() => handleNextItem()}
                                    >
                                        {_t("begin")}
                                    </Button>
                                )}
                                {currentOption === menuToMap.length - 1 && (
                                    <Button onClick={handleDataSubmit} primary>
                                        {_t("done")}
                                    </Button>
                                )}
                            </Buttons>
                        </Footer>
                    </Wrap>
                    {currentOption != 4 && <Skip>{_t("skip")}</Skip>}
                </div>
            </Card>
        </>
    );
};

export default Onboarding;
