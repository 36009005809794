import React from "react";

export default function Navicon({ selected }) {
  return (
    <div className={` hamburger ${selected ? "active" : ""}`}>
      <span className="toggle-icon--bar toggle-icon--bar-top"></span>
      <span className="toggle-icon--bar toggle-icon--bar-middle"></span>
      <span className="toggle-icon--bar toggle-icon--bar-bottom"></span>
    </div>
  );
}
