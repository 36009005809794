export const tags_pt = {
    Disponibilidad: "Disponibilidade",
    disponibilidad: "Disponibilidade",
    Inmediato: "Agora mesmo",
    inmediato: "Agora mesmo",
    Futuro: "Futuro",
    futuro: "Futuro",
    puerto: "porta",
    feria: "feira",
    colecciones: "coleções",
    Transito: "Transito",
    transito: "Transito",
    categoria: "categoria",
    "flores y plantas": "flores e plantas",
    color: "cor",
    gris: "cinza",
    amarillo: "amarelo",
    chocolate: "chocolate",
    rojo: "vermelho",
    bolas: "bolas",
    "floreros ceramica": "vasos de cerâmica",
    cojines: "almofadas",
    lila: "roxa",
    "portavela 1 vela": "castiçal 1 vela",
    verde: "verde",
    "accesorios mesa": "acessórios de mesa",
    "floreros vidrio": "vasos de vidro",
    "personas figuras": "figuras de pessoas",
    azul: "azul",
    flores: "flores",
    "otras figuras": "outras figuras",
    "arbol decorativo": "árvore decorativa",
    "rojo 2": "vermelho 2",
    portavelas: "candelabro",
    santas: "papai noel",
    marcos: "marcos",
    crema: "creme",
    "mugs y tazas": "canecas e copos",
    velas: "velas",
    "otras plantas": "outras plantas",
    "animales figuras": "figuras de animais",
    "relojes de pared": "Relógios de parede",
    "cojines y fundas": "almofadas e capas",
    animales: "animais",
    "otros colgantes": "outros pingentes",
    "figuras country": "números do país",
    canvas: "tela",
    "": "",
    marron: "fugir",
    "aves figuras": "figuras de pássaros",
    renos: "rena",
    "frutas figuras": "figuras de frutas",
    "elefantes figuras": "figuras de elefantes",
    "potes con pie": "vasos com pé",
    "portaretratos 4x6": "portaretratos 4x6",
    "portaretratos 5x7": "portaretratos 5x7",
    sillas: "cadeiras",
    "lampara de mesa": "lampada de mesa",
    "potes sin pie": "vasos sem pés",
    portaplatos: "Prateleira",
    "portaretratos 8x10": "portaretratos 8x10",
    "accesorios de mesa": "acessórios de mesa",
    cortinas: "cortinas",
    turquesa: "turquesa",
    "textil mesa": "mesa têxtil",
    "coronas y guirnaldas": "coroas e guirlandas",
    "acc recamara": "acc quarto",
    "acc bar": "barra de acesso",
    alfombras: "tapetes",
    angeles: "anjos",
    "mesas auxiliares": "tabelas auxiliares",
    "articulos de fomi": "artigos fomi",
    "acc baño": "banheiro",
    rosado: "rosado",
    figuras: "figuras",
    "santas country": "país do papai noel",
    envases: "embalagem",
    "accesorios sala": "acessórios para sala",
    "espejo redondo": "espelho redondo",
    "platos decorativos": "pratos decorativos",
    "otros navideños": "outro Natal",
    "centro mesa vidrio": "mesa de centro de vidro",
    "portavelas daily": "portavelas daily",
    "accesorios cocina": "acessórios de cozinha",
    "placas de pared": "placas de parede",
    cintas: "fitas",
    organizadores: "organizadores",
    "caminos de mesa": "caminhos de mesa",
    "textil navidad country": "têxteis do país de natal",
    funda: "bainha",
    villas: "vilas",
    manteleria: "toalhas de mesa",
    "figuras nacimientos": "figuras nascimentos",
    "juego de nacimientos": "jogo de nascimento",
    "bolsas de regalo": "sacos de presente",
    linternas: "lanternas",
    bar: "bar",
    taburetes: "fezes",
    picks: "picaretas",
    individuales: "Individual",
    "vajillas por piezas": "talheres por peças",
    "floreros metal": "vasos de metal",
    "textil cocina": "têxtil de cozinha",
    "jarras y vasos": "jarras e copos",
    orquideas: "orquideas",
    abstractas: "resumo",
    fuentes: "fontes",
    "set de portavelas": "conjunto de castiçais",
    suculentas: "suculentos",
    cascanueces: "quebra-nozes",
    "luces led": "luzes LED",
    "adornos para jardin": "enfeites de jardim",
    "esferas decorativas ceramica": "esferas decorativas de cerâmica",
    "centro mesa ceramica": "mesa de centro cerâmica",
    "floreros y centros": "vasos e centros",
    "portaretratos multiples": "vários porta-retratos",
    "sillas de bar": "bancos de bar",
    "espejos rectangulares": "espelhos retangulares",
    anforas: "ânforas",
    "sets de baño": "conjuntos de banheiro",
    "otros muebles sala": "outros móveis de sala de estar",
    "centro mesa metal": "centro de mesa de metal",
    mantas: "mantas",
    "centros de mesa": "centros de mesa",
    "bandejas metal": "bandejas de metal",
    naranja: "laranja",
    "arbol premium": "prêmio arbol",
    pebeteros: "caldeirões",
    letreros: "sinais",
    "hombre de nieve- country": "boneco de neve - país",
    "africanas figuras": "figuras africanas",
    "adornos colgantes": "enfeites pendurados",
    candelabros: "candelabros",
    adornos: "enfeites",
    poltronas: "poltronas",
    "set espejos": "definir espelhos",
    "bandejas resina": "bandejas de resina",
    "velas daily": "velas diariamente",
    "punta de arbol": "copa da árvore",
    mallas: "meia-calça",
    "falda arbol": "saia de árvore",
    "portaretratos otras medidas": "porta-retrato outras medidas",
    "set cuadros": "definir quadros",
    "textil accesorios": "acessórios têxteis",
    vajillas: "talheres",
    "hombre de nieve": "homem de neve",
    cuberteria: "talheres",
    mesas: "mesas",
    estantes: "prateleiras",
    "adornos mesa": "enfeites de mesa",
    "bandejas ceramica": "bandejas de cerâmica",
    "recamara infantil": "quarto de criança",
    "floreros otros": "outros vasos",
    "marinas figuras": "figuras marinhas",
    cactus: "cacto",
    arlequines: "arlequins",
    "plantas y hojas": "plantas e folhas",
    "sala nautica": "sala náutica",
    leggings: "legging",
    "bandejas vidrio": "bandejas de vidro",
    "material didactico escolar": "material didático escolar",
    "bandejas madera": "bandejas de madeira",
    "relojes de mesa": "relógios de mesa",
    lazos: "laços",
    consolas: "consoles",
    "mesas de centro": "mesa de café",
    "accesorios nacimientos": "acessórios de nascimento",
    etiquetas: "rótulos",
    frutas: "fruta",
    "lampara de pie": "luminária de piso",
    gabinetes: "gabinetes",
    tops: "tops",
    "pinceles para manualidades": "escovas de artesanato",
    copas: "óculos",
    "centro mesa resina": "centro mesa resina",
    "adornos exteriores": "acabamento externo",
    "acc de baño": "banho",
    otros: "outras",
    "esferas decorativas vidrio": "esferas de vidro decorativas",
    "cajas de regalos": "caixas de presente",
    "bandejas para hornear": "assadeiras",
    "espejo utilitario": "espelho utilitário",
    repisas: "prateleiras",
    "alfombras de exterior": "tapetes ao ar livre",
    agendas: "agendas",
    "arbol basico": "árvore básica",
    "sports bra": "esportes bons",
    arreglos: "arranjos",
    bases: "bases",
    "stickers para manualidades": "adesivos para artesanato",
    "adornos con luz led": "enfeites com luz led",
    "juegos de tazas de café": "conjuntos de xícaras de café",
    "carritos de servir": "carrinhos de serviço",
    "muebles para jardin": "móveis de jardim",
    "bolas de agua": "bolas de água",
    "plantas surtidas": "plantas variadas",
    trenes: "trens",
    "juegos de tazas de te": "conjuntos de xícaras",
    casas: "casas",
    "oficina y escritorio": "escritório e mesa",
    "bomboneras/pastilleros": "caixas de doces/comprimidos",
    "cojines country": "almofadas country",
    "portaretratos 6x8": "portaretratos 6x8",
    "cartucheras y lapiceras": "estojos e canetas",
    "portaretratos especiales": "molduras especiais",
    "esferas decorativas resina": "esferas decorativas resina",
    "sillas tapizadas": "cadeiras estofadas",
    lamparas: "lâmpadas",
    pompones: "pompons",
    "potes de pared": "pernas de parede",
    "articulos para fiesta": "items da festa",
    estacas: "estacas",
    "decoración de cocina": "decoração da cozinha",
    peso: "peso",
    "santas colgantes country": "pingentes do país do papai noel",
    "muñecos animados": "bonecos animados",
    "santas fantasia": "fantasia sagrada",
    "textil bano": "têxtil de banheiro",
    "duendes- country": "goblins- país",
    niños: "crianças",
    "bienvenidos country": "país de boas-vindas",
    "papel adhesivo para forrar": "papel adesivo para forro",
    shorts: "calção",
    muebles: "mobília",
    "escenas villas": "cenas da vila",
    resistencia: "resistência",
    "relojes de arena": "ampulhetas",
    "lampara de techo": "luz de teto",
    "jgo tazas": "canecas jgo",
    "muebles cocina": "Móveis de cozinha",
    "renos- country": "renos- país",
    "carpeteria para oficina": "pasta de escritório",
    "otros adornos": "outros ornamentos",
    "articulos para manualidades": "material de artesanato",
    "ollas y sartenes": "tachos e panelas",
    "cepillo de inodoro": "escova de banheiro",
    accesorios: "acessórios",
    "articulos de madera": "artigos de madeira",
    "juegos de mesa": "jogos de tabuleiro",
    "botas y medias country": "botas e meias country",
    "hoodies y sueter": "moletons e suéter",
    fundas: "coldres",
    golosinas: "doces",
    sets: "conjuntos",
    botas: "botas",
    "pinturas para manualidades": "tintas artesanais",
    "boligrafos escolares": "boligrafos escolares",
    escarchas: "geada",
    "articulos de sederia": "itens de seda",
    "accesorios de entrenamiento": "acessórios de treino",
    ajedrez: "Xadrez",
    "textil sala otros": "sala têxtil outros",
    "marcadores escolares": "marcadores escolares",
    "candelero con luz led": "castiçal com luz led",
    "otros decorados": "outras decorações",
    "comedor nautico": "sala de jantar náutica",
    "arboles con luz": "árvores com luz",
    "velas de bateria": "velas de bateria",
    "accesorios villas": "acessórios para villas",
    palmeras: "Palmeiras",
    "carpetería escolar": "pasta da escola",
    "rojo 3": "vermelho 3",
    "accesorios yoga y pilates": "acessórios de ioga e pilates",
    "accesorios para oficina": "acessórios de escritório",
    "limpia pipas": "tubos limpos",
    lapices: "lápis",
    papeles: "papéis",
    "sillas plastico": "cadeiras de plástico",
    "gnomo-country": "país dos gnomos",
    termos: "termos",
    "matts o tapetes": "mate ou papel de parede",
    "porta carnet / gafetes / platificadoras":
        "porta-cartões / crachás / máquinas de galvanização",
    "accesorios para computadora": "acessórios de computador",
    pantalones: "calça",
    "arlequines fantasia": "arlequins de fantasia",
    maletas: "malas",
    potes: "parceiro",
    borradores: "rascunhos",
    "portavela 1 vela vidrio": "castiçal 1 vela de vidro",
    reglas: "as regras",
    "nacimientos acrilicos": "partos em acrílico",
    "juegos vasos y jarra": "conjuntos de copos e jarras",
    colgantes: "pingentes",
    "zapatillas flyknit": "tênis de malha",
    "sagrada familia": "Sagrada Familia",
    "arcangel miguel": "arcanjo miguel",
    tape: "fita",
    "caja de regalo": "caixa de presente",
    "muñecos inflables": "bonecos infláveis",
    "taza con cuchara": "copo com colher",
    "de fátima / con pastores /con corazon":
        "de Fátima / com pastores / com coração",
    "san jose": "São José",
    "sagrado corazon de jesus": "sagrado coração de Jesus",
    "figuras con luz": "figuras com luz",
    "basureros para baño": "latas de lixo do banheiro",
    "cobertores de silla y respaldo country":
        "cadeira de campo e capas traseiras",
    "luces de exterior": "luzes ao ar livre",
    "esferas decorativas fibra": "esferas de fibra decorativa",
    ojitos: "olhos pequenos",
    fechallegada: "fechallegada",
    "mas 120 dias": "mas 120 dias",
    bolsas: "bolsas",
    "luces basicas": "luzes básicas",
    "cangureras y cinturones": "pochetes e cintos",
    "milagrosa / medalla milagrosa": "medalha milagrosa / milagrosa",
    canastas: "cestas",
    bonsai: "bonsai",
    "acc de cabello": "cabelo acc",
    "copa para vino tinto": "copo para vinho tinto",
    "jackets y chalecos": "jaquetas e coletes",
    "copa para agua": "copo de água",
    "resaltadores escolares": "marcadores escolares",
    "comedor infantil": "refeitório infantil",
    "textil baño": "têxtil de banheiro",
    bienvenidos: "Bem vinda",
    "accesorios personales religiosos": "acessórios pessoais religiosos",
    "set de manualidades": "conjunto de artesanato",
    "articulos de fieltro": "itens de feltro",
    "inmaculada concepción de maria": "Concepção imaculada",
    escritorios: "escrivaninhas",
    bamboo: "bambu",
    inciensos: "incenso",
    "consolas metal": "consoles de metal",
    "bandejas metal redondas": "bandejas de metal redondas",
    "boligrafos para oficina": "canetas de escritório",
    cuadernos: "cadernos",
    bolsos: "bolsas",
    "notas adhesivas": "notas adesivas",
    tableros: "Pranchas",
    "crucifijos pared / mesa": "crucifixos de parede/mesa",
    "angel de la guarda": "anjo da guarda",
    "santisima trinidad": "Santíssima Trindade",
    "santas cajas 12 pzs country": "caixas de papai noel 12 pcs país",
    guadalupe: "Guadalupe",
    "mesas auxiliares metalicas": "mesas laterais de metal",
    "luces led de 10 o menos": "luzes led de 10 ou menos",
    "san antonio": "Santo Antônio",
    "recuperacion y terapia": "recuperação e terapia",
    "gomas y pegamentos escolares": "borrachas e colas escolares",
    "piedras decorativas": "pedras decorativas",
    "perforadoras para oficina": "exercícios de escritório",
    "sacapuntas escolares": "apontador de lápis escolar",
    "otros muñecos - country": "outras bonecas - país",
    autos: "carros",
    "tijeras para oficina": "tesoura de escritório",
    coronas: "coroas",
    "copas sin tipo": "óculos sem tipo",
    "divino niño": "criança divina",
    "pintura escolar y accesorios": "pintura e acessórios escolares",
    "gomas y pegamentos para manualidades": "borrachas e colas para artesanato",
    "acc arbol": "árvore de acc",
    mapas: "mapas",
    "santas con musica": "papai noel com musica",
    "consolas vidrio": "consoles de vidro",
    "textil infantil": "têxtil infantil",
    toys: "brinquedos",
    "bandejas decorativas": "bandejas decorativas",
    "san judas / judas tadeo": "são judas / judas tadeu",
    "del carmen": "de Carmem",
    tintas: "tintas",
    "copa para champaña": "Taça de champanhe",
    "set de placas": "set de placas",
    mochilas: "mochilas",
    "maria auxiliadora": "maria auxiliadora",
    "exactos y guillotinas": "exatas e guilhotinas",
    confeti: "confete",
    cajas: "caixas",
    "juegos de bar": "jogos de bar",
    "mama santa-country": "mamãe santa-país",
    "material de marketing": "material de marketing",
    engrapadoras: "engrapadoras",
    "cocina nautica": "cozinha náutica",
    "cara de santa": "cara de papai noel",
    "huevos decorativos": "ovos decorativos",
    ficheros: "arquivos",
    foco: "foco",
    "copa para vino blanco": "copo para vinho branco",
    revisteros: "porta-revistas",
    "referencias sin foto hogar": "referências sem foto de casa",
    "rosarios y denarios": "rosários e denários",
    geometría: "geometria",
    "arboles decor": "árvores de decoração",
    "jg de mesas metalicas": "jg de mesas de metal",
    "faldas country": "saias campestres",
    "sets escolares": "sets escolares",
    rosas: "rosas",
    "muebles navideños": "móveis de natal",
    "perpetuo socorro": "perpetuo socorro",
    "angel fibra optica": "anjo fibra óptica",
    crayones: "giz de cera",
    candeleros: "castiçais",
    "cuadros con marco": "molduras com moldura",
    "jesus misericordioso / divina misericordia":
        "jesus misericordioso / misericórdia divina",
    "arcangel gabriel": "arcanjo Gabriel",
    cuadros: "pinturas",
    "copa para licor": "copa para licor",
    "sagrado corazon de maria": "sagrado coração de maria",
    querubines: "querubins",
    "de la dulce espera": "da doce espera",
    "bomboneras y pastilleros": "caixas de doces e caixas de comprimidos",
    "relojes y tracker": "relógios e rastreador",
    "mesas auxiliares madera": "mesas laterais de madeira",
    "angeles acrilicos": "anjos de acrílico",
    plantillas: "modelos",
    botellas: "garrafas",
    "bases para potes": "bases para potes",
    "imagenes para pintar": "fotos para pintar",
    "sin foto": "sem foto",
    lupas: "lupa",
    "arcagel rafael": "arcagel rafael",
    "bolso de playa": "Bolsa de praia",
    "cajas de regalo": "Caixas de presente",
    "pinceles escolares": "escovas de escola",
    "tijeras escolares": "tesoura escolar",
    "potes soporte metal": "potes de metal",
    "plantas colgantes": "plantas penduradas",
    "ensaladeras/bowl": "tigelas de salada / tigela",
    "cristo resucitado": "Cristo ressuscitado",
    "potes y maceteros": "vasos e jardineiras",
    "santa ana": "Santa ana",
    mugs: "canecas",
    "santa teresa": "Santa Teresa",
    "infante de praga": "criança de praga",
    "correctores para oficina": "corretores de escritório",
    "potes metal": "você pode metal",
    albumes: "álbuns",
    espejos: "Espelhos",
    "otras bolas": "outras bolas",
    desatanudos: "desatar",
    "san francisco": "São Francisco",
    "renos con luz": "rena com luz",
    "de lourdes": "pesado",
    nieve: "neve",
    "sillas bar": "cadeiras de bar",
    "portavela 1 vela metal": "castiçal 1 vela de metal",
    "juego de mesas": "jogo de tabuleiro",
    "vestidos y faldas": "vestidos e saias",
    "consolas acero inoxidable": "consoles de aço inoxidável",
    "san / sao expedito": "san / sao expedito",
    estrellas: "estrelas",
    aves: "pássaros",
    "santa marta": "Santa Marta",
    "set de licoreras y cokteleras": "conjunto de licores e coqueteleiras",
    "caminos de mesa country": "caminhos de mesa country",
    "silicona liquida": "silicone líquido",
    bandejas: "bandejas",
    "placas virgenes": "pratos virgens",
    "santas poliresina": "polidor de papai noel",
    "aguas benditas": "águas sagradas",
    "otras escenas": "outras cenas",
    "santa- country": "país do papai noel",
    "baño nautico": "banheiro náutico",
    "cascanueces - country": "quebra-nozes - país",
    "basculas digitales": "balanças digitais",
    "espiritu santo": "Espirito Santo",
    "padre pio (santo)": "pai pio (santo)",
    "dispensador de jabón": "saboneteira",
    "rosa mistica": "rosa mística",
    "fuentes con lampara/ luz": "fontes com lâmpada / luz",
    "placas sagrada familia": "pratos da sagrada familia",
    "de vidrio": "de vidro",
    "santa eduviges": "santa eduviges",
    "taburete individual": "fezes individuais",
    "plastilina escolar": "plasticina escolar",
    "tijeras para manualidades": "tesoura de artesanato",
    "barras de silicona": "hastes de silicone",
    "utensilios de cocina": "utensílios de cozinha",
    "juego 7 arcangeles": "jogo 7 arcanjos",
    "accesorios para escritorio": "acessórios de mesa",
    "luces led otros tamaños": "luzes led outros tamanhos",
    escaleras: "escadas",
    "jesus nazareno": "jesus nazareno",
    decanters: "decantadores",
    cupulas: "cúpulas",
    "jgs de bano": "jgs de bano",
    "platos ceramica": "pratos de cerâmica",
    "san pancracio": "san pancracio",
    "de las 3 rosas": "das 3 rosas",
    "san benito": "São Benito",
    "caridad de cobre": "caridade de cobre",
    "material marketing": "marketing de materiais",
    "santas con accesorios country": "papai noel com acessórios country",
    calculadoras: "calculadoras",
    "reyes magos": "Reis Magos",
    "material de cortar y troquelar": "cortar e morrer material",
    "luces led de 20": "20 luzes led",
    "osos- country": "osos- país",
    "velas aromaticas": "velas aromáticas",
    "otros santas": "outros santos",
    "candados y cierres de seguridad": "cadeados e fechaduras de segurança",
    "picks flores": "escolhe flores",
    latas: "latas",
    "porta vasos": "porta vasos",
    "calculadoras escolares": "calculadoras escolares",
    "ultima cena": "Última Ceia",
    "bandejas metal rectangulares": "bandejas retangulares de metal",
    "bases para pastel": "Bases de bolo",
    "del rosario": "Rosário",
    promocionales: "promocional",
    "bandeja decor": "bandeja de decoração",
    "lampara mesa base ceramica": "candeeiro de mesa com base em cerâmica",
    enterizos: "enterizos",
    "angeles- country": "angeles- país",
    "virgen medalla milagrosa": "medalha milagrosa virgem",
    "calendarios colgantes": "calendários pendurados",
    "descansa cucharas": "colheres de descanso",
    sofa: "sofá",
    loncheras: "loncheras",
    "santas acrilicos": "santos de acrílico",
    "porta retrato 8x10": "porta retrato 8x10",
    "angeles decorativos": "anjos decorativos",
    "bola decorativa": "bola decorativa",
    "textil exterior nautico": "têxtil exterior náutico",
    "reloj pared plastico": "relógio de parede de plástico",
    "12plg": "12pl",
    "juego 6 piezas": "jogo 6 peças",
    tecnologia: "tecnologia",
    "16plg": "16pl",
    "24plg": "24pl",
    "san juan bautista": "São João Batista",
    "5plg": "5pl",
    "de la salud": "da Saúde",
    jaulas: "gaiolas",
    "calendarios country": "calendários de países",
    "8plg": "8pl",
    "otros niños": "Outras crianças",
    "santa familia acrilica": "santa familia acrilica",
    "santa rita": "santa rita",
    "florero vidrio -25 cm": "vaso de vidro -25 cm",
    joyeros: "joalheiros",
    "santa lucia": "Santa Lúcia",
    "san juan bosco": "São João Bosco",
    "de chiquinquira": "de chiquinquira",
    "funda p/cojin-country": "capa de almofada country",
    "flores y follaje": "flores e folhagens",
    "niña maria": "menina maria",
    "placas angeles": "placas de anjos",
    "jg de mesas madera": "jg de mesas de madeira",
    "articulos de cuentas": "itens de conta",
    "niño/niña- country": "menino / menina- país",
    "san lazaro": "São Lázaro",
    "santa librada": "santo liberto",
    fondue: "fondue",
    "san jorge": "São Jorge",
    "piks y entremeseros": "piks e entremeseros",
    magnetos: "ímãs",
    "soportes / atriles": "estandes / estandes de música",
    "santo niiño de atocha": "santo filho de atocha",
    "otros textiles country": "outros têxteis do país",
    "nuestra señora aparecida / la aparecida":
        "nossa senhora apareceu / apareceu",
    "de la merced/con esposas/con niños":
        "de la merced / com algemas / com crianças",
    "virgen sagrado corazon de maria": "Virgem Sagrado Coração de Maria",
    vinchas: "vinchas",
    "san gregorio hernandez": "São Gregório Hernández",
    "otras placas": "outras placas",
    difusores: "difusores",
    sofas: "sofás",
    "san cristobal": "São Cristovão",
    exhibidores: "expositores",
    "juego de velas": "conjunto de velas",
    "mesas para jardin": "mesas de jardim",
    "bandejas metal ovaladas": "bandejas de metal ovais",
    tizas: "giz",
    "san judas": "São Judas",
    "pistolas de silicona": "armas de silicone",
    "divina pastora": "pastora divina",
    "lampara mesa base vidrio": "candeeiro de mesa com base de vidro",
    "esferas decorativas madera": "esferas decorativas de madeira",
    "virgen de guadalupe": "Virgem de Guadalupe",
    "placas jesus": "placas jesus",
    "dispensadores de jabon": "dispensadores de sabão",
    conos: "cones",
    relojes: "relógios",
    "buen pastor": "Bom pastor",
    tapacorcho: "saca rolhas",
    "mesas auxiliares otras": "mesas auxiliares outros",
    "lampara de pared": "Lâmpada de parede",
    "country colgante": "pingente de país",
    campanas: "sinos",
    "floreros de ceramica": "vasos de cerâmica",
    "grama artificial": "grama artificial",
    "snowman acrilico": "boneco de neve acrílico",
    "florero vidrio 33 a 40 cm": "vaso de vidro 33 a 40 cm",
    "portaretratos 3x3": "portaretratos 3x3",
    "virgen del carmen": "virgem do carmem",
    "consolas madera": "consoles de madeira",
    "virgen mistica 3 rosas": "mística virgem 3 rosas",
    "guirnaldas y coronas con luz": "guirlandas e grinaldas com luz",
    ramas: "remanescente",
    "san cayetano": "São Caetano",
    "la candelaria": "candelária",
    "correctores escolares": "corretores escolares",
    "bandejas metal cuadradas": "bandejas quadradas de metal",
    "set espejos redondos": "conjunto de espelhos redondos",
    "porta retrato 4x6": "porta retrato 4x6",
    vasos: "xícaras",
    "relojes country": "relógios country",
    "san martin de porres": "San Martin de Porres",
    "cascanueces lata": "quebra-nozes pode",
    "jesus divina misericordia": "jesus divina misericordia",
    "baño infantil": "banheiro infantil",
    letras: "letras",
    pastores: "pastores",
    "marcadores para oficina": "marcadores para escritório",
    "el calvario": "o Calvário",
    "bolas 60mm": "bolas 60mm",
    "otros animales": "Outros animais",
    manzanas: "maçãs",
    "cuadros y placas": "fotos e placas",
    "caminos tela": "estradas de pano",
    "resaltadores para oficiina": "marcadores de escritório",
    "la anunciacion": "a anunciação",
    "recamara nautica": "quarto náutico",
    "carritos de servir negros": "carrinhos de servir preto",
    fruteros: "fruteiras",
    "acc para cerveza": "acc para cerveja",
    "accesorios escolares": "acessórios escolares",
    "san charbel": "São Charbel",
    "snowman con luz": "boneco de neve com luz",
    "cuidado de la salud": "cuidados de saúde",
    "santo niño de atocha": "santo filho de atocha",
    "surtidos- country": "país variado",
    "otros angeles": "outros anjos",
    "virgen de la inmaculada": "virgem da imaculada",
    "lampara mesa base metal": "candeeiro de mesa com base em metal",
    "de coromoto": "por coromoto",
    "relojes de piso": "relógios de chão",
    "jesus y angel": "jesus e anjo",
    "lging 7/8 sculpt": "lging 7/8 esculpir",
    "lging seamless": "sem costura",
    "milagroso de buga": "milagroso de buga",
    "referencias sin foto religioso": "referências sem foto religiosa",
    delfines: "golfinhos",
    "nac 5plg a 7plg": "nac 5plg a 7plg",
    "otros cristo": "outro cristo",
    "potes ceramica": "você pode cerâmica",
    "maria la antigua": "velha maria",
    "martin caballero": "cavaleiro de martim",
    "canvas 80x80": "tela 80x80",
    "abrazo de francisco": "Abraço do Francisco",
    "set cuadros cuadrados": "conjunto de molduras quadradas",
    "lampara de aceite": "lamparina a óleo",
    "san sebastian": "são Sebastião",
    "corona 18plg": "coroa 18plg",
    "bandejas madera cuadradas": "bandejas quadradas de madeira",
    "porta retrato 5x7": "porta retrato 5x7",
    "jacket athleisure": "jaqueta esportiva",
    "virgen de lourdes": "virgem de lurdes",
    "bebe jesus": "bebe jesus",
    ceniceros: "cinzeiros",
    mascotas: "animais de estimação",
    "cascanueces acrilicos": "quebra-nozes acrílico",
    gallinas: "galinhas",
    "aceiteras y vinagreras": "latas de óleo e galheteiro",
    "la dolorosa": "a dor",
    "virgen maria auxiliadora": "virgem maria ajudante",
    "niño jesus pastor": "menino jesus pastor",
    "juegos de shots": "jogos de tiros",
    "jarras y termos": "jarras e garrafas térmicas",
    "esferas decorativas metal": "esferas decorativas de metal",
    "adornos de cristal": "ornamentos de cristal",
    "copos de nieve": "copos de neve",
    "sacapuntas para oficina": "apontador de lápis para escritório",
    "relojes surtidos": "relógios variados",
    plastilina: "argila",
    "bolas +100mm": "bolas + 100mm",
    "placas niño jesus": "pratos do bebê jesus",
    "platos cuadrados y rectangulares": "placas quadradas e retangulares",
    "bandejas resina rectangulares": "bandejas retangulares de resina",
    "el justo juez": "o justo juiz",
    "arcangel chamuel": "arcanjo chamuel",
    "placas sagrado corazon de jesus": "placas sagrado coração de jesus",
    "san expedito": "San expedito",
    "florero vidrio 25 a 28 cm": "vaso de vidro 25 a 28 cm",
    elfos: "elfos",
    "cojin 45 x 45 cm": "cojin 45 x 45 cm",
    "florero ceramica 33 a 40 cm": "vaso de cerâmica 33 a 40 cm",
    perros: "cães",
    "escenas nacimientos": "cenas de nascimento",
    "de la providencia": "da providência",
    "virgen del perpetuo socorro": "virgem do perpétuo socorro",
    osos: "ursos",
    "figuras surtidas": "figuras variadas",
    servilletero: "Porta-guardanapo",
    "porta ret otras medidas": "porta ret outras medidas",
    "luces led de 50": "50 luzes led",
    "platos redondos y ovalados": "placas redondas e ovais",
    "santa barbara": "Santa Barbara",
    "libros con oracion": "livros de oração",
    "de la ofrenda": "da oferta",
    "relojes de pared country": "relógios de parede do país",
    "florero vidrio 29 a 32 cm": "vaso de vidro 29 a 32 cm",
    "accesorios para regalo": "acessórios para presentes",
    tarjeteros: "titulares de cartão",
    licoreras: "lojas de bebidas",
    "entrada a jerusalen": "entrada para jerusalém",
    especieros: "especiarias",
    "san juan diego": "São João Diego",
    "cintas 6 cm": "fitas 6 cm",
    "juego de copas": "jogo de bebida",
    "trenes resina/ceramica": "trens de resina/cerâmica",
    "candeleros juego": "jogo de castiçais",
    "niña maria / con libro/con rosario":
        "menina maria / com livro / com rosário",
    "para ti": "For You",
    "mejores vendidos": "Best Sellers",
    "visto recientemente": "Recently Viewed",
    "sub categoria": "sub categoria",
    arbustos: "arbustos",
    "asiento metal": "assento de metal",
    "asiento plastico": "assento de plástico",
    "asiento tapizado": "assento estofado",
    "bandejas plasticas": "bandejas plásticas",
    "cajas decorativas": "caixas decorativas",
    "canastas decorativas": "cestas decorativas",
    circulos: "círculos",
    "cojin 30x50 cm": "almofada 30x50 cm",
    "cojin 45x45 cm": "almofada 45x45 cm",
    "cojin 50x50 cm": "almofada 50x50 cm",
    "cojin redondo": "almofada redonda",
    colgadores: "cabides",
    eucalipto: "eucalipto",
    helechos: "samambaias",
    hojas: "folhas",
    hortensias: "hortênsias",
    jacintos: "jacintos",
    "mano de tigre": "garra de tigre",
    mantas: "mantas",
    "mesas de comedor": "mesas de jantar",
    "mueble para tv": "móvel para tv",
    nudos: "nós",
    "otras formas espejos": "outras formas de espelhos",
    "platos deco ceramica": "pratos deco cerâmica",
    "platos deco resina": "pratos deco resina",
    portavasos: "porta-copos",
    ramos: "ramos",
    sabila: "aloe vera",
    "sillas bar metal": "cadeiras de bar de metal",
    "sillas bar plasticas": "cadeiras de bar de plástico",
    "sillas bar tapizadas": "cadeiras de bar estofadas",
    sofás: "sofás",
    "sujetador de libros": "suporte para livros",
    "velas aromaticas": "velas aromáticas",
    "velas con luz": "velas com luz",
    "centro de mesa": "centro de mesa",
    "centros de mesa": "centros de mesa",
    comedor: "sala de jantar",
    "esferas decorativas": "esferas decorativas",
    floreros: "vasos",
    jardin: "jardim",
    "muebles sala": "móveis de sala",
    "muebles tapizados": "móveis estofados",
    "otros adornos sala": "outros enfeites de sala",
    placas: "placas",
    "plantas decorativas sala": "plantas decorativas para a sala",
    portaretratos: "porta-retratos",
    "potes decorativos sala": "potes decorativos para a sala",
    recamara: "quarto",
    regalos: "presentes",
    "textil sala": "têxteis para a sala",
    margaritas: "margaridas",
    descuento: "desconto",
    "estilo de vida": "estilos de vida",
    bohemio: "boêmio",
    categorias: "categorias",
    contemporaneo: "contemporâneo",
	contemporaeo: "contemporâneo",
    etnico: "étnico",
    industrial: "industrial",
    "mid century": "mid century",
    natural: "natural",
    naturall: "naturall",
    nautico: "náutico",
    "neo clasico": "neo clássico",
    "neo clasicoo": "neo clássico",
    "rustico glam": "rústico glam",
};
