import React, { useState } from "react";
import styled from "styled-components";
import StickyContainer from "./ReactSticky/StickyContainer";
import {
    Section,
    Container,
    Level,
    LevelItem,
    LevelLeft,
    LevelRight,
    BlankState,
} from "../../sdk/Layout";
import { Button } from "../../sdk/Button";
import Icon2 from "../../components/Icons2";
import ProductGrid from "../../components/ProductGrid2";
import Filter from "../../components/Filter2";
import QuickViewVirtualized from "./QuickViewVirtualized";
import WidthWidth from "../../helpers/WithWidth";
import { Control, Field, SelectBox } from "../../sdk/Form";
import useTraceUpdate from "../../hooks/useTraceUpdate";

const Main = styled.div`
    display: flex;
    position: relative;
    padding-top: 10px;
    min-height: 976px;
`;

const BtnFilter = styled.div`
    width: 190px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    color: ${(props) => props.theme.primaryDarkMode};
    display: block;
    margin-bottom: 0;
    cursor: pointer;
    user-select: none;
    padding: 12px 10px;
    min-height: 40px;
    border: solid 1px ${(props) => props.theme.borderColor};
    border-radius: 4px;
    @media (min-width: 769px) {
        &.is-active {
            border-radius: 0;
            width: 200px;
            margin-left: -10px;
            border-left: 0;
        }
    }
    svg {
        float: right;
        width: 14px;
        height: 14px;
    }
    @media (max-width: 768px) {
        font-size: 0;
        width: 40px;
        svg {
            display: block;
            width: 100%;
            text-align: center;
        }
        &.is-active {
            color: #fff;
            background-color: #112c55;
            border-color: #112c55;
        }
    }
`;

const SelectBoxExtended = styled(SelectBox)`
    @media (max-width: 768px) {
        width: 100%;
        max-width: 171px;
    }
`;

function CollectionProducts(props) {
    const {
        products,
        _t,
        handleLimitChange,
        limit,
        page,
        order,
        handlePageChange,
        handleOrderChange,
        getRecomendations,
        getCompleteTheLook,
        lastKnownProduct,
        addToCart,
        removeItem,
        viewItem,
        ambientProducts,
        itemToItem,
        tags,
        handleTagSelected,
        slug,
        width,
        isRetrivingProducts,
        selectedTags,
        isSearch,
        getInAdvancePage,
        asociatedProducts,
        prices,
        isDirectos,
        availableFilter,
        jsonSettings,
        pathname,
        customTracker,
        refetch,
    } = props;
    const [productIndex, setProductIndex] = useState(-1);
    const [replaceProductObject, setReplaceProductObject] = useState(null);
    const [length, setLength] = useState(
        products != null && products.products != null
            ? products.products.length
            : 0
    );

    const prevProductRef = React.useRef(length);
    const timeoutRef = React.useRef(null);
    const timeoutLookRef = React.useRef(null);
    const prevProductIndexRef = React.useRef(productIndex);

    const handleSetProductIndex = (index) => {
        setReplaceProductObject(null);
        setProductIndex(index);
    };

    const getDefaultFilterView = () => {
        const newFilterActive =
            typeof window !== "undefined"
                ? window.localStorage.getItem("filterDefault")
                : null;

        if (width <= 767) return false;
        return newFilterActive ? newFilterActive === "true" : false;
    };

    const [filterActive, setFilterActive] = useState(getDefaultFilterView());

    const getDefaultGrid = (newGrid) => {
        const defaultGrid =
            typeof window !== "undefined"
                ? window.localStorage.getItem("grid")
                : null;

        if (defaultGrid != null && defaultGrid !== "") return defaultGrid;
        return "x4";
    };

    const handleTagSelectedExtended = (tag) => {
        if (width < 767) {
            setFilterActive(false);
        }
        handleTagSelected(tag);
    };

    const handleReplaceProductObject = (itemId, product) => {
        document.getElementById("close-button").scrollIntoView(true);
        setReplaceProductObject(product);
    };

    const setDefaultGrid = (newGrid) => {
        window.localStorage.setItem("grid", newGrid);
        setGrid(newGrid);
    };

    const [grid, setGrid] = useState(() => {
        return getDefaultGrid();
    });

    const showQuickView = React.useCallback(
        (product, index) => {
            // setQuickViewActive(true);
            setReplaceProductObject(null);
            if (width < 1561) {
                setFilterActive(false);
            }
            handleSetProductIndex(index);
            setGrid("x4");
            if (product) {
                if (typeof analytics !== "undefined") {
                    analytics.track("Product Clicked", {
                        product_id: product.slug,
                    });
                }
                if (customTracker) customTracker(product.slug, product);
            }
        },
        [width]
    );

    const hideQuickView = () => {
        // setQuickViewActive(false);
        setReplaceProductObject(null);
        handleSetProductIndex(-1);
        setGrid(getDefaultGrid);
    };

    const toggleFilter = () => {
        setFilterActive((prev) => {
            window.localStorage.setItem("filterDefault", !prev);
            if (width < 1561 && !prev) hideQuickView();
            return !prev;
        });
    };

    const onLimitChange = (e) => {
        handleLimitChange(parseInt(e.target.value));
    };

    const onOrderChange = (e) => {
        if (prevProductIndexRef.current > -1) handleSetProductIndex(0);
        handleOrderChange(e.target.value);
    };

    const onPageChange = React.useCallback(
        (page) => {
            if (prevProductIndexRef.current > -1) handleSetProductIndex(0);
            handlePageChange(page);
        },
        [handlePageChange]
    );

    const currentProduct =
        products && products.products && productIndex != null
            ? products.products[productIndex]
            : null;

    function nextProduct() {
        setReplaceProductObject(null);
        if (width < 1561) {
            setFilterActive(false);
        }

        setProductIndex(function (prev) {
            const newIndex = prev + 1;

            if (products && newIndex >= prevProductRef.current) {
                return prev;
            }
            return newIndex;
        });
    }

    const backProduct = () => {
        setReplaceProductObject(null);
        setProductIndex((prev) => {
            const newIndex = prev - 1;
            return newIndex < -1 ? -1 : newIndex;
        });
    };

    // const onKeyDown = (event) => {
    //     if (event.keyCode === 39) {
    //         nextProduct();
    //     } else if (event.keyCode === 37) {
    //         backProduct();
    //     } else if (event.keyCode === 27) {
    //         hideQuickView();
    //     }
    // };

    // React.useEffect(() => {
    //     document.addEventListener("keydown", onKeyDown, false);
    //     return () => {
    //         document.removeEventListener("keydown", onKeyDown, false);
    //     };
    // }, []);

    React.useEffect(() => {
        const newLenght =
            products != null && products.products != null
                ? products.products.length
                : 0;
        setLength(newLenght);
        prevProductRef.current = newLenght;
    }, [products]);

    React.useEffect(() => {
        if (prevProductRef.current > productIndex + 1) {
            const product = products
                ? products.products[productIndex + 1]
                : null;
            if (product) {
                setTimeout(() => {
                    const img =
                        product.imagesUrl && product.imagesUrl.length > 0
                            ? product.imagesUrl[0].url
                            : "";

                    let imageObj = new Image();
                    imageObj.src = img;
                }, 50);
            }
        }
    }, [productIndex]);

    React.useEffect(() => {
        if (currentProduct != null) {
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(() => {
                getRecomendations(currentProduct.slug);
            }, 300);
            if (timeoutLookRef.current) clearTimeout(timeoutLookRef.current);
            timeoutLookRef.current = setTimeout(() => {
                getCompleteTheLook(currentProduct.slug);
            }, 400);
        }

        prevProductIndexRef.current = productIndex;
    }, [productIndex]);

    React.useEffect(() => {
        handleSetProductIndex(-1);
    }, [slug]);

    React.useEffect(() => {
        if (width < 769) setFilterActive(getDefaultFilterView());
    }, [width]);

    if (
        !isRetrivingProducts &&
        (products == null ||
            products.products == null ||
            products.products.length === 0)
    )
        return (
            <Section>
                <Container isFluid>
                    <BlankState>
                        <div>
                            {/* <Icon2 icon="shopping-cart"></Icon2> */}
                            <h3>{_t("searchNotFound")}...</h3>
                            <p>{_t("noProductsFound")} </p>
                        </div>
                    </BlankState>
                </Container>
            </Section>
        );
    return (
        <>
            {/* <ReactTooltip effect="solid" /> */}
            <Section>
                <Container isFluid>
                    <Level isMobile>
                        <LevelLeft>
                            <LevelItem>
                                <BtnFilter
                                    className={filterActive ? "is-active" : ""}
                                    onClick={() => toggleFilter()}
                                >
                                    {_t("filters")}

                                    <Icon2
                                        className={`${
                                            filterActive ? "" : "is-hidden"
                                        } is-hidden-mobile`}
                                        icon="angle-down"
                                    />

                                    <Icon2
                                        className={`${
                                            filterActive ? "is-hidden" : ""
                                        } is-hidden-mobile`}
                                        icon="angle-up"
                                    />

                                    <Icon2
                                        className="is-visible-mobile"
                                        icon="filter"
                                    />
                                </BtnFilter>
                            </LevelItem>
                        </LevelLeft>
                        <LevelRight>
                            <LevelItem>
                                <Field hasAddons>
                                    <Control>
                                        <Button static>
                                            <Icon2 icon="sort" />
                                        </Button>
                                    </Control>
                                    <Control>
                                        <SelectBoxExtended>
                                            <select
                                                onChange={onOrderChange}
                                                value={order}
                                            >
                                                {isSearch ? (
                                                    <option value="">
                                                        {_t("relevancy")}
                                                    </option>
                                                ) : (
                                                    "null"
                                                )}

                                                {!isSearch ? (
                                                    <option value="predefined-order-descending">
                                                        {_t("relevancy")}
                                                    </option>
                                                ) : null}
                                                <option value="slug-descending">
                                                    {_t("fabricaHighLow")}
                                                </option>
                                                <option value="price-ascending">
                                                    {_t("priceLowHigh")}
                                                </option>
                                                <option value="price-descending">
                                                    {_t("priceHighLow")}
                                                </option>
                                                <option value="title-ascending">
                                                    A-Z
                                                </option>
                                                <option value="title-descending">
                                                    Z-A
                                                </option>
                                                <option value="created-ascending">
                                                    {_t("oldestNewest")}
                                                </option>
                                                <option value="created-descending">
                                                    {_t("newestOldest")}
                                                </option>
                                                <option value="minventa-ascending">
                                                    {_t("minVentaAsec")}
                                                </option>
                                                <option value="minventa-descending">
                                                    {_t("minVentaDesc")}
                                                </option>
                                            </select>
                                        </SelectBoxExtended>
                                    </Control>
                                </Field>
                            </LevelItem>
                            <LevelItem className="is-hidden-mobile">
                                <Field hasAddons>
                                    <Control>
                                        <Button static>
                                            <Icon2 icon="eye" />
                                        </Button>
                                    </Control>
                                    <Control>
                                        <SelectBox>
                                            <select
                                                value={limit}
                                                onChange={onLimitChange}
                                            >
                                                <option value={35}>35</option>
                                                <option value={70}>70</option>
                                                <option value={105}>105</option>
                                            </select>
                                        </SelectBox>
                                    </Control>
                                </Field>
                            </LevelItem>

                            <LevelItem className="is-hidden-mobile">
                                <Field hasAddons>
                                    <Control>
                                        <Button
                                            className={
                                                grid === "x7" ? "is-active" : ""
                                            }
                                            onClick={() => setDefaultGrid("x7")}
                                        >
                                            <Icon2 icon="th" />
                                        </Button>
                                    </Control>
                                    <Control>
                                        <Button
                                            className={
                                                grid === "x4" ? "is-active" : ""
                                            }
                                            onClick={() => setDefaultGrid("x4")}
                                        >
                                            <Icon2 icon="thlarge" />
                                        </Button>
                                    </Control>
                                </Field>
                            </LevelItem>
                        </LevelRight>
                    </Level>
                </Container>
                <StickyContainer>
                    <Main>
                        <Filter
                            filterActive={filterActive}
                            tags={tags}
                            prices={prices}
                            isDirectos={isDirectos}
                            availableFilter={availableFilter}
                            selectedTags={selectedTags}
                            slug={slug}
                            handleTagSelected={handleTagSelectedExtended}
                        />
                        <ProductGrid
                            products={products}
                            grid={grid}
                            currentPage={page}
                            setPage={onPageChange}
                            getInAdvancePage={getInAdvancePage}
                            onQuickView={showQuickView}
                            addToCart={addToCart}
                            viewItem={viewItem}
                            limit={limit}
                            order={order}
                            isRetrivingProducts={isRetrivingProducts}
                            width={width}
                            currentProduct={productIndex}
                            lastKnownProduct={lastKnownProduct}
                            productIndex={productIndex}
                        />
                        <QuickViewVirtualized
                            products={products ? products.products : []}
                            isBackProductActive={productIndex > 0}
                            productIndex={productIndex}
                            setProductIndex={handleSetProductIndex}
                            isNextProductActive={length - 1 > productIndex}
                            productLength={length}
                            hideQuickView={hideQuickView}
                            jsonSettings={jsonSettings}
                            asociatedProducts={asociatedProducts}
                            viewItem={handleReplaceProductObject}
                            replaceProductObject={replaceProductObject}
                            addToCart={addToCart}
                            removeItem={removeItem}
                            isSticky={width > 768}
                            width={width}
                            refetch={refetch}
                            itemToItem={itemToItem}
                            nextProduct={nextProduct}
                            backProduct={backProduct}
                            ambientProducts={ambientProducts}
                        />
                    </Main>
                </StickyContainer>
            </Section>
        </>
    );
}

export default WidthWidth()(CollectionProducts);
