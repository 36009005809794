import React from "react";
import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Section, Container, Heading } from "../../sdk/Layout";
// import { Button } from "../../sdk/Button";
import InternalLink from "../InternalLink";
import { Button } from "../../sdk/Button";

const ImageCardListContainer = styled.div``;

const Image = styled.div`
    position: relative;
    &:before {
        content: "";
        display: block;
        padding-top: 100%;
    }
    img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
    }
`;

const Card = styled(InternalLink)`
    width: 15.666%;
    min-width: 150px;
    margin: 0.5%;
    margin-bottom: 10px;
    font-size: 16px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    h3 {
        font-size: 14px;
        text-decoration: none;
        color: ${(props) => props.theme.primaryDark};
        display: block;
        font-weight: 300;
        padding: 20px 0 10px;
        font-weight: bold;
        text-transform: uppercase;
        border-bottom: solid 1px transparent;
        position: relative;
        text-overflow: ellipsis;
        overflow: hidden;
        :before {
            content: "";
            display: block;
            background-color: ${(props) => props.theme.primaryDarkMode};
            height: 1px;
            width: 0;
            transition: all 200ms ease-in-out;
            position: absolute;
            bottom: 0;
            left: 0;
            opacity: 0;
        }
    }

    :hover h3:before {
        width: 100%;
        opacity: 1;
    }

    @media (max-width: 768px) {
        min-width: 120px;
        h3 {
            font-size: 12px;
            padding: 10px 0 5px;
        }
    }
`;

const Cards = styled.div`
    overflow: hidden;
    position: relative;
    font-size: 0;
    margin-right: -0.5%;
    margin-left: -0.5%;

    @media (max-width: 980px) {
        & {
            overflow-x: auto;
            white-space: nowrap;
        }
    }
`;

const Center = styled.div`
    text-align: center;
    padding-top: 10px;
`;

const Link = styled(InternalLink)`
    display: block;
    margin: 20px auto;
    text-align: center;
    max-width: 250px;
    button {
        display: block;
        margin: auto;
    }
`;

const SlideCardContainer = styled.div`
    @media (max-width: 980px) {
        position: relative;
        &:before,
        &:after {
            content: "";
            display: block;
            width: 40px;
            height: 100%;
            position: absolute;
            top: 0;
            right: -2px;
            box-shadow: inset -25px 0 25px -10px white;
            z-index: 2;
            transition: all 200ms linear;
        }
        &:before {
            box-shadow: inset 25px 0 25px -10px white;
            right: inherit;
            left: -3px;
            opacity: 0;
        }
        &.to-left:before {
            opacity: 1;
        }
        &.to-left::after {
            opacity: 0;
        }
    }
`;
function AmbientBlock({ childCollection, config, title, limit, pathname }) {
    if (
        config == null ||
        config.length === 0 ||
        childCollection == null ||
        childCollection.length === 0
    )
        return null;
    const childCollectionHalf = childCollection.slice(0, limit || 10);

    React.useEffect(() => {
        const slideContainer = document.getElementById("slide-container");
        const slide = document.getElementById("slide");
        const slideWidth = slide.offsetWidth;
        const scrollWidth = slide.scrollWidth;
        slide.onscroll = function () {
            if (slide.scrollLeft < 10) {
                slideContainer.classList.remove("to-left");
            } else if (slide.scrollLeft + slideWidth > scrollWidth / 2) {
                slideContainer.classList.add("to-left");
            }
        };

        if (typeof window !== "undefined" && pathname != null)
            window.__lastAmbient = pathname;
    }, []);

    return (
        <Section className="content-visibility">
            <Container>
                <Heading>
                    <h2>{config[0].title}</h2>
                    <p>{config[0].paragraph}</p>
                </Heading>
                <SlideCardContainer id="slide-container">
                    <Cards id="slide">
                        {childCollectionHalf.map((index, i) => {
                            const image =
                                index.imagesUrl && index.imagesUrl.length > 0
                                    ? index.imagesUrl[0]?.thumb || ""
                                    : "";

                            return (
                                <Card
                                    slug={`${index["parent-slug"]}/${i}`}
                                    type="ambient"
                                    key={index._id}
                                >
                                    <Image className="image-card__title">
                                        <LazyLoadImage
                                            src={image}
                                            alt={`ambiente ${index.title}`}
                                        />
                                    </Image>
                                    <h3>{index.title}</h3>
                                </Card>
                            );
                        })}
                    </Cards>
                </SlideCardContainer>
                <Link slug={config[0].slug} type="ambient">
                    <Button>{title}</Button>
                </Link>
            </Container>
        </Section>
    );
}

export default React.memo(AmbientBlock);
