import React from "react";

export default function HomeIcon() {
    return (
        <svg
            width="23px"
            height="21px"
            viewBox="0 0 23 21"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <g
                    id="current_bar"
                    transform="translate(-599.000000, -69.000000)"
                    fill="#000000"
                    fillRule="nonzero"
                >
                    <g
                        id="Group-8"
                        transform="translate(563.000000, 50.000000)"
                    >
                        <g id="Group">
                            <g
                                id="Stacked-Group"
                                transform="translate(36.000000, 18.000000)"
                            >
                                <g id="home">
                                    <path d="M22.8652344,9.85679688 L12.4051758,1.32164062 C11.8758796,0.894737193 11.1205266,0.894737193 10.5912305,1.32164062 L0.134765625,9.85679688 C-0.0188207734,9.98196373 -0.0421300965,10.207823 0.08265625,10.3617188 L0.536367187,10.9191992 C0.660994949,11.0720564 0.885527318,11.095913 1.03949219,10.9726563 L2.875,9.47316406 L2.875,20.3891797 C2.875,20.7861344 3.19679534,21.1079297 3.59375,21.1079297 L9.34375,21.1079297 C9.74070466,21.1079297 10.0625,20.7861344 10.0625,20.3891797 L10.0625,14.6391797 L12.9375,14.6526563 L12.9375,20.40625 C12.9375,20.8032047 13.2592953,21.125 13.65625,21.125 L19.40625,21.1101758 C19.8032047,21.1101758 20.125,20.7883804 20.125,20.3914258 L20.125,9.47316406 L21.9605078,10.9726563 C22.1144738,11.0977471 22.3406775,11.074423 22.4658789,10.9205469 L22.9195898,10.3630664 C22.9796902,10.2887922 23.0077398,10.1936429 22.9975404,10.0986446 C22.9873409,10.0036463 22.939732,9.91661998 22.8652344,9.85679688 Z M18.6825586,19.6762695 L18.6870508,19.6762695 L14.3745508,19.6897461 L14.3745508,13.9321094 C14.3733261,13.5365383 14.0536122,13.2158221 13.6580469,13.2133594 L9.34554688,13.2012305 C9.15453366,13.2006335 8.97113923,13.2760952 8.83586127,13.4109517 C8.70058332,13.5458082 8.62454985,13.7289663 8.62455078,13.9199805 L8.62455078,19.6762695 L4.3125,19.6762695 L4.3125,8.30025391 L11.5,2.43480469 L18.6875,8.30025391 L18.6825586,19.6762695 Z" />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
