export const defaultProduct = {
    _id: "RW9myZiA8azJKitNn",
    title: "Habladores con pestaña",
    slug: "599-4215110001",
    sku: "599-4215110001",
    detalle: {
        material: "SULFITO",
        dimensions: "18plgX3plgX3plg",
        packaging: "Caja de  carton Corrugado",
        unit: "PZ",
        collection: "",
    },
    content: "",
    protected: false,
    check_availability: true,
    vendor: "Santini Christmas",
    type: "MATERIAL MARKETING",
    tags: [
        "categoria_marketing",
        "feria_",
        "colecciones_",
        "inventario",
        "navidad",
        "material marketing",
        "material marketing",
        "navidad",
        "color_rojo ",
    ],
    multiple: 20,
    min_venta: 20,
    fair: "",
    branch: "110",
    collection: null,
    available: 40,
    price: 1.03,
    compare_at_price: null,
    cubicaje: 0.003,
    img: "https://s3.amazonaws.com/ntx-images/599-4215110001.jpg",
    imagesUrl: [
        {
            url: "https://www.noritex.com/iydyBo8N5xHz84YKg/599-4215110001.jpg",
            thumb:
                "https://www.noritex.com/iydyBo8N5xHz84YKg/224x224_599-4215110001.jpg",
        },
    ],
    images: [
        {
            filename: "iydyBo8N5xHz84YKg/599-4215110001.jpg",
            width: 2000,
            height: 2000,
        },
    ],
    companyId: "iydyBo8N5xHz84YKg",
    showSmallClients: true,
    display_price: 1.03,
};

export const defaultStrings = {
    available: "Disponible",
    notAvailable: "No Disponible",
    fair: "Feria",
    caseOf: "Feria",
};
