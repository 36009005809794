import React from "react";
import { useParams } from "react-router-dom";
import Cover from "../components/Cover";

import { Section } from "../../sdk/Layout";
import { Button } from "../../sdk/Button";
import { Field, Control } from "../../sdk/Form";

import { SectionHeader } from "../styles";
import Blueberry from "../../../blueberry";
import useInput from "../../hooks/useInput";
import { useTranslation } from "../../../lang";

function Admin({ recomendations, addToCart }) {
    const [_t] = useTranslation();
    const [isLoading, setIsLoading] = React.useState(false);
    const [password, input] = useInput({
        type: "text",
        placeholder: "Secret Password",
    });
    let { page, lang } = useParams();

    const handleOnSubmit = (e) => {
        console.log(password);
        Blueberry.setPriceLevel(password)
            .then(() => {
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
                alert(
                    "No tienes perfil de vendedor para poder usar esta funcionalidad"
                );
            });
    };

    return (
        <>
            <Section>
                <SectionHeader>
                    <h3>{_t("adminSection")}</h3>
                    <p>This sections is only available to</p>
                </SectionHeader>

                <Field hasAddons>
                    <Control isExpanded>{input}</Control>
                    <Control>
                        <Button
                            loading={isLoading}
                            onClick={handleOnSubmit}
                            primary
                        >
                            Submit
                        </Button>
                    </Control>
                </Field>
            </Section>
        </>
    );
}

export default Admin;
