import React, { useState } from "react";

function useInput({ type, placeholder, onKeyPress, autoFocus }) {
    const [value, setValue] = useState("");

    const input = (
        <input
            value={value}
            placeholder={placeholder}
            onKeyPress={onKeyPress}
            onChange={(e) => setValue(e.target.value)}
            type={type}
            autoFocus={autoFocus}
        />
    );
    return [value, input, setValue];
}

export default useInput;
