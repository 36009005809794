import styled from "styled-components";
import {
    Container,
    Section,
    Columns,
    Column,
    Title,
    Subtitle,
    Level,
    LevelLeft,
    LevelRight,
    LevelItem,
    Box,
    Heading,
    SimpleCard,
} from "../sdk/Layout";
import { Menu, Profile, Tabs, Tag } from "../sdk/Elements";
import { Icon, Button } from "../sdk/Button";

export const Wrapper = styled.div``;

export const PreloaderContainer = styled.div`
    height: 400px;
    width: 100%;
`;

export const Sidebar = styled(Column)`
    /* height: 100%; */
    padding: 0;
    width: 280px;
    @media (max-width: 1030px) {
        display: block;
        height: auto;
        width: 100%;
    }
`;

export const Main = styled(Column)`
    padding: 0;
`;

export const AppMenu = styled(Menu)`
    a.is-active {
        svg {
            fill: #fff;
            color: #fff;
        }
    }
    li.is-disabled {
        opacity: 0.2;
        text-decoration: line-through;
    }
    @media (max-width: 1030px) {
        &:not(.is-active) {
            display: none;
        }
        margin-top: 20px;
    }
`;

export const ToggleAppMenu = styled(Button)`
    display: none;
    justify-content: space-between;
    span {
        text-transform: uppercase;
    }
    @media (max-width: 1030px) {
        display: flex;
    }
    svg {
        margin-top: 1px;
    }
    &.is-open svg {
        transform: rotate(180deg);
    }
`;

export const MenuContainer = styled.div`
    background-color: ${(props) => props.theme.white};
    border-right: 1px solid ${(props) => props.theme.borderColor};
    padding: 20px;
    width: 280px;
    /* height: 100%; */
    overflow-x: hidden;
    overflow-y: auto;
    position: sticky;
    height: calc(100vh - 106px);
    top: 106px;
    left: 0;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
    &:hover {
        /*overflow-y: auto;
  	padding-right: 5px;*/
    }

    @media (max-width: 1030px) {
        background-color: ${(props) => props.theme.grayLight};
        position: relative;
        width: 100%;
        height: auto;
        padding: 10px;
        top: 0px;
    }
`;

export const Cover = styled.div`
    height: 170px;
    position: relative;
    overflow: hidden;
    background-color: ${(props) => (props.isLoading ? "transparent" : "#000")};
    @media (max-width: 980px) {
        background-color: ${(props) =>
            props.isLoading ? "transparent" : "#000"};
    }
    > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        opacity: 0.5;
        @media (max-width: 980px) {
            /* opacity: 1; */
        }
    }
    ${Profile} {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        flex-direction: column;
        width: 100%;
        padding: 0px 10px;
        figure {
            margin-right: 0;
            margin-bottom: 10px;
            background-color: #fff;
        }
        h3 {
            color: #fff;
        }
    }
    @media (max-width: 550px) {
        height: 170px;
        & ${Profile} {
            figure {
                width: 80px;
                height: 80px;
            }
        }
    }
`;

export const TopBar = styled.div`
    background-color: ${(props) => props.theme.white};
    padding: 10px;
    h4 {
        margin-bottom: 0;
    }
    ${Tabs} {
        font-size: 14px;
    }
    p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: ${(props) => props.theme.primaryDark};
    }
    @media (max-width: 768px) {
        & ${LevelItem} + ${LevelItem} {
            margin-left: 0 !important;
        }
    }
`;

export const SectionHeader = styled(Heading)`
    h3 {
        text-transform: uppercase;
    }
    @media (max-width: 768px) {
        padding-bottom: 10px;
        h2,
        h3,
        p {
            width: 100%;
        }
        h2 {
            font-size: 12px;
        }
        p {
            font-size: 11px;
            line-height: 13px;
        }
    }
    @media (max-width: 980px) ${(props) =>
            !props.theme.isStandalone ? "and (display-mode: standalone)" : ""} {
        padding-bottom: 5px;
        h3 {
            text-transform: initial;
            padding-bottom: 0;
            margin-bottom: 0;
            font-size: 18px;
            &:before {
                display: none;
            }
        }
    }
`;

export const Content = styled.div`
    padding: 0 5%;
    @media (max-width: 768px) {
        padding: 0px 4px;
    }
`;

export const Wrap = styled.div`
    width: 100%;
    max-width: 700px;
    margin: auto;
`;

export const Divisor = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${(props) => props.theme.borderColor};
    margin: 30px 0;
    @media (max-width: 550px) {
        margin: 10px 0;
    }
`;

export const Feed = styled(Box)`
    & + & {
        margin-top: 20px;
    }
    h4,
    p {
        margin: 0;
    }
    figure {
        position: relative;
        padding-top: 35%;
        margin-bottom: 10px;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        & ${Icon} {
            position: absolute;
            top: 10px;
            right: 10px;
            border: solid 1px #e3e3e3;
            border-radius: 2px;
            cursor: pointer;
            background-color: rgba(0, 0, 0, 0.2);
            opacity: 0.6;
            :hover {
                opacity: 0.8;
            }
            svg {
                color: #fff;
            }
        }
    }
    footer {
        position: relative;
        h4 {
            font-weight: bold;
            font-size: 16px;
            color: ${(props) => props.theme.primaryDark};
            padding-bottom: 5px;
        }
        span {
            color: ${(props) => props.theme.gray};
            font-size: 12px;
        }
        p {
            margin: 0;
            margin-top: 10px;
            padding: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 14px;
        }
        svg {
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }

    @media (max-width: 550px) {
        padding: 10px;
    }
`;

export const CollectionList = styled.div`
    font-size: 0;
    margin-right: -0.5%;
    margin-left: -0.5%;

    ${SimpleCard} {
        width: 19%;
        margin-bottom: 20px;

        @media (max-width: 980px) {
            width: 32.333%;
        }

        @media (max-width: 768px) {
            width: 49%;
            margin-bottom: 10px;
            h3 {
                font-size: 12px;
            }
        }
    }
`;

export const TagsList = styled.div`
    margin-bottom: 10px;
    p {
        display: inline-block;
        margin-right: 10px;
        font-weight: bold;
        font-size: 14px;
        color: ${(props) => props.theme.primaryDark};
    }
    ${Tag} {
        cursor: pointer;
        border: solid 1px ${(props) => props.theme.borderColor};
        font-size: 16px;
        margin-right: 7px;
        margin-bottom: 7px;
        &:hover {
            background-color: ${(props) => props.theme.borderColor};
        }
        @media (max-width: 768px) {
            font-size: 10px;
        }
    }
`;

export const TagExtended = styled(Tag)`
    ${(props) =>
        props.isSelected
            ? `
    background-color: #112c55 !important;
    color: #fff;
    
    `
            : ""}
`;

export const ContentEditor = styled.div`
    margin: 20px 0;
    width: 100%;
`;
export const TopBarWrapper = styled.div`
    .stand-alone-topbar {
        background-color: #fff;
        padding: 5px;
        overflow-x: auto;
        .brands-buttons {
            font-size: 0;
            white-space: nowrap;
            a {
                opacity: 0.7;
                display: inline-block;
                vertical-align: top;
                &:not(:last-child) {
                    margin-right: 7px;
                }
                &.is-disabled {
                    display: none;
                }
                &.selected {
                    opacity: 1;
                    div {
                        box-shadow: 0 0 0 1px #939eb1;
                    }
                    span {
                        color: #464646;
                    }
                }
                div {
                    border: solid 2px white;
                    width: 45px;
                    height: 45px;
                    overflow: hidden;
                    border-radius: 100%;
                }
                span {
                    font-size: 9px;
                    line-height: 10px;
                    color: #747474;
                    display: block;
                    text-align: center;
                    padding-top: 3px;
                    width: 45px;
                    white-space: normal;
                }
            }
            svg {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            figure {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                background: #122c55;
                overflow: hidden;
                svg {
                    width: 25px !important;
                    height: 25px !important;
                    object-fit: contain !important;
                    color: #fff;
                }
            }
        }
        .v-divisor {
            width: 1px;
            height: 15px;
            background: #b4b4b4;
        }
    }
`;

export const CardList = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -1%;
    margin-right: -1%;
    margin-left: -1%;
    padding: 0 20px;
    margin-top: -50px;
    position: relative;
    z-index: 2;
    &:empty {
        display: none;
    }
    @media (max-width: 768px) {
        padding: 0px;
        margin: 0;
    }
`;

export const Card = styled.div`
    background-color: ${(props) => props.theme.white};
    padding: 20px;
    width: 31.333%;
    margin: 1%;
    h3 {
        font-size: 20px;
        line-height: 18px;
        margin-bottom: 15px;
        color: ${(props) => props.theme.primaryDark};
    }
    figure {
        display: block;
        overflow: hidden;
        position: relative;
        margin-bottom: 10px;
        &:before {
            content: "";
            display: block;
            padding-top: 100%;
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    span {
        text-decoration: none;
        cursor: pointer;
        color: ${(props) => props.theme.primaryDarkMode};
        font-size: 14px;
        &:hover {
            text-decoration: underline;
        }
    }
    @media (max-width: 768px) {
        padding: 10px;
        margin: 0;
        width: 33.333%;
        figure {
            margin-bottom: 6px;
        }
        h3 {
            font-size: 16px;
            line-height: 14px;
        }
        span {
            font-size: 12px;
        }
    }
    @media (max-width: 540px) {
        h3 {
            font-size: 13px;
        }
    }
`;

export const ButtonExtended = styled(Button)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: -15px;
    svg {
        width: 26px;
        fill: white;
        color: white;
        height: 26px;
    }
    span {
        color: white;
        position: relative;
        top: -5px;
    }

    @media (max-width: 768px) {
        margin-top: 0;
    }
`;
