import React, { useState } from "react";
import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { LangConsumer } from "../../context";
import { Button, Buttons } from "../../sdk/Button";
import { Preloader } from "../../sdk/Elements";
import Icon2 from "../Icons2";
import useInCart from "../../../blueberry/hooks/useInCart";
import WishlistButton from "../WishlistButton";

export { default as DetailProductTable } from "./DetailProductTable";
export { default as ProductMeta } from "./ProductMeta";
export { default as AddToCart } from "./AddToCart";

export { default as ProductTitle } from "./ProductTitle";
export { default as AsociatedProducts } from "./AsociatedProducts";
import {
	isNumeric,
	numberWithCommas,
	scrollElementIntoView,
} from "../../utils";
import { defaultProduct, defaultStrings } from "./defaultProduct";
import { getProductMin, getAvailability } from "./AddToCart";
import getProductStatus from "./helpers/getProductStatus";
import { getDetailValue } from "./DetailProductTable";

export const TagExtented = styled.span`
	background-color: ${(props) =>
		props.status === "future"
			? "#2296f1"
			: props.status === "notAvailable"
			? "#e32619"
			: props.status === "transit"
			? "#257942"
			: "#112c55"} !important;
	${(props) => (props.status === "available" ? "display: none;" : "")}
	color: #fff;
	position: absolute;
	z-index: 2;
	right: 0px;
	height: 25px;
	line-height: 25px;
	border-radius: 3px 3px 0 0px;
	transform: rotate(-90deg);
	transform-origin: bottom right;
	font-weight: bold;
	padding: 0 10px;
	letter-spacing: 2px;
	bottom: 120px;
	@media (max-width: 768px) {
		height: 20px;
		line-height: 20px;
		padding: 0px 8px;
		font-size: 8px;
		bottom: inherit !important;
		top: -15px !important;
	}
`;

const Info = styled.div`
	color: ${(props) => props.theme.gray};
	font-size: 12px;
	justify-content: space-between;
	display: flex;
	@media (max-width: 768px) {
		position: absolute;
		bottom: 0;
		left: 10px;
		span + span {
			margin-left: 10px;
		}
	}
`;

const BtnQuickView = styled(Button)`
	@media (max-width: 768px) {
		span {
			display: none;
		}
		svg {
			margin-right: -5px !important;
		}
	}
	@media (min-width: 769px) {
		svg {
			display: none;
		}
	}
`;

export const Item = styled.div`
	margin-bottom: 10px;
	width: 100%;
	text-align: center;
	cursor: pointer;
	font-size: 16px;
	border: solid 1px ${(props) => props.theme.borderColor};
	padding: 10px;
	transition: border-color 200ms linear;
	position: relative;
	margin-right: 0.5%;
	margin-left: 0.5%;
	display: inline-block;
	vertical-align: top;
	color: ${(props) => props.theme.primaryDark};
	background-color: ${(props) =>
		props.highlight ? "rgba(0,0,0,0.08)" : props.theme.prodItemBg};

	scroll-margin-top: 160px;
	border-radius: ${(props) => props.theme.radio};

	@media (max-width: 768px) {
		width: 99% !important;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		padding: 7px 5px;
		padding-right: 25px;
		margin-bottom: 5px;
		border: none;
		&:not(:last-child) {
			border-bottom: solid 1px ${(props) => props.theme.borderColor};
		}
		> div {
			padding-top: 0 !important;
			padding-left: 10px;
			width: 100%;
		}
	}

	@media (min-width: 769px) {
		&:hover {
			border-color: ${(props) => props.theme.primaryDarkMode};
		}
	}

	${(props) =>
		props.active ? `border-color: ${props.theme.primaryDarkMode};` : ""}

	figure {
		position: relative;
		&:before {
			content: "";
			display: block;
			padding-top: 90%;
		}
		img {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			object-fit: contain;
			object-position: center;
		}
		span {
			position: absolute;
		}
		@media (max-width: 768px) {
			width: 120px;
			margin: auto;
			&:before {
				padding-top: 100%;
			}
		}
	}
	.price-foused {
		background-color: ${(props) => props.theme.primary};
		color: ${(props) => props.theme.white};
		padding: 2px;
		span {
			color: ${(props) => props.theme.white};
		}
		border-radius: 3px;
	}
	> div {
		padding-top: 20px;
		text-align: left;
		position: relative;

		p {
			margin: 0 0 6px;
			&:empty {
				display: none;
			}
			span {
				font-weight: bold;
				font-size: 15px;
				padding-bottom: 5px;
				color: ${(props) => props.theme.primaryDark};
			}
		}

		h3 {
			font-weight: normal;
			text-transform: uppercase;
			font-size: 14px;
			padding-bottom: 5px;
			max-width: 90%;
			overflow: hidden;
			word-break: break-word;
			margin-top: 0;
			font-weight: 400;
			margin-bottom: 0;
		}

		> span {
			font-size: 12px;
			color: ${(props) => props.theme.gray};
		}
		@media (max-width: 768px) {
			p {
				span {
					font-size: 12px;
				}
			}
			h3 {
				font-size: 11px;
				margin-top: 0;
				margin-bottom: 0;
				font-weight: bold;
			}
			span {
				/*display: none;*/
			}
		}
	}

	${Buttons} {
		position: absolute;
		bottom: 100%;
		margin-top: -20px;
		left: 0;
		top: 0;
		width: 100%;
		padding: 0px 10px;
	}
	${Button} {
		box-shadow: 0px 4px 20px -5px rgba(0, 0, 0, 0.3);
		transition: all 200ms linear;
		opacity: 0;
		z-index: 2;
		& + ${Button} {
			margin-left: 5px;
		}
	}

	&:hover ${Button} {
		opacity: 1;
	}

	@media (max-width: 768px) {
		& ${Button} {
			opacity: 1 !important;
			-webkit-box-shadow: inherit;
			box-shadow: inherit;
		}
		& ${Buttons} {
			margin-top: 0;
			width: initial;
			padding: 0;
			justify-content: flex-start !important;
			left: inherit;
			right: 0;
			bottom: 0;
			top: inherit;
		}
	}
`;

const InCartTag = styled.p`
	/* height: 10px;
    width: 10px;
    float: right !important;
    background-color: #4caf50;
    padding: 0; */
	position: absolute;
	top: 0px;
	right: 0px;
	z-index: 9;
	font-size: 10px;
	background-color: ${(props) => props.theme.grayLight};
	padding: 4px 6px;
	border-radius: 0 0 0 3px;
	border: solid 1px ${(props) => props.theme.borderColor};
	display: flex;
	justify-content: center;
	color: ${(props) => props.theme.gray};
`;

const ForceVisible = styled.span`
	display: block !important;
	margin-bottom: 0;

	display: inline !important;
	${(props) =>
		props.compare
			? `
    color: rgba(0, 0, 0, 0.2) !important;
    text-decoration: line-through !important;
    `
			: ""}
	& + & {
		margin-left: 5px;
	}
`;

export const PreloaderProduct = () => {
	return (
		<Item>
			<a>
				<Preloader square />
			</a>
			<div>
				<Preloader half />
				<h3>
					<Preloader />
				</h3>
			</div>
		</Item>
	);
};

export function getFair(product) {
	return product && !product.check_availability;
}

export function productHasPrice(product) {
	return !!product.price_d || !!product.price || !!product.price_usa;
}
export function isPackagingNumeric(product) {
	const packaging = getDetailValue(product, "packaging");
	return (
		isNumeric(packaging) &&
		productHasPrice(product) &&
		packaging > 0 &&
		packaging !== "PZ" &&
		packaging !== "Pedaço"
	);
}

export function getProductCase(product) {
	const packaging = getDetailValue(product, "packaging");
	if (isPackagingNumeric(product)) {
		if (product.price_usa) {
			return numberWithCommas(product.price_usa / packaging);
		}
		return numberWithCommas(
			(product.price_d ? product.price_d : product.price) / packaging,
		);
	}
	return null;
}

export function getProductPrice(product) {
	if (product.price_usa) {
		return product.price_usa;
	}
	return product.price_d ? product.price_d : product.price;
}

export function ProductPrice(props) {
	return (
		<>
			{props.compare_at_price === "" ||
			props.compare_at_price == null ? null : (
				<ForceVisible compare>${props.compare_at_price}</ForceVisible>
			)}
			{props.price === "" ||
			props.price === "NaN" ||
			props.price == null ? null : (
				<ForceVisible
					className={` ${
						props.compare_at_price === "" ||
						props.compare_at_price == null
							? ""
							: "compare-at-price"
					}`}>
					${props.price}
				</ForceVisible>
			)}
			{props.price_d === "" ||
			props.price_d === "NaN" ||
			props.price_d == null ? null : (
				<ForceVisible className={``}>${props.price_d}</ForceVisible>
			)}
			{props.price_usa === "" ||
			props.price_usa === "NaN" ||
			props.price_usa == null ? null : (
				<ForceVisible className={``}>${props.price_usa}</ForceVisible>
			)}
		</>
	);
}

function getProductImage(imgObj) {
	if (imgObj.medium) return imgObj.medium;
	return (
		imgObj.url.replace(
			"https://d3rf19v7mx5ido.cloudfront.net",
			"https://www.noritex.com",
		) + "?d=400x400"
	);
}

function Product({
	product,
	index,
	onQuickView,
	scrollPosition,
	strings = defaultStrings,
	active,
	addToCart,
	viewItem,
	highlight,
	removeProduct,
	disableLazyLoad,
	isLoggedIn,
	isDirectos,
	hideAddToCart,
}) {
	const [isAddingToCart, setIsAddingToCart] = React.useState(false);
	const [isRemoving, setIsRemoving] = React.useState(false);
	const [currentImage, setCurrentImage] = React.useState(
		product.imagesUrl && product.imagesUrl.length > 0
			? getProductImage(product.imagesUrl[0])
			: "https://www.noritex.com/uplds/2022/0/12/hoBAkXMjq5b8nsnMcno-image.jpg",
	);
	const productRef = React.useRef();
	const currentAvailble = React.useRef(true);

	const inCart = useInCart(product._id);
	const isAvailable = getAvailability(product) && currentAvailble.current;
	const isInCart = inCart != null;

	const packaging = getDetailValue(product, "packaging");
	const hasPrice = productHasPrice(product);

	const isFair = getFair(product);

	let didRequestImage = false;

	const handleQuickView = (e) => {
		e.stopPropagation();
		e.preventDefault();
		onQuickView(product, index);
	};

	const handleAddToCart = (e) => {
		e.stopPropagation();
		e.preventDefault();
		setIsAddingToCart(true);
		addToCart(product._id, getProductMin(product, isDirectos), product)
			.then(() => {
				setIsAddingToCart(false);
			})
			.catch((err) => {
				currentAvailble.current = false;
				setIsAddingToCart(false);
			});
	};

	const handleRemove = (e) => {
		e.stopPropagation();
		e.preventDefault();
		setIsRemoving(true);
		removeProduct(product._id)
			.then(() => {
				setIsRemoving(false);
			})
			.catch((err) => {
				// console.log(err);
			});
	};

	const handleOnHover = () => {
		if (product.imagesUrl && product.imagesUrl.length > 1) {
			setCurrentImage(getProductImage(product.imagesUrl[1]));
		}
	};
	const onMouseLeave = () => {
		const img =
			product.imagesUrl && product.imagesUrl.length > 0
				? product.imagesUrl[0].url
				: "";

		if (img !== "" && !didRequestImage) {
			let imageObj = new Image();
			imageObj.src = img;
			didRequestImage = true;
		}
		if (product.imagesUrl && product.imagesUrl.length > 0) {
			setCurrentImage(getProductImage(product.imagesUrl[0]));
		}
	};

	const handleViewItem = (e) => {
		e.stopPropagation();
		e.preventDefault();
		viewItem(product.slug, product);
	};

	const doNothing = (e) => {
		e.stopPropagation();
		e.preventDefault();
		viewItem(product.slug, product);
	};

	const productStatus = getProductStatus({ product });
	React.useEffect(() => {
		if (highlight && typeof window !== "undefined") {
			setTimeout(function () {
				productRef &&
					productRef.current &&
					scrollElementIntoView(productRef.current, "smooth");
			}, 500);
		}
	}, []);
	React.useEffect(() => {
		if (active && typeof window !== "undefined") {
			productRef &&
				productRef.current &&
				scrollElementIntoView(productRef.current, "smooth");
		}
	}, [active]);

	return React.useMemo(
		() => (
			<LangConsumer>
				{({ lang }) => (
					<Item
						highlight={highlight}
						ref={productRef}
						onMouseEnter={handleOnHover}
						onTouchStart={handleOnHover}
						onMouseLeave={onMouseLeave}
						onTouchEnd={onMouseLeave}
						active={active}
						onClick={handleViewItem}>
						{isInCart ? (
							<InCartTag>{strings.inCart}</InCartTag>
						) : null}
						<TagExtented status={productStatus}>
							{productStatus === "future"
								? strings.fair
								: productStatus === "notAvailable"
								? strings.notAvailable
								: productStatus === "transit"
								? strings.transit
								: ""}
						</TagExtented>
						<a
							href={`/${lang}/products/${product.slug}/`}
							onClick={doNothing}>
							<figure>
								<LazyLoadImage
									// effect="blur"
									visibleByDefault={disableLazyLoad}
									// srcSet={`
									//     ${img} 224w,
									//     ${currentImage} 400w
									// `}
									threshold={500}
									scrollPosition={scrollPosition}
									src={currentImage}
									alt={product.title}
								/>
							</figure>
						</a>
						<div>
							<p
								className={
									product.price_d ? `price-foused ` : ""
								}>
								{product.price_d ? `${strings.direct} ` : ""}
								<ProductPrice
									price={product.price}
									price_d={product.price_d}
									price_usa={product.price_usa}
									compare_at_price={product.compare_at_price}
								/>
							</p>
							<h3>
								{product.title ||
									product.title_eng ||
									product.title_pt}
							</h3>

							{isDirectos && product.slug ? (
								<span>{product.slug.substring(0, 6)}</span>
							) : null}
							<Info className="info">
								<span>
									{isFair
										? `Min ${product.min_venta}`
										: `${strings.caseOf} ${product.multiple}`}
								</span>
								{isPackagingNumeric(product) ? (
									<span>
										({getProductCase(product)}
										/Count)
									</span>
								) : null}
							</Info>
							<Buttons isCentered>
								{onQuickView ? (
									<BtnQuickView
										small
										onClick={handleQuickView}
										onTouchStart={handleOnHover}>
										<Icon2 icon="eye" />
										<span>{strings.quickView}</span>
									</BtnQuickView>
								) : null}
								{isLoggedIn && !hasPrice ? null : isLoggedIn &&
								  isAvailable &&
								  !hideAddToCart ? (
									<Button
										small
										onClick={handleAddToCart}
										loading={isAddingToCart && "true"}>
										<Icon2
											icon={
												isInCart ? "check" : "cart-plus"
											}
										/>
									</Button>
								) : null}
								{removeProduct ? (
									<Button
										small
										onClick={handleRemove}
										loading={isRemoving && "true"}>
										<Icon2 icon={"trash"} />
									</Button>
								) : null}
							</Buttons>
						</div>
						{isLoggedIn ? (
							<WishlistButton isHart productId={product._id} />
						) : null}
					</Item>
				)}
			</LangConsumer>
		),
		[
			inCart,
			isAddingToCart,
			strings,
			highlight,
			active,
			product,
			currentImage,
		],
	);
}

export default Product;
