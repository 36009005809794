import React from "react";

export default function UserIcon() {
    return (
        <svg
            width="23px"
            height="23px"
            viewBox="0 0 23 23"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g stroke="none" strokeWidth="{1}" fill="none" fillRule="evenodd">
                <g
                    id="current_bar"
                    transform="translate(-881.000000, -418.000000)"
                    fill="#000000"
                    fillRule="nonzero"
                >
                    <g
                        id="Group-8-Copy-5"
                        transform="translate(563.000000, 400.000000)"
                    >
                        <g id="Group">
                            <g
                                id="Stacked-Group"
                                transform="translate(34.000000, 18.000000)"
                            >
                                <g
                                    id="user-fill"
                                    transform="translate(284.000000, 0.000000)"
                                >
                                    <path d="M11.5,12.9375 C15.0712891,12.9375 17.96875,10.0400391 17.96875,6.46875 C17.96875,2.89746094 15.0712891,0 11.5,0 C7.92871094,0 5.03125,2.89746094 5.03125,6.46875 C5.03125,10.0400391 7.92871094,12.9375 11.5,12.9375 Z M17.25,14.375 L14.7748047,14.375 C13.7775391,14.8332031 12.6679688,15.09375 11.5,15.09375 C10.3320312,15.09375 9.22695312,14.8332031 8.22519531,14.375 L5.75,14.375 C2.57402344,14.375 0,16.9490234 0,20.125 L0,20.84375 C0,22.0341797 0.965820312,23 2.15625,23 L20.84375,23 C22.0341797,23 23,22.0341797 23,20.84375 L23,20.125 C23,16.9490234 20.4259766,14.375 17.25,14.375 Z" />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
