import React from "react";
import { useRouteMatch } from "react-router-dom";
import SearchSuggestions from "./SearchSuggestions";
import InternalLink from "../InternalLink";
import Icon from "../Icon";
import {
    SearchFlyDown,
    Closer,
    Footer,
    Results,
    Items,
    Suggestions,
} from "./style";

function BackButton() {
    let match = useRouteMatch(
        "/:lang/collection/:collection/:defaultTags/products/:product"
    );

    if (!match) return null;
    return (
        <button>
            <Icon icon="chevron-left" />
        </button>
    );
}

export default class Search extends React.PureComponent {
    state = {
        visible: false,
        value: "",
        currentItem: -1,
    };

    timeout = null;

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
        if (this.timeout) clearTimeout(this.timeout);
    }

    escFunction = (event) => {
        if (event.keyCode === 27) {
            this.closeFlyDown();
        }
        if (event.keyCode === 40) {
            this.nextItem();
        }
        if (event.keyCode === 38) {
            this.lastItem();
        }

        if (event.keyCode === 13) {
            this.viewResults(event);
            this.closeFlyDown();
        }
    };

    nextItem = () => {
        const { suggestions } = this.props;
        this.setState((prev) => {
            const nextItem = prev.currentItem + 1;

            return {
                currentItem:
                    nextItem > suggestions.length - 1
                        ? suggestions.length - 1
                        : nextItem,
            };
        });
    };

    lastItem = () => {
        this.setState((prev) => ({
            currentItem: prev.currentItem - 1 > -2 ? prev.currentItem - 1 : 0,
        }));
    };

    openFlyDown = () => {
        // if (window.location.href.indexOf("search") > -1) return;
        this.setState({ visible: true });
        document.addEventListener("keydown", this.escFunction, false);
    };

    closeFlyDown = () => {
        this.timeout = setTimeout(() => {
            this.setState({ visible: false });
        }, 200);

        try {
            document.activeElement.blur();
        } catch (e) {
            document.body.blur();
        }
        document.removeEventListener("keydown", this.escFunction, false);
    };

    viewSearchQuery = (e) => {
        e.preventDefault();
        const value = e.currentTarget.getAttribute("data-value");

        this.props.goToSearch(value);
    };

    onChange = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const { value } = e.target;
        this.setState({ value, currentItem: -1 });
        this.props.onChange(value);
    };

    viewResults = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (window.searchTimeout != null) {
            clearTimeout(window.searchTimeout);
        }
        const { currentItem, value } = this.state;
        const { suggestions } = this.props;
        if (currentItem >= 0) {
            this.props.goToSearch(suggestions[currentItem]);
        } else {
            this.props.goToSearch(value);
        }
    };

    getSearchResults = (suggestions, viewSearchQuery, currentItem) => {
        const productsComp = [];
        for (let i = 0; i <= Math.min(suggestions.length, 10); i++) {
            if (suggestions[i] != null) {
                productsComp.push(
                    <SearchSuggestions
                        key={suggestions[i] + i}
                        searchResult={suggestions[i]}
                        viewSearchQuery={viewSearchQuery}
                        active={currentItem === i}
                    />
                );
            }
        }
        return productsComp;
    };

    renderSearchResults = (
        suggestions,
        visible,
        viewSearchQuery,
        strings,
        isLoggedIn,
        currentItem
    ) => {
        if (!visible || suggestions == null || suggestions.length === 0)
            return null;

        return (
            <SearchFlyDown className={visible && "visible"}>
                <Closer onClick={this.closeFlyDown}>
                    <Icon icon="close" />
                </Closer>

                {/* <Results className="search-flydown--results--content-enabled visible">
                    <Items id="cb1-list">
                        {this.getSearchResults(suggestions, viewSearchQuery)}
                    </Items>
                    {suggestions.length > 0 ? (
                        <Footer>
                            <a
                                className="search-flydown--continue click"
                                onMouseDown={this.viewResults}
                            >
                                {strings.seAllResults}
                            </a>
                        </Footer>
                    ) : null}
                </Results> */}

                <Suggestions>
                    {/* <h3>Búsquedas</h3> */}
                    <ul>
                        {this.getSearchResults(
                            suggestions,
                            viewSearchQuery,
                            currentItem
                        )}
                    </ul>
                    {/* <span className="divisor"></span>
                    <h3>Menú</h3>
                    <ul>
                        <li><a href="">Home</a></li>
                        <li><a href="">Blog</a></li>
                        <li><a href="">Mis listas</a></li>
                        <li><a href="">Ayuda</a></li>
                    </ul> */}
                </Suggestions>
            </SearchFlyDown>
        );
    };

    render() {
        const { placeholder, suggestions, strings, isLoggedIn } = this.props;
        const { visible, value, currentItem } = this.state;

        return (
            <div
                className="live-search  "
                role="search"
                onBlur={this.closeFlyDown}
            >
                <form className="searchform" aria-autocomplete="list">
                    <input
                        id="search-input"
                        onFocus={this.openFlyDown}
                        onChange={this.onChange}
                        autoComplete="off"
                        value={
                            currentItem > -1 ? suggestions[currentItem] : value
                        }
                        tabIndex={0}
                        aria-label={placeholder}
                        placeholder={placeholder}
                        aria-expanded={visible ? "true" : "false"}
                        autoCapitalize="off"
                        autoCorrect="off"
                        aria-owns="cb1-list"
                        aria-haspopup="false"
                        role="combobox"
                        spellCheck="false"
                        title="buscar"
                    />
                    <button
                        onClick={this.viewResults}
                        aria-label="Search Button"
                    >
                        <svg
                            aria-hidden="true"
                            focusable="false"
                            role="presentation"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                        >
                            <path
                                fill="currentColor"
                                fillRule="evenodd"
                                d="M12.514 14.906a8.264 8.264 0 0 1-4.322 1.21C3.668 16.116 0 12.513 0 8.07 0 3.626 3.668.023 8.192.023c4.525 0 8.193 3.603 8.193 8.047 0 2.033-.769 3.89-2.035 5.307l4.999 5.552-1.775 1.597-5.06-5.62zm-4.322-.843c3.37 0 6.102-2.684 6.102-5.993 0-3.31-2.732-5.994-6.102-5.994S2.09 4.76 2.09 8.07c0 3.31 2.732 5.993 6.102 5.993z"
                            ></path>
                        </svg>
                    </button>
                    <label className="is-hidden" htmlFor="search-input">
                        {placeholder}
                    </label>
                    {this.renderSearchResults(
                        suggestions,
                        visible,
                        this.viewSearchQuery,
                        strings,
                        isLoggedIn,
                        currentItem
                    )}
                </form>
            </div>
        );
    }
}
