// import loadable from '@loadable/component'

// import LocalizedStrings from 'localized-strings';
import { tags_en } from "./tags_en.js";
import { tags_pt } from "./tags_pt.js";

// const es = loadable(() => import('./es'))
// const en = loadable(() => import('./en'))
// const pt = loadable(() => import('./pt'))

// import loadable from '@loadable/component'

// const es = loadable.lib(() => import('./es.js'))
// const en = loadable.lib(() => import('./en.js'))
// const pt = loadable.lib(() => import('./pt.js'))

export const lang = (expression, lang) => {
    switch (lang) {
        case "en":
            return tags_en[expression] == null
                ? expression
                : tags_en[expression];
        case "pt":
            return tags_pt[expression] == null
                ? expression
                : tags_pt[expression];
        default:
            return expression;
            break;
    }
};

// export const lang = es;
