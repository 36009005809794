import React from "react";

export default function SearchResults({
	searchResult,
	viewSearchQuery,
	active,
}) {
	return (
		<li className={active ? "is-active" : ""}>
			<a onClick={viewSearchQuery} data-value={searchResult}>
				{searchResult}
			</a>
		</li>
	);
}
