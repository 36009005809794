import React from "react";

export default function BagFillIcon() {
    return (
        <svg
            width="21px"
            height="23px"
            viewBox="0 0 21 23"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <g
                    id="current_bar"
                    transform="translate(-811.000000, -348.000000)"
                    fill="#000000"
                    fillRule="nonzero"
                >
                    <g
                        id="Group-8-Copy-4"
                        transform="translate(563.000000, 330.000000)"
                    >
                        <g id="Group">
                            <g
                                id="Stacked-Group"
                                transform="translate(34.000000, 18.000000)"
                            >
                                <g
                                    id="bag-fill"
                                    transform="translate(213.000000, 0.000000)"
                                >
                                    <path d="M16.8125,7.1875 L16.8125,5.75 C16.8125,2.57941406 14.233041,0 11.0625,0 C7.89191406,0 5.3125,2.57941406 5.3125,5.75 L5.3125,7.1875 L1,7.1875 L1,19.40625 C1,21.3910332 2.6089668,23 4.59375,23 L17.53125,23 C19.5160332,23 21.125,21.3910332 21.125,19.40625 L21.125,7.1875 L16.8125,7.1875 Z M8.1875,5.75 C8.1875,4.16470703 9.47720703,2.875 11.0625,2.875 C12.647793,2.875 13.9375,4.16470703 13.9375,5.75 L13.9375,7.1875 L8.1875,7.1875 L8.1875,5.75 Z M15.375,11.140625 C14.7795605,11.140625 14.296875,10.6579395 14.296875,10.0625 C14.296875,9.46706055 14.7795605,8.984375 15.375,8.984375 C15.9704395,8.984375 16.453125,9.46706055 16.453125,10.0625 C16.453125,10.6579395 15.9704395,11.140625 15.375,11.140625 Z M6.75,11.140625 C6.15456055,11.140625 5.671875,10.6579395 5.671875,10.0625 C5.671875,9.46706055 6.15456055,8.984375 6.75,8.984375 C7.34543945,8.984375 7.828125,9.46706055 7.828125,10.0625 C7.828125,10.6579395 7.34543945,11.140625 6.75,11.140625 Z" />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
