import React from "react";
import styled from "styled-components";
import { Modal } from "../../sdk/Elements";
import { Box, Heading } from "../../sdk/Layout";
import { Field, FieldBody, Control, SelectBox } from "../../sdk/Form";
import { Button, Buttons } from "../../sdk/Button";
import Icons2 from "../Icons2";
import HelpTooltip from "../HelpTooltip";
import useInput from "../../hooks/useInput";
import useSelect from "../../hooks/useSelect";
import worldCurrencies from "./worldCurrencies";
import { numberWithCommas } from "../../utils";
import Blueberry from "../../../blueberry";
import useNotification from "../../hooks/useNotification";
import ModalPortal from "../ModalPortal";
import { useTranslation } from "../../../lang";
import useUser from "../../../blueberry/hooks/useUser";
import { getProductPrice } from ".";

const ModalExtended = styled(Modal)`
	z-index: 999;
	> div {
		max-width: 500px;
	}
`;

const ButtonsExtended = styled(Buttons)`
	margin-top: 30px;
`;

const Info = styled.div`
	padding-top: 10px;
	text-align: center;
	div {
		padding: 5px 0;
	}
	svg {
		width: 20px;
		height: 20px;
		margin-bottom: -3px;
		color: ${(props) => props.theme.gray};
	}
	ul {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: space-between;
		padding: 10px;
		border-radius: 3px;
		border: solid 1px ${(props) => props.theme.borderColor};
		li {
			font-size: 20px;
			span {
				font-size: 12px;
				display: block;
				color: ${(props) => props.theme.gray};
			}
		}
	}
`;

const ModalPrecioTienda = ({ closeModal, product, refetch }) => {
	const [_t] = useTranslation();
	const user = useUser();

	const price = getProductPrice(product);

	const [multiplicador, multiplicadorInput, setMultiplicador] = useInput({
		type: "number",
		placeholder: "",
		defaultValue: 0,
	});
	const [conversion, conversionInput, setConversion] = useInput({
		type: "number",
		placeholder: "",
	});

	// const [handleNotify, NotifyProfile] = useNotification({
	//     timeout: 3000,
	// });

	const [currency, currencySelect, setCurrency] = useSelect({
		options: worldCurrencies.map((index, i) => ({
			key: `${index.symbol}-${i}`,
			value: index.symbol,
			label: `${index.cc} - ${index.symbol} `,
		})),
	});

	const handleSave = () => {
		if (typeof window !== "undefined" && analytics) {
			analytics.track("Store Price Set", {
				multiplicador,
				conversion,
				currency,
			});
		}
		Blueberry.storePriceConfiguration({
			multiplicador: parseFloat(multiplicador),
			conversion: conversion ? parseFloat(conversion) : 1,
			currency,
		})
			.then((resp) => {
				// handleNotify({
				//     paragraph: "Done Saved",
				//     info: true,
				// });
				if (refetch) refetch();
				closeModal();
			})
			.catch((error) => {});
	};

	React.useEffect(() => {
		if (user && user.storePrice) {
			if (user.storePrice.multiplicador)
				setMultiplicador(user.storePrice.multiplicador);
			if (user.storePrice.currency) setCurrency(user.storePrice.currency);
			if (user.storePrice.conversion)
				setConversion(user.storePrice.conversion);
		}
	}, [user]);

	return (
		<>
			<ModalPortal>
				<ModalExtended className="is-active">
					<button onClick={closeModal}></button>
					<span onClick={closeModal}></span>
					<div>
						<Box>
							<Heading isCentered>
								<h3>{_t("configureStorePrice")} </h3>
								<p>{_t("adjustMultiplier")}</p>
							</Heading>
							<FieldBody>
								<Field>
									<label>
										Multiplicador
										<HelpTooltip
											text={_t("timesNoritex")}
										/>
									</label>
									<Control isExpanded>
										{multiplicadorInput}
									</Control>
								</Field>
								<Field isNarrow>
									<label>{_t("currency")}</label>
									<SelectBox isFullWidth>
										{currencySelect}
									</SelectBox>
								</Field>
								<Field>
									<Control isExpanded>
										<label>{_t("currencyExchange")}</label>
										{conversionInput}
									</Control>
								</Field>
							</FieldBody>

							<Info>
								<div>
									<ul>
										<li>
											<span>{_t("price")} NTX</span>$
											{price}
										</li>
										<li>
											<Icons2 icon="close-alt"></Icons2>
										</li>
										<li>
											<span>{_t("multiplier")}</span>
											{multiplicador}
										</li>
										<li>
											<Icons2 icon="close-alt"></Icons2>
										</li>
										<li>
											<span>{_t("conversion")}</span>
											{conversion}
										</li>
									</ul>
								</div>
								<div>
									<Icons2 icon="equal"></Icons2>
								</div>
								<div>
									<h4>
										{_t("yourPrice")}: {currency}{" "}
										{numberWithCommas(
											price * multiplicador * conversion,
										)}
									</h4>
								</div>
							</Info>

							<ButtonsExtended isRight>
								<Button onClick={closeModal} small>
									{_t("cancel")}
								</Button>
								<Button small primary onClick={handleSave}>
									{_t("save")}
								</Button>
							</ButtonsExtended>
						</Box>
					</div>
				</ModalExtended>
			</ModalPortal>
			{/* <NotifyProfile /> */}
		</>
	);
};

export default ModalPrecioTienda;
