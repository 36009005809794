import React from "react";
import styled from "styled-components";
// import { Media, Player, controls, utils } from "react-media-player";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { autoPlay } from "react-swipeable-views-utils";
import SwipeableViews from "react-swipeable-views";
import { Section, Container, Heading } from "../../sdk/Layout";
import { Message } from "../../sdk/Elements";
import { Slider, Pager, Arrows } from "../../sdk/Slider";
import renderIf from "../../utils/renderIf";
import Audio from "../../components/Audio";
// const {
//     PlayPause,
//     CurrentTime,
//     Progress,
//     SeekBar,
//     Duration,
//     MuteUnmute,
//     Volume,
//     Fullscreen,
// } = controls;

const slideStyle = { overflow: "hidden" };

const SliderExtended = styled(Slider)`
    overflow: hidden;
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        li {
            overflow: hidden;
            img {
                width: auto;
                height: 100%;
                object-fit: contain;
                @media (max-width: 980px) {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
`;

const SliderFullSizeExtended = styled(Slider)`
    overflow: hidden;
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        min-height: 500px;
        overflow: hidden;
        position: relative;
        @media (max-width: 769px) {
            min-height: auto;
        }
        > div {
            width: 100%;
        }
        li {
            overflow: hidden;
            img {
                width: auto;
                height: 100%;
                display: block;
                margin: auto;
                object-fit: contain;
                @media (max-width: 980px) {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
`;

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Text = styled.div`
    max-height: 367px;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }

    h2 {
        font-size: 24px;
        margin-bottom: 20px;
        color: #fff;
    }
    p {
        font-size: 16px;
        line-height: 22px;
        + p {
            margin-top: 15px;
        }
    }

    @media (max-width: 980px) {
        h2 {
            font-size: 20px;
        }
        p {
            line-height: 18px;
            font-size: 14px;
        }
    }
`;

const FeatureImage = styled.div`
    display: flex;
    @media (max-width: 980px) {
        display: block;
    }
`;

const Left = styled.div`
    width: 30%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #112c55;
    padding: 50px;
    color: #fff;

    @media (min-width: 981px) {
        min-height: 500px;
    }
    @media (max-width: 980px) {
        width: 100%;
        padding: 20px;
    }
`;

const Right = styled.div`
    width: 70%;
    position: relative;
    @media (max-width: 980px) {
        width: 100%;
    }
`;
const Right100 = styled.div`
    width: 100%;
    position: relative;
    @media (max-width: 980px) {
        width: 100%;
    }
`;

const PlayerWrapper = styled.div`
    padding-top: 20px;
`;

const AudioExtended = styled(Audio)`
    padding: 0 0px;
    margin: 0 0px;
    width: 100% !important;
    max-width: 300px !important;
    display: block;
    margin: auto;
`;

const PlayerAudio = ({ audio }) => {
    if (audio == null && audio !== "") return null;
    return (
        <PlayerWrapper>
            <AudioExtended config={[{ audio }]} />
        </PlayerWrapper>
    );
};

const ItemDenominator = ({ count, activeItemIndex, onClick }) => {
    const dots = [];

    if (count === 0 || count === 1) return null;

    for (let i = 0; i < count; i++) {
        dots.push(
            <a
                key={`dot-slider-creator-${i}`}
                className={activeItemIndex === i ? "current" : ""}
                onClick={() => onClick(i)}
            />
        );
    }

    return <Pager>{dots}</Pager>;
};

function MasterHead({
    images,
    content,
    title,
    audio,
    imageSetAutomatically,
    isLoggedIn,
    _t,
}) {
    const [activeItemIndex, setActiveItemIndex] = React.useState(0);

    const imagesCount = images ? images.length : 0;

    const renderImages = imagesCount > 0 && !imageSetAutomatically;

    const nextSlide = () => {
        let newActiveItemIndex = activeItemIndex + 1;
        if (newActiveItemIndex >= imagesCount) {
            newActiveItemIndex = 0;
        }

        setActiveItemIndex(newActiveItemIndex);
    };

    const lastSlide = () => {
        let newActiveItemIndex = activeItemIndex - 1;
        if (newActiveItemIndex <= -1) {
            newActiveItemIndex = imagesCount - 1;
        }
        setActiveItemIndex(newActiveItemIndex);
    };

    React.useEffect(() => {
        setActiveItemIndex(0);
    }, [imagesCount]);

    if (content === "") {
        return (
            <Section>
                <Container>
                    <Heading isCentered>
                        <h3>{title}</h3>
                    </Heading>
                    {renderImages ? (
                        <FeatureImage>
                            <Right100>
                                <SliderFullSizeExtended>
                                    <ul>
                                        <AutoPlaySwipeableViews
                                            enableMouseEvents
                                            interval={4000}
                                            index={activeItemIndex}
                                            slideStyle={slideStyle}
                                            style={slideStyle}
                                            onChangeIndex={setActiveItemIndex}
                                        >
                                            {images &&
                                                images.map((index, i) => (
                                                    <li
                                                        key={`collection-slide-${i}`}
                                                    >
                                                        <LazyLoadImage
                                                            visibleByDefault={
                                                                true
                                                            }
                                                            effect="blur"
                                                            src={index}
                                                            alt={title}
                                                        />
                                                    </li>
                                                ))}
                                        </AutoPlaySwipeableViews>
                                    </ul>
                                    {renderIf(imagesCount > 1)(
                                        <Arrows>
                                            <a onClick={lastSlide}></a>
                                            <a onClick={nextSlide}></a>
                                        </Arrows>
                                    )}
                                    <ItemDenominator
                                        onClick={setActiveItemIndex}
                                        activeItemIndex={activeItemIndex}
                                        count={imagesCount}
                                    />
                                </SliderFullSizeExtended>
                            </Right100>
                        </FeatureImage>
                    ) : null}
                </Container>
            </Section>
        );
    }
    return (
        <Section>
            <Container>
                {!isLoggedIn ? (
                    <Heading isCentered>
                        <Message
                            dangerouslySetInnerHTML={{
                                __html: _t("collectionHelpText"),
                            }}
                        />
                    </Heading>
                ) : null}
                {renderIf(!renderImages)(
                    <Heading isCentered>
                        <h3>{title}</h3>
                        {renderIf(content !== "")(
                            <p
                                className="collection--description rte"
                                dangerouslySetInnerHTML={{
                                    __html: content,
                                }}
                            />
                        )}
                    </Heading>
                )}
                {renderIf(renderImages)(
                    <FeatureImage>
                        <Left>
                            <Text>
                                <h2>{title}</h2>
                                <div
                                    className="collection--description rte"
                                    dangerouslySetInnerHTML={{
                                        __html: content,
                                    }}
                                ></div>
                            </Text>
                            <PlayerAudio audio={audio} />
                        </Left>
                        <Right>
                            <SliderExtended>
                                <ul>
                                    <AutoPlaySwipeableViews
                                        enableMouseEvents
                                        interval={4000}
                                        index={activeItemIndex}
                                        slideStyle={slideStyle}
                                        style={slideStyle}
                                        onChangeIndex={setActiveItemIndex}
                                    >
                                        {images &&
                                            images.map((index, i) => (
                                                <li
                                                    key={`collection-slide-${i}`}
                                                >
                                                    <LazyLoadImage
                                                        visibleByDefault={true}
                                                        effect="blur"
                                                        src={index}
                                                        alt={title}
                                                    />
                                                </li>
                                            ))}
                                    </AutoPlaySwipeableViews>
                                </ul>
                                {renderIf(imagesCount > 1)(
                                    <Arrows>
                                        <a onClick={lastSlide}></a>
                                        <a onClick={nextSlide}></a>
                                    </Arrows>
                                )}
                                <ItemDenominator
                                    onClick={setActiveItemIndex}
                                    activeItemIndex={activeItemIndex}
                                    count={imagesCount}
                                />
                            </SliderExtended>
                        </Right>
                    </FeatureImage>
                )}
            </Container>
        </Section>
    );
}

export default React.memo(MasterHead);
