import React, { useState } from "react";
import PropTypes from "prop-types";
import { useWorker, WORKER_STATUS } from "@koale/useworker";
import useDirectos from "../../../blueberry/hooks/useDirectos";
import renderIf from "../../utils/renderIf";
import { Control, Radio } from "../../sdk/Form";
import { isNumeric } from "../../utils";
import useNotification from "../../hooks/useNotification";
import { useTranslation } from "../../../lang";
import { productHasPrice, ProductPrice } from "./index";
import { Columns, Column, Title } from "../../sdk/Layout";
import { Button } from "../../sdk/Button";
import Icons2 from "../Icons2";
import ModalPortal from "../ModalPortal";
import ProductMeta from "./ProductMeta";
import ProductTitle from "./ProductTitle";
import AsociatedProducts from "./AsociatedProducts";
import { DropdownExtended, RadioExtended, ButtonExtended } from "./style";

import {
	Qty,
	Btn,
	Add,
	WishlistButtonExtended,
	BottomBar,
	Rows,
	Row,
	PopupOptions,
	PopupCloser,
	PopupOptionsFooter,
	Thumbnail,
	Price,
	QtyPopup,
} from "./style";
import useCart from "../../../blueberry/hooks/useCarts";

export const getMultiple = (product) => {
	return product.multiple;
};
export const getAvailability = (product) => {
	return product && product.check_availability && product.available <= 0
		? false
		: true;
};

export const getDirectQuantity = (product) => {
	return (
		Math.ceil(
			800 /
				(product.price_d ? product.price_d : product.price) /
				getMultiple(product),
		) * getMultiple(product)
	);
};

export const getProductMin = (product, isDirectos) => {
	if (isDirectos && product.price_d) {
		return getDirectQuantity(product, getMultiple(product));
	}
	if (product.check_availability) {
		if (getMultiple(product) <= product.available)
			return getMultiple(product);
		return product.available;
	}

	return product.min_venta;
};

const sortAndFindCookieTokens = (product, isInCart, carts) => {
	const cartCookieToken = [];

	for (let index = 0; index < carts.length; index++) {
		const currentCart = carts[index];
		for (let j = 0; j < currentCart.items.length; j++) {
			if (currentCart.items[j].productId === product._id) {
				cartCookieToken.push(currentCart.cookie_token);
				break;
			}
		}
	}

	return cartCookieToken;
};

function AddToCart({
	product,
	isInCart,
	isFair,
	addToCart,
	isLoggedIn,
	viewItem,
	removeItem,
	defaultQuantity,
	asociatedProducts,
}) {
	const [_t] = useTranslation();
	const isDirectos = useDirectos();
	const carts = useCart();
	const cartLength = carts ? carts.length : 0;
	const [isAddingToCart, setIsAddinToCart] = useState(false);
	const timeout = React.useRef(0);
	const hasPrice = productHasPrice(product);
	const [
		sortAndFindCookieTokensWorker,
		{ status: workerStatus, kill: workerTerminate },
	] = useWorker(sortAndFindCookieTokens);

	const [cartCookieToken, setCartCookieToken] = React.useState([]);

	const currentAvailble = React.useRef(true);
	const [addAlert, NotificationComponent] = useNotification({
		timeout: 3000,
	});

	const [quantity, setQuantity] = useState(0);

	const [isAddToCartVisible, setIsAddToCartVisible] = React.useState(false);
	const toggleAddToCart = () => {
		setIsAddToCartVisible((prevState) => !prevState);
	};

	const isAvailable = getAvailability(product) && currentAvailble.current;

	const getMax = React.useCallback(() => {
		return product.check_availability && !isDirectos
			? product.available
			: 9999999;
	}, [product, isDirectos]);

	const getCorrectValue = (newQ) => {
		const min = getProductMin(product, isDirectos);
		const max = getMax();
		const multiple = getMultiple(product);

		if (newQ < min || newQ === min) return min;
		else if (newQ > max || newQ === max) return max;
		else if (newQ % multiple) return Math.ceil(newQ / multiple) * multiple;
		return newQ;
	};

	const handleQuantityChange = (e) => {
		const type = e.currentTarget.getAttribute("data-type");
		const { value } = e.currentTarget;

		if (type === "minus") {
			setQuantity((prev) => {
				const multiple = getMultiple(product);
				const newQ = prev - parseInt(multiple);
				const min = getProductMin(product, isDirectos);

				if (min <= newQ) return newQ;
				return min;
			});
		} else if (type === "plus") {
			setQuantity((prev) => {
				const multiple = getMultiple(product);
				const newQ = prev + parseInt(multiple);

				if (getMax() >= newQ) return newQ;
				return getMax();
			});
		} else {
			if (isNumeric(value) || value === "") {
				const newQ = value === "" ? 0 : parseInt(value);

				setQuantity(newQ);
				if (timeout.current) clearTimeout(timeout.current);
				timeout.current = setTimeout(() => {
					setQuantity(getCorrectValue(newQ));
				}, 1000);
			}
		}
	};

	const handelAddToCart = (e, cookie_token) => {
		if (product && (!product.check_availability || product.available > 0)) {
			setIsAddinToCart(true);

			if (cookie_token == null && cartCookieToken.length > 0) {
				cookie_token = cartCookieToken[0];
			}

			let newQ = getCorrectValue(quantity);

			addToCart(product._id, newQ, product, cookie_token)
				.then((resp) => {
					setIsAddinToCart(false);
					setIsAddToCartVisible(false);
				})
				.catch((err) => {
					if (err && err.reason === "not-enough-quantity") {
						currentAvailble.current = false;
						addAlert({
							paragraph: _t("notAvailableAnymore"),
							error: true,
						});
					}
					setIsAddinToCart(false);
				});
		}
	};
	const handleRemoveFromCart = (e, cookie_token) => {
		setIsAddinToCart(true);
		removeItem(product._id, product.slug, cookie_token)
			.then(() => {
				setIsAddinToCart(false);
			})
			.catch((err) => {
				setIsAddinToCart(false);
			});
	};

	const handelAddToCartDropdown = (e) => {
		if (e.target.checked) {
			handelAddToCart(null, e.target.value);
		} else {
			handleRemoveFromCart(null, e.target.value);
		}
	};

	React.useEffect(() => {
		if (product) {
			if (defaultQuantity != null) {
				setQuantity(getCorrectValue(defaultQuantity));
			} else {
				setQuantity(getProductMin(product, isDirectos));
			}
		}
	}, [product, isDirectos]);

	React.useEffect(() => {
		if (product && isInCart && carts && carts.length > 0) {
			sortAndFindCookieTokensWorker(product, isInCart, carts).then(
				(resp) => {
					setCartCookieToken(resp);
				},
			);
		} else {
			setCartCookieToken([]);
		}
	}, [product._id, carts, isInCart]);

	return (
		<>
			<Qty hasAddons className="hide-on-standalone">
				<NotificationComponent />
				<Control>
					<Button data-type="minus" onClick={handleQuantityChange}>
						<svg
							aria-hidden="true"
							focusable="false"
							data-prefix="fal"
							data-icon="minus"
							role="img"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 384 512">
							<path
								fill="currentColor"
								d="M376 232H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h368c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"
							/>
						</svg>
					</Button>
				</Control>
				<Control isExpanded>
					<input
						type="text"
						value={quantity}
						onChange={handleQuantityChange}
						disabled={!isLoggedIn}
					/>
				</Control>
				<Control>
					<Button data-type="plus" onClick={handleQuantityChange}>
						<svg
							aria-hidden="true"
							focusable="false"
							data-prefix="fal"
							data-icon="plus"
							role="img"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 384 512">
							<path
								fill="currentColor"
								d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"
							/>
						</svg>
					</Button>
				</Control>
			</Qty>
			<Add>
				<Btn className="hide-on-standalone">
					{isLoggedIn && !hasPrice ? (
						<Button static isFullWidth>
							{_t("noPermissionToThisBrand")}
						</Button>
					) : isLoggedIn ? (
						<DropdownExtended isHoverable={isAvailable}>
							<ButtonExtended
								className={isInCart && "in-cart"}
								primary={!isInCart}
								isFullWidth
								static={!isAvailable}
								loading={isAddingToCart && "true"}>
								<span
									className="label"
									onClick={handelAddToCart}
									onTouchStart={handelAddToCart}>
									{isInCart
										? _t("updateCart")
										: _t("addToCart")}
								</span>
								{cartLength > 1 ? (
									<span className="icon-down">
										<Icons2 icon="chevron-down" />
									</span>
								) : null}
							</ButtonExtended>
							{cartLength > 1 ? (
								<div>
									<div>
										<ul>
											{carts.map((index) => {
												return (
													<li key={index._id}>
														<RadioExtended
															disabled={
																(index.brand
																	? index
																			.brand
																			._id
																	: "") !==
																product.brandId
															}>
															<label>
																<input
																	type="checkbox"
																	name="carrito"
																	disabled={
																		(index.brand
																			? index
																					.brand
																					._id
																			: "") !==
																		product.brandId
																	}
																	checked={
																		cartCookieToken.indexOf(
																			index.cookie_token,
																		) > -1
																	}
																	onChange={
																		handelAddToCartDropdown
																	}
																	value={
																		index.cookie_token
																	}
																/>

																<p>
																	{index.title
																		? index.title
																		: index.brand
																		? index
																				.brand
																				.name
																		: "No name"}
																</p>
																<span></span>
															</label>
														</RadioExtended>
													</li>
												);
											})}
										</ul>
									</div>
								</div>
							) : null}
						</DropdownExtended>
					) : (
						<Button isFullWidth>{_t("pleseLoginToEnter")}</Button>
					)}
				</Btn>
				{isLoggedIn ? (
					<WishlistButtonExtended productId={product._id} />
				) : null}
			</Add>
			<ModalPortal>
				<BottomBar>
					{/* <Button onClick={toggleList}>
                    <Icons2 icon="heart"></Icons2>
                </Button> */}
					{isLoggedIn && !hasPrice ? (
						<Button static isFullWidth>
							{_t("noPermissionToThisBrand")}
						</Button>
					) : (
						<Button primary isFullWidth onClick={toggleAddToCart}>
							{isInCart ? _t("updateCart") : _t("addToCart")}
						</Button>
					)}
				</BottomBar>
			</ModalPortal>
			{renderIf(isAddToCartVisible)(
				<ModalPortal>
					<PopupOptions className="show-on-standalone">
						<span onClick={toggleAddToCart}></span>
						<div>
							<PopupCloser onClick={toggleAddToCart}>
								<Icons2 icon="close"></Icons2>
							</PopupCloser>
							<Rows>
								<Row>
									<Columns>
										<Column isNarrow>
											<Thumbnail>
												<img
													src={
														product.imagesUrl &&
														product.imagesUrl
															.length > 0
															? product
																	.imagesUrl[0]
																	.thumb
															: ""
													}
													alt={product.title}
												/>
											</Thumbnail>
										</Column>
										<Column>
											<ProductTitle
												size="6"
												title={product.title}
												title_eng={product.title_eng}
												title_pt={product.title_pt}
											/>
											<Price>
												<ProductPrice
													price={product.price}
													price_d={product.price_d}
													price_usa={
														product.price_usa
													}
													compare_at_price={
														product.compare_at_price
													}
												/>
											</Price>
											<ProductMeta
												jsonSettings={null}
												product={product}
												isFair={isFair}
												isLoggedIn={isLoggedIn}
											/>
										</Column>
									</Columns>
								</Row>
								<Row>
									<Title as="h3" size="6">
										{_t("quantity")}
									</Title>
									<QtyPopup>
										<span
											tabIndex="0"
											role="button"
											data-type="minus"
											onClick={handleQuantityChange}>
											-
										</span>
										<input type="number" value={quantity} />
										<span
											data-type="plus"
											tabIndex="0"
											role="button"
											onClick={handleQuantityChange}>
											+
										</span>
									</QtyPopup>
								</Row>
								<Row>
									<AsociatedProducts
										viewItem={viewItem}
										asociatedProducts={asociatedProducts}
									/>
								</Row>
							</Rows>
							<PopupOptionsFooter>
								<Button
									primary
									isFullWidth
									static={!isAvailable}
									onClick={handelAddToCart}
									loading={isAddingToCart && "true"}>
									{isInCart
										? _t("updateCart")
										: _t("addToCart")}
								</Button>
							</PopupOptionsFooter>
						</div>
					</PopupOptions>
				</ModalPortal>,
			)}
			{/* {renderIf(isListVisible)(
                <PopupOptions className="show-on-standalone">
                    <span></span>
                    <div>
                        <PopupCloser onClick={toggleList}>
                            <Icons2 icon="close"></Icons2>
                        </PopupCloser>
                        <div>
                            <Title as="h3" size="6">
                                Guardar en mi lista
                            </Title>
                            <WishList>
                                <label>
                                    <input type="checkbox" />
                                    <span></span>
                                    <p>My First List</p>
                                </label>
                                <label>
                                    <input type="checkbox" />
                                    <span></span>
                                    <p>Mis favoritos</p>
                                </label>
                            </WishList>

                            <AddList>
                                <span>+</span>Crear lista
                            </AddList>
                        </div>

                        <div>
                            <Title as="h3" size="6">
                                Nueva Lista
                            </Title>{" "}
                            <Control isExpanded>
                                <input type="text" placeholder="Título" />{" "}
                            </Control>
                        </div>

                        <PopupOptionsFooter>
                            <Button primary isFullWidth>
                                Listo
                            </Button>
                            <Button primary isFullWidth>
                                Guardar
                            </Button>{" "}
                        </PopupOptionsFooter>
                    </div>
                </PopupOptions>
            )} */}
		</>
	);
}

AddToCart.propTypes = {
	product: PropTypes.object.isRequired,
	isInCart: PropTypes.bool.isRequired,
	isFair: PropTypes.bool.isRequired,
	isLoggedIn: PropTypes.bool.isRequired,
	addToCart: PropTypes.func.isRequired,
};

export default React.memo(AddToCart);
