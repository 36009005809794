import React from "react";
import styled from "styled-components";
import Blueberry from "../../../blueberry";
import { useTranslation } from "../../../lang";
import { Tag } from "../../sdk/Elements";
import useNotification from "../../hooks/useNotification";
import getProductStatus from "./helpers/getProductStatus";

const TagExtented = styled(Tag)`
    height: 1.6em;
    background-color: ${(props) =>
        props.status === "future"
            ? "#2296f1"
            : props.status === "notAvailable"
            ? "#e32619"
            : props.status === "transit"
            ? "#257942"
            : "#112c55"} !important;
    ${(props) => (props.status === "available" ? "display: none;" : "")}
`;

const Meta = styled.div`
    padding-bottom: 10px;
    span {
        display: inline-block;
        vertical-align: middle;
        color: gray;
        font-size: 14px;
        @media (max-width: 980px) {
            font-size: 12px;
        }
        a {
            color: royalblue;
            &:hover {
                text-decoration: underline;
            }
        }
        ${Tag} {
            background-color: #2296f1;
            color: white;
        }
    }
    span + span:before {
        content: "-";
        margin: 0 10px;
    }
    a {
        cursor: pointer;
    }
`;

const Link = styled.a`
    position: relative;
    ${(props) => {
        if (props.loading) {
            return `
  			@-webkit-keyframes spinAround {
				  from {
				    transform: rotate(0deg);
				  }
				}
				@keyframes spinAround {
				  from {
				    transform: rotate(0deg);
				  }
				  to {
				    transform: rotate(359deg);
				  }
				}
				color: transparent !important;
				pointer-events: none;
				&::after {
				  -webkit-animation: spinAround 500ms infinite linear;
				  animation: spinAround 500ms infinite linear;
				  border: 2px solid #dbdbdb;
				  border-radius: 290486px;
				  border-right-color: transparent;
				  border-top-color: transparent;
				  content: "";
				  display: block;
				  height: 1em;
				  position: relative;
				  width: 1em;
				  position: absolute;
				  left: calc(50% - (1em / 2));
				  top: calc(50% - (1em / 2));
				  position: absolute !important;
				}
  		`;
        }
    }};
`;

function ProductMeta({ product, isFair, jsonSettings, isLoggedIn }) {
    const [_t] = useTranslation();
    const [handleNotify, NotifyProfile] = useNotification({
        timeout: 3000,
    });

    const [isDone, setIsDone] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const productStatus = getProductStatus({ product });

    const handleViewRequest = () => {
        setIsLoading(true);
        Blueberry.requestProductData({
            ask: "video",
            productId: product._id,
        })
            .then(() => {
                setIsLoading(false);
                setIsDone(true);

                handleNotify({
                    paragraph: _t("videoRequested"),
                    info: true,
                });
            })
            .catch(() => {
                setIsDone(true);
                setIsLoading(false);
            });
    };

    React.useEffect(() => {
        setIsDone(false);
    }, [product]);

    return (
        <>
            <Meta>
                <span>
                    <TagExtented status={productStatus}>
                        {productStatus === "future"
                            ? _t("fair")
                            : productStatus === "notAvailable"
                            ? _t("notAvailable")
                            : productStatus === "transit"
                            ? `${_t("deliveryDate")} ${product.deliveryDate}`
                            : _t("available")}
                    </TagExtented>
                </span>
                <span>Ref: {product.sku}</span>
                <span>by {product.vendor}</span>
                {jsonSettings &&
                jsonSettings.showAskForVideo &&
                isLoggedIn &&
                !product.check_availability ? (
                    <span>
                        {!isDone ? (
                            <Link
                                onClick={handleViewRequest}
                                loading={isLoading}
                            >
                                {_t("requestVideo")}
                            </Link>
                        ) : (
                            _t("videoRequested")
                        )}
                    </span>
                ) : null}
            </Meta>
            <NotifyProfile />
        </>
    );
}

export default React.memo(ProductMeta);
