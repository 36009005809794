/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable object-curly-newline */
import React from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";
import Blueberry from "../../../blueberry";
import useUser from "../../../blueberry/hooks/useUser";
import useJsonSettings from "../../hooks/useJsonSettings";
import { Container, Section, Title, Heading } from "../../sdk/Layout";
import { getTag, rules } from "../../utils";
import ProductsScrollable from "../ProductsScrollable";
import ProductGrid from "../ProductGrid2";

const SectionExtended = styled(Section)`
    position: relative;
    z-index: 1;
    background-color: ${(props) => props.theme.grayLight};
    @media (max-width: 768px) {
        padding-right: 0;
        padding-top: 40px;
        padding-bottom: 40px;
    }
`;

const ContainerExtended = styled(Container)`
	.title {
		color: ${({ theme }) => theme.textDefault};
		text-wrap: balance;
		text-align: center;
		margin-bottom: 16px;
	}
	h2.title {
		font-size: 28px;
		font-weight: 800;
		text-transform: uppercase;
	}
	h3.title {
		font-size: 18px;
		font-weight: 400;
	}
    @media (max-width: 768px) {
        overflow-x: auto;
        padding-bottom: 10px;
    }
`;

const brandsOrder = [
	"tyJbdXkZCercBFmnK-concepts",
	"tyJbdXkZCercBFmnK-santini",
	"tyJbdXkZCercBFmnK-merletto",
	"tyJbdXkZCercBFmnK-diangelo",
	"tyJbdXkZCercBFmnK-wellness",
];

function Recomendations({ toggleModalProduct, addToCart, viewItem, _t }) {
    const user = useUser();

    const jsonSettings = useJsonSettings();

    const [isLoading, setIsLoading] = React.useState(false);
    const [hasError, setError] = React.useState(false);
    const [didFirstLoad, setDidFirstLoad] = React.useState(false);
    const [hideComponent, setHideComponent] = React.useState(false);
    const [recomendations, setRecomendations] = React.useState([]);

    const { ref, inView } = useInView({
        threshold: 0,
    });

	// Agrupar y ordenar recomendaciones por brand (orden: concepts, santini, etc...)
	// Máximo 5 productos por marca
	const recomsByBrand = React.useMemo(() => {
		if (!recomendations?.length || !recomendations[0].products?.products?.length) return [];

		const prods = recomendations[0].products.products;
		const group = Object.groupBy(prods, (p) => p.brandId);
		const sortGroup = {};
		const keys = Object.keys(group);
		keys.sort((a, b) => brandsOrder.indexOf(a) - brandsOrder.indexOf(b));
		keys.forEach((key) => {
			group[key] = group[key].slice(0, 5);
			sortGroup[key] = group[key].map((i) => i);
		});
		return sortGroup;
	}, [recomendations]);

    React.useEffect(() => {
        if (!didFirstLoad && inView) {
            setIsLoading(true);

            Blueberry.homePage({
                filter: "",
                limit: 18,
                page: 1,
                limitPerRow: 40,
                rotationRate: 0.1,
                includeBestsellers: false,
                includePersonal: true,
                recentlyViewed: false,
            })
                .then((resp) => {
                    setRecomendations(resp);
                    setIsLoading(false);
                    setDidFirstLoad(true);
                })
                .catch(() => {
                    setDidFirstLoad(false);
                    setIsLoading(false);
                    setError(true);
                });
        }
    }, [inView]);

	if (hideComponent) return null;

    return (
        <SectionExtended ref={ref}>
            <ContainerExtended isFluid>
				<h2 className="title">{_t("recommendationsTitle")}</h2>
				<h3 className="title">{_t("basedOnYourHistory")}</h3>
				{isLoading ? (
					<ProductsScrollable
						viewItem={viewItem}
						addToCart={addToCart}
						isLoading={isLoading}
						onQuickView={toggleModalProduct}
					/>
				) : null}
				{
					(recomendations?.length && !isLoading) && (
						<>
							{ Object.keys(recomsByBrand).map((brandId) => (
								<ProductGrid
									key={`recoms-${brandId}`}
									products={{ products: recomsByBrand[brandId] }}
									viewItem={viewItem}
									addToCart={addToCart}
									onQuickView={toggleModalProduct}
									lazyLoadAll
								/>
							))}
						</>
					)
				}
            </ContainerExtended>
        </SectionExtended>
    );
}

export default Recomendations;
