import React, { useState } from "react";
import styled from "styled-components";
import { Container, Section } from "../../sdk/Layout";
import { Button } from "../../sdk/Button";
import { Dropdown } from "../../sdk/Elements";
import { Field, Control, Checkbox } from "../../sdk/Form";
import useWishlist from "../../../blueberry/hooks/useWishlist";
import Blueberry from "../../../blueberry/";
import { useTranslation } from "../../../lang";

const CheckboxExtended = styled(Checkbox)`
    p {
        font-size: 13px;
        margin: 0;
    }
    span {
        position: relative;
        ${(props) =>
            props.isLoading
                ? `
                border: none !important;
                
            @-webkit-keyframes spinAround {
				  from {
				    transform: rotate(0deg);
				  }
				}
				@keyframes spinAround {
				  from {
				    transform: rotate(0deg);
				  }
				  to {
				    transform: rotate(359deg);
				  }
				}
				color: transparent !important;
				pointer-events: none;
				&::after {
				  -webkit-animation: spinAround 500ms infinite linear;
				  animation: spinAround 500ms infinite linear;
				  border: 2px solid #dbdbdb;
				  border-radius: 290486px;
				  border-right-color: transparent;
				  border-top-color: transparent;
				  content: "";
				  display: block;
				  height: 1em;
				  position: relative;
				  width: 1em;
				  position: absolute;
				  left: calc(50% - (1em / 2));
				  top: calc(50% - (1em / 2));
				  position: absolute !important;
				}
        
        `
                : ""}
    }
`;

const WidgetAdd = styled.div`
    display: block;
    padding: 0.375rem 1rem;
`;

const Closer = styled.div`
    display: none;
    position: absolute;
    top: 5px;
    right: 7px;
    svg {
        width: 15px;
    }
`;

const DropdownExtended = styled(Dropdown)`
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    li {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    a {
        cursor: pointer;
        border: none;
    }
    > div {
        width: 100%;
    }

    /* @media (max-width: 980px) ${(props) =>
        !props.theme.isStandalone ? "and (display-mode: standalone)" : ""} {
        > div {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(0, 0, 0, 0.3);
            z-index: 999;
            > div {
                width: 95%;
                max-width: 300px;
                position: relative;
                li {
                    padding: 0.8rem 1rem;
                }
            }
        }
        & ${Closer} {
            display: block;
        }
    } */
`;

const HeartIconButton = styled.span`
    position: absolute;
    z-index: 2;
    top: 10px;
    left: 10px;
    opacity: 0.5;
    @media (hover: hover) {
        &:hover {
            opacity: 1;
        }
    }
    svg {
        width: 20px;
        height: 20px;
        color: ${(props) => props.theme.gray};
    }
    &.checked {
        opacity: 1;
        svg {
            color: ${(props) =>
                props.theme.darkMode ? "#d03636" : props.theme.primary};
        }
    }

    ${(props) => {
        if (props.loading) {
            return `
            svg  {
                opacity: 0;
            }
  			@-webkit-keyframes spinAround {
				  from {
				    transform: rotate(0deg);
				  }
				}
				@keyframes spinAround {
				  from {
				    transform: rotate(0deg);
				  }
				  to {
				    transform: rotate(359deg);
				  }
				}
				color: transparent !important;
				pointer-events: none;
				&::after {
				  -webkit-animation: spinAround 500ms infinite linear;
				  animation: spinAround 500ms infinite linear;
				  border: 2px solid #dbdbdb;
				  border-radius: 290486px;
				  border-right-color: transparent;
				  border-top-color: transparent;
				  content: "";
				  display: block;
				  height: 1em;
				  position: relative;
				  width: 1em;
				  position: absolute;
				  left: calc(50% - (1em / 2));
				  top: calc(50% - (1em / 2));
				  position: absolute !important;
				}
  		`;
        }
    }};
`;

const IconAdd = ({ checked }) => {
    if (checked) {
        return (
            <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="check-square"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
            >
                <path
                    fill="currentColor"
                    d="M400 480H48c-26.51 0-48-21.49-48-48V80c0-26.51 21.49-48 48-48h352c26.51 0 48 21.49 48 48v352c0 26.51-21.49 48-48 48zm-204.686-98.059l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.248-16.379-6.249-22.628 0L184 302.745l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.25 16.379 6.25 22.628.001z"
                />
            </svg>
        );
    }
    return (
        <svg
            width="10px"
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="plus-square"
            className="svg-inline--fa fa-plus-square fa-w-14"
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
        >
            <path
                fill="currentColor"
                d="M352 240v32c0 6.6-5.4 12-12 12h-88v88c0 6.6-5.4 12-12 12h-32c-6.6 0-12-5.4-12-12v-88h-88c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h88v-88c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v88h88c6.6 0 12 5.4 12 12zm96-160v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-48 346V86c0-3.3-2.7-6-6-6H54c-3.3 0-6 2.7-6 6v340c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"
            />
        </svg>
    );
};
const IconHeart = ({ checked }) => {
    if (!checked) {
        return (
            <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="far"
                data-icon="heart"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
            >
                <path
                    fill="currentColor"
                    d="M458.4 64.3C400.6 15.7 311.3 23 256 79.3 200.7 23 111.4 15.6 53.6 64.3-21.6 127.6-10.6 230.8 43 285.5l175.4 178.7c10 10.2 23.4 15.9 37.6 15.9 14.3 0 27.6-5.6 37.6-15.8L469 285.6c53.5-54.7 64.7-157.9-10.6-221.3zm-23.6 187.5L259.4 430.5c-2.4 2.4-4.4 2.4-6.8 0L77.2 251.8c-36.5-37.2-43.9-107.6 7.3-150.7 38.9-32.7 98.9-27.8 136.5 10.5l35 35.7 35-35.7c37.8-38.5 97.8-43.2 136.5-10.6 51.1 43.1 43.5 113.9 7.3 150.8z"
                />
            </svg>
        );
    }

    return (
        <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="heart"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
        >
            <path
                fill="currentColor"
                d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"
            />
        </svg>
    );
};

// const IconClose = () => (
//     <svg
//         width="10px"
//         aria-hidden="true"
//         focusable="false"
//         data-prefix="fal"
//         data-icon="times"
//         role="button"
//         xmlns="http://www.w3.org/2000/svg"
//         viewBox="0 0 320 512"
//     >
//         <path
//             fill="currentColor"
//             d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
//         />
//     </svg>
// );

function CreateNewSection({
    onCreateNewWishlist,
    onChange,
    title,
    isLoading,
    toggleEdit,
    isEditing,
    _t,
}) {
    const inputRef = React.useRef();

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            onCreateNewWishlist();
        }
    };
    React.useEffect(() => {
        if (isEditing) {
            inputRef.current.focus();
        }
    }, [isEditing]);
    if (isEditing) {
        return (
            <>
                <WidgetAdd>
                    <Field>
                        <Control isExpanded>
                            <label>{_t("name")}</label>
                            <input
                                ref={inputRef}
                                onChange={onChange}
                                onKeyDown={handleKeyDown}
                                value={title}
                                type="text"
                                placeholder="Nombre de la lista"
                            />
                        </Control>
                    </Field>
                </WidgetAdd>
                <Button
                    as="a"
                    loading={isLoading}
                    onClick={onCreateNewWishlist}
                >
                    Guardar lista
                </Button>
            </>
        );
    }
    return <a onClick={toggleEdit}>+ Crear lista</a>;
}

function BotonPaginaProducto({ productId, className, isHart }) {
    const [_t] = useTranslation();
    const [isVisible, setIsVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingWishlist, setLoadingWishlist] = useState(null);
    const [title, setTitle] = useState("");
    const [wishlist] = useWishlist(productId);

    const [isEditing, setIsEditing] = useState(false);

    // const closeBoton = (e) => {
    //     if (e.currentTarget.contains(document.activeElement))
    //         setIsVisible(false);
    // };
    const toggleEdit = (e) => {
        e.stopPropagation();
        setIsEditing((prev) => !prev);
    };

    const toggleVisibility = () => setIsVisible((prev) => !prev);
    const onSetTitle = (e) => {
        setTitle(e.target.value);
    };
    const onCreateNewWishlist = (e) => {
        setIsLoading(true);
        let newTitle = title;
        if (analytics)
            analytics.track("Product Added to Wishlist", { productId });
        if (newTitle === "") newTitle = _t("myFirstList");
        Blueberry.upsertWishlist({ wishlist: { title: newTitle } })
            .then((resp) => {
                Blueberry.insertProductWishlist({
                    productId,
                    wishlistId: resp,
                }).then((resp) => {
                    setIsLoading(false);
                    setIsEditing(false);
                    setTitle("");
                });
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err);
            });
    };

    const handleChekboxChange = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const { value, checked } = e.target;

        setLoadingWishlist(value);
        if (checked) {
            if (analytics)
                analytics.track("Product Added to Wishlist", { productId });
            Blueberry.insertProductWishlist({
                productId,
                wishlistId: value,
            }).then(() => {
                setLoadingWishlist(null);
            });
        } else {
            if (analytics)
                analytics.track("Product Removed from Wishlist", { productId });
            Blueberry.removeProductWishlist({
                productId,
                wishlistId: value,
            }).then(() => {
                setLoadingWishlist(null);
            });
        }
    };

    const handleButtonClicked = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const listId = e.currentTarget.getAttribute("data-value");
        const checked = e.currentTarget.getAttribute("data-checked") === "true";
        const obj = {
            stopPropagation: e.stopPropagation,
            preventDefault: e.preventDefault,
            target: {
                value: listId,
                checked: !checked,
            },
        };

        if (listId == null) {
            onCreateNewWishlist(obj);
        } else {
            handleChekboxChange(obj);
        }
    };

    if (isHart) {
        const list = wishlist && wishlist.length > 0 ? wishlist[0] : null;
        const isChecked = list ? list.checked : false;

        return (
            <HeartIconButton
                small
                data-value={list ? list._id : null}
                data-checked={isChecked}
                className={isChecked && "checked"}
                onClick={handleButtonClicked}
                loading={loadingWishlist ? true : undefined}
            >
                <IconHeart checked={isChecked} />
            </HeartIconButton>
        );
    }

    return (
        <DropdownExtended isActive className={className}>
            <Button onClick={toggleVisibility}>
                <IconAdd checked={wishlist.some((index) => index.checked)} />
                <span>{_t("saveToMyList")}</span>
            </Button>
            <div className={!isVisible ? "is-hidden" : ""}>
                <div>
                    <Closer onClick={toggleVisibility}>
                        {/* {<IconClose />} */}
                    </Closer>
                    <li>{_t("select")}...</li>
                    <ul>
                        <li className="divisor"></li>
                        {wishlist == null || wishlist.length === 0 ? (
                            <li>
                                <CheckboxExtended>
                                    <label>
                                        <input
                                            type="checkbox"
                                            value={""}
                                            checked={false}
                                            onChange={onCreateNewWishlist}
                                        />
                                        <span></span>
                                        <p>{_t("myFirstList")}</p>
                                    </label>
                                </CheckboxExtended>
                            </li>
                        ) : null}
                        {wishlist.map((index, i) => (
                            <li key={index.title + i}>
                                <CheckboxExtended
                                    isLoading={
                                        loadingWishlist === index._id
                                            ? true
                                            : undefined
                                    }
                                >
                                    <label>
                                        <input
                                            type="checkbox"
                                            value={index._id}
                                            checked={index.checked}
                                            onChange={handleChekboxChange}
                                        />
                                        <span></span>
                                        <p>{index.title}</p>
                                    </label>
                                </CheckboxExtended>
                            </li>
                        ))}

                        <li className="divisor"></li>
                    </ul>

                    <CreateNewSection
                        title={title}
                        onChange={onSetTitle}
                        isLoading={isLoading}
                        isEditing={isEditing}
                        _t={_t}
                        toggleEdit={toggleEdit}
                        onCreateNewWishlist={onCreateNewWishlist}
                    />
                </div>
            </div>
        </DropdownExtended>
    );
}

export default React.memo(BotonPaginaProducto);
